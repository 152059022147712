import { Grid, Paper } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import Aakar from "../../config/Aakar-normal";
import endpoint from "../../config/endpoints";
import { langugae } from "../../config/languageJSON";
import {
  cancelledMsg,
  checkedListEmptyMsg,
  noInternetMsg,
  serverMsg,
} from "../../config/messageconstant";
import TiroDevanagariHindi from "../../config/TiroDevanagariHindi-Regular-normal"; // Replace with the actual path to your font file
import { BillingHistoryTableJson } from "../../DynamicFormsJson/MastersJSON/billingHistory";

import logo from "../../Images/gokulImg.png";
import ofc from "../../Images/OFC_logo.png";

import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByUserType } from "../../Slice/beat.slice";
import {
  getBillingHistoryByOutletAndDate,
  getTodaysBillingHistoryUpdated,
  getBillingHistoryBySalesPersonUpdated,
} from "../../Slice/billingHistory.slice";
import { getActiveLanguage } from "../../Slice/multilingual.slice";

import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";
import { downloadJson } from "../../components/Comman/ExportToTxt";
import { LabelCompo } from "../../components/Comman/Label";
import { withRouter } from "../../components/withRouter";
import { redColor, titleColor } from "../../config/ColorObj";
import {
  getOutletByBeatIdsPost,
  getOutletByUserType,
} from "../../Slice/outlet.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BillingHistoryPopup from "./BillingHistoryPopup";
import {
  getSalesExecutiveByBeatIds,
  getSalesExecutiveByLoginAreas,
} from "../../Slice/salesExecutive.slice";
let billColumns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    align: "center",
  },
  {
    title: "Firm Name",
    name: "firmName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Beat Name",
    name: "beatName",
    positionCenter: false,
    canSearch: true,
  },
  {
    title: "Bill No",
    name: "billNo",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Taxable Amount",
    name: "taxableAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "CGST",
    name: "cgstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "SGST",
    name: "sgstAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "Total Amount",
    name: "totalAmt",
    positionCenter: true,
    canSearch: true,
    align: "right",
  },
  {
    title: "Status",
    name: "statusName",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "E-Invoice Generated",
    name: "isInvoiceGenerated",
    positionCenter: true,
    canSearch: true,
    align: "center",
  },
  {
    title: "Action",
    name: "action",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
    minWidth: 250,
  },
];
class BillingHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billList: [],
      editFlag: false,
      editData: "",
      langNew: "",
      lang: "2",
      flag: "1",
      searchBy: "0",
      fieldData: {},
      formData: {},
      dynamicMasterData: {
        // areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outletByUserType,
      },
      selection: [],
      showPdf: 0,
      fieldDataForModule: {},
      fromDate: dayjs().format("DD-MM-YYYY"),
      toDate: dayjs().format("DD-MM-YYYY"),
    };
  }
  async componentDidMount() {
    const {
      getTodaysBillingHistoryUpdated,
      getBeatByUserType,
      getActiveLanguage,
      getSalesExecutiveByLoginAreas,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getTodaysBillingHistoryUpdated().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getSalesExecutiveByLoginAreas().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getBeatByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getActiveLanguage().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  splitArrayIntoNArrays(arr, chunkSize) {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.beatList !== nextProps.beatList) {
      if (
        this.props.beatList?.beatByUserType !==
        nextProps.beatList?.beatByUserType
      ) {

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beatByUserType,
          },
        });
      }
    }

    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesPersonDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getOutletByBeatIdsPost,
      getSalesExecutiveByBeatIds,
      showLoador,
      showNotification,
    } = this.props;

    if (data.flag) {
      this.setState({
        searchBy: data.flag,
        formData: {
          flag: data.flag,
          fromdate: dayjs(),
          todate: dayjs(),
          beat: [],
          outletIds: [],
          salesPersonIds: []
        },
      });
    }

    if (navigator.onLine) {
      if (data.beat && data.beat != "") {
        if (this.state.searchBy == 0) {
          let beatJson = {
            beatIds: data.beat,
          };
          showLoador({ loador: true });
          await getOutletByBeatIdsPost({ beatIds: beatJson }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        } else {
          showLoador({ loador: true });
          await getSalesExecutiveByBeatIds({ beatIds: data.beat.join() }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        }
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchList = (data) => {
    const {
      getBillingHistoryByOutletAndDate,
      getBillingHistoryBySalesPersonUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      this.setState({
        fieldData: data,
        fromDate: data.fromdate,
        toDate: data.todate,
      });
      if (data.flag == 0) {
        let billingHistoryData = {
          outletIds: data.outletIds,
          fromDate: data.fromdate,
          toDate: data.todate,
        };
        showLoador({ loador: true });
        getBillingHistoryByOutletAndDate({
          billingHistoryDataForPost: billingHistoryData,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        let salesTeamIdsData = {
          salesTeamIds: data.salesPersonIds,
          fromDate: data.fromdate,
          toDate: data.todate,
        };
        showLoador({ loador: true });
        getBillingHistoryBySalesPersonUpdated({
          salesTeamIdsData: salesTeamIdsData,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getHeadersSub = (dataToSet) => {
    const { formData, lang } = this.state;
    let all = langugae[lang].all ? langugae[lang].all : "";

    let strip = langugae[lang].strip ? langugae[lang].strip : "";

    let cartoon = langugae[lang].cartoon ? langugae[lang].cartoon : "";
    let SubHead = `<td ></td>`;
    dataToSet.packagingType &&
      dataToSet.packagingType.map((row) => {
        SubHead =
          SubHead +
          ` <td align="left" 
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${all}</td><td align="left"  
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${strip}</td><td align="left" 
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${cartoon}</td>`;
      });

    return `<tr> ` + SubHead + `<tr>`;
  };
  getHeaders = (dataToSet) => {
    const { formData, lang } = this.state;
    let t = `<tr> <td  
    style="border-bottom: 1px solid #313131;  border-left: 1px solid #313131; padding: 5px; color: #000; font-size: 10px;">${langugae[lang].productName ? langugae[lang].productName : ""
      }</td>`;

    dataToSet.packagingType &&
      dataToSet.packagingType.map((row) => {
        t =
          t +
          `
      <td colspan="3" align="left" "
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${row.name}</td>`;
      });

    t = t + ` </tr>`;
    return t;
  };
  getDetailsData = (categoryData, dataToSet, finalTotalObj) => {
    const { formData, lang } = this.state;

    let packagingTypeList =
      dataToSet.packagingType != null ? dataToSet.packagingType : [];
    let orderSummaries =
      dataToSet.orderSummaries != null ? dataToSet.orderSummaries : [];
    let t = ``;
    let totalObj = {};
    let totalList = [langugae[lang].total ? langugae[lang].total : ""];
    categoryData.groups.map((groupData) => {
      t =
        t +
        `<tr> 
<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${groupData.name}</td>`;

      packagingTypeList.map((packagingTypeData) => {
        let flag = 0;
        orderSummaries.map((rowData) => {
          if (
            groupData.id == rowData.groupId &&
            rowData.packagingTypeId == packagingTypeData.id
          ) {
            flag = 1;
            t =
              t +
              `
      <td  align="left" "
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${rowData.totalQty}</td>
      <td  align="left" "
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${rowData.secondaryUomValue}</td>
      <td  align="left" "
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${rowData.primaryUomValue}</td> `;

            totalObj = {
              ...totalObj,
              [packagingTypeData.id + "-totalQty"]: totalObj[
                packagingTypeData.id + "-totalQty"
              ]
                ? totalObj[packagingTypeData.id + "-totalQty"] +
                rowData.totalQty
                : rowData.totalQty,
              [packagingTypeData.id + "-secondaryUomValue"]: totalObj[
                packagingTypeData.id + "-secondaryUomValue"
              ]
                ? totalObj[packagingTypeData.id + "-secondaryUomValue"] +
                rowData.secondaryUomValue
                : rowData.secondaryUomValue,
              [packagingTypeData.id + "-primaryUomValue"]: totalObj[
                packagingTypeData.id + "-primaryUomValue"
              ]
                ? totalObj[packagingTypeData.id + "-primaryUomValue"] +
                rowData.primaryUomValue
                : rowData.primaryUomValue,
            };
          }
        });
        if (flag == 0) {
          t =
            t +
            `
    <td  align="left" "
    style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left"></td>
    <td  align="left" "
    style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left"></td>
    <td  align="left" "
    style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left"></td> `;
        }
      });
    });
    t = t + `<tr>`;
    t =
      t +
      `
<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">Total</td>

`;
    packagingTypeList.map((row) => {
      let totalQty = totalObj[row.id + "-totalQty"]
        ? totalObj[row.id + "-totalQty"]
        : 0;
      let secondaryUomValue = totalObj[row.id + "-secondaryUomValue"]
        ? totalObj[row.id + "-secondaryUomValue"]
        : 0;
      let primaryUomValue = totalObj[row.id + "-primaryUomValue"]
        ? totalObj[row.id + "-primaryUomValue"]
        : 0;
      finalTotalObj = {
        ...finalTotalObj,
        [row.id + "-totalQty"]: finalTotalObj[row.id + "-totalQty"]
          ? finalTotalObj[row.id + "-totalQty"] + totalQty
          : totalQty,
        [row.id + "-secondaryUomValue"]: finalTotalObj[
          row.id + "-secondaryUomValue"
        ]
          ? finalTotalObj[row.id + "-secondaryUomValue"] + secondaryUomValue
          : secondaryUomValue,
        [row.id + "-primaryUomValue"]: finalTotalObj[
          row.id + "-primaryUomValue"
        ]
          ? finalTotalObj[row.id + "-primaryUomValue"] + primaryUomValue
          : primaryUomValue,
      };
      let totalQtyData = totalObj[row.id + "-totalQty"]
        ? totalObj[row.id + "-totalQty"]
        : 0;

      let secondaryUomValueData = totalObj[row.id + "-secondaryUomValue"]
        ? totalObj[row.id + "-secondaryUomValue"]
        : 0;
      let primaryUomValueData = totalObj[row.id + "-primaryUomValue"]
        ? totalObj[row.id + "-primaryUomValue"]
        : 0;
      t =
        t +
        `
<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${totalQtyData}</td>

<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${secondaryUomValueData}</td>

<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${primaryUomValueData}</td>

`;
    });

    t = t + `</tr>`;
    return { DataToShow: t, finalTotalObj: finalTotalObj };
  };

  getDetailsMain = (dataToSet) => {
    const { formData, lang } = this.state;
    let t = ``;

    let categoryWithGroups =
      dataToSet.categoryWithGroups != null ? dataToSet.categoryWithGroups : [];
    let packagingTypeList =
      dataToSet.packagingType != null ? dataToSet.packagingType : [];
    let finalTotalObj = {};
    categoryWithGroups.map((categoryData) => {
      let details = this.getDetailsData(categoryData, dataToSet, finalTotalObj);
      finalTotalObj = details.finalTotalObj;
      t =
        t +
        `<tr> 
<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${categoryData.category.name +
        " ( " +
        categoryData.category.shortName +
        " ) "
        }</td>`;

      packagingTypeList.map((packType) => {
        t =
          t +
          `
  <td colspan="3" align="left" "
  style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left"></td>`;
      });
      t = t + `</tr>`;
      t = t + `${details.DataToShow}`;
    });
    t = t + `<tr>`;
    t =
      t +
      `
<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">Total</td>

`;
    packagingTypeList.map((row) => {
      let totalQtyData = finalTotalObj[row.id + "-totalQty"]
        ? finalTotalObj[row.id + "-totalQty"]
        : 0;

      let secondaryUomValueData = finalTotalObj[row.id + "-secondaryUomValue"]
        ? finalTotalObj[row.id + "-secondaryUomValue"]
        : 0;
      let primaryUomValueData = finalTotalObj[row.id + "-primaryUomValue"]
        ? finalTotalObj[row.id + "-primaryUomValue"]
        : 0;
      t =
        t +
        `
<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${totalQtyData}</td>

<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${secondaryUomValueData}</td>

<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${primaryUomValueData}</td>

`;
    });
    t = t + `</tr>`;
    return t;
  };

  generatePDF1 = async (response, rowData) => {
    var doc = new jsPDF();
    const { formData, lang } = this.state;
    let pdfTitle = langugae[lang].billDispatch
      ? langugae[lang].billDispatch
      : "";
    let date =
      formData.fromdate && formData.todate
        ? formData.fromdate + " To " + formData.todate
        : dayjs().format("DD-MM-YYYY") + " To " + dayjs().format("DD-MM-YYYY");
    let dataToSet = response.dtoLoadSheet;

    const { fieldData } = this.state;

    var pdf = new jsPDF();
    let fromBillContactPersonNo = localStorage.getItem("distributorName");
    let distName = langugae[lang].distName
      ? langugae[lang].distName + " : "
      : "";
    let beatName = langugae[lang].beatName
      ? langugae[lang].beatName + " : "
      : "";
    let beatNameData = response.beatName ? response.beatName : "-";
    let amount = langugae[lang].amount ? langugae[lang].amount + " : " : "";
    let amt = response.amt ? response.amt : "0";
    let dateInfo;
    let fromDate =
      fieldData.fromdate || moment(new Date()).format("DD-MM-YYYY");
    let toDate = fieldData.todate || moment(new Date()).format("DD-MM-YYYY");
    dateInfo = fromDate + " To " + toDate;
    let dateData = langugae[lang].date ? langugae[lang].date + " : " : "";
    let pdfLogo =
      localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
        ? logo
        : ofc;
    let htmlContent = "";
    htmlContent = `

    <style type="text/css">
    table {
      border-collapse: collapse;
      font-size: 10;
      width: 100%;
      page-break-inside: auto !important
    }
    </style>
    <div style="padding:20px">

        <table width="100%" border="0" cellpadding="0" cellspacing="0"
          style="border-left: 1px solid #313131; border-right: 1px solid #313131; border-top: 1px solid #313131;">



          <tr>
            <td width="60%" colspan="6" rowspan="2"
              style="border-left: 1px solid #313131; padding: 5px; color: #000; font-size: 15px; text-align: left">


              <h4

              style="color: #000; font-size: 16px; text-align: left; margin: 05px; float: left; margin-left: 5px; margin-top: 20px">&nbsp;${pdfTitle}</h4>

              <p
                style="color: #000; font-size: 12px; text-align: left; margin: 15px 0 0 0; display: inline-block; width: 100%">${distName + "" + fromBillContactPersonNo
      }<br />
                  ${beatName}${beatNameData}<br />
                 ${amount + "" + amt.toFixed(2)}
                  <br />
                  ${dateData + "" + dateInfo}

              </p> 

            </td>
            <td colspan="4" width="40%"
              style="border-left: 1px solid #313131; border-right: 1px solid #313131; padding: 10px; color: #FFF; font-size: 15px;">
              <img style="float: left;"
              src=${pdfLogo}
              alt="" /> 
            </td>

          </tr>


        </table>

        <table width="100%" border="1" cellpadding="0" cellspacing="0"
          style="border-top: 1px solid #313131; border-right: 1px solid #313131; border-left: 1px solid #313131; border:1px solid #313131">
          ${this.getHeaders(dataToSet)}
          ${this.getHeadersSub(dataToSet)}
          ${this.getDetailsMain(dataToSet)}


        </table>

    </div>

    `;

    // const virtualDiv = document.createElement('div');
    // virtualDiv.innerHTML = htmlContent;
    // document.body.appendChild(virtualDiv);

    // html2canvas(virtualDiv).then((canvas) => {

    //   var y = 0;
    //   pdf.addImage(canvas, 'JPEG', 0, y, pdf.internal.pageSize.width, 0);

    //   pdf.save(pdfTitle + " " + moment().format("DD-MM-YYYY") + ".pdf");
    // });

    // for (let i = 0; i < canvasRefs.length; i++) {
    // const htmlContent = canvasRefs[i];
    const virtualDiv = document.createElement("div");
    virtualDiv.innerHTML = htmlContent;
    document.body.appendChild(virtualDiv);

    const canvas = await html2canvas(virtualDiv);
    document.body.removeChild(virtualDiv);
    // if (i > 0) {
    //   pdf.addPage();
    // }
    var y = 0;
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "JPEG",
      0,
      y,
      pdf.internal.pageSize.width,
      0
    );
    // }
    pdf.save(pdfTitle + " " + moment().format("DD-MM-YYYY") + ".pdf");
  };

  generatePDF1Old = (response, rowData) => {
    var doc = new jsPDF();
    const { lang } = this.state;
    if (lang != 3) {
      doc.addFileToVFS(
        "TiroDevanagariHindi-Regular-normal.ttf",
        TiroDevanagariHindi
      );
      doc.addFont(
        "TiroDevanagariHindi-Regular-normal.ttf",
        "TiroDevanagariHindi-Regular",
        "normal"
      );
      doc.setFont("TiroDevanagariHindi-Regular");
    } else {
      doc.addFileToVFS("Shruti Font-normal.ttf", Aakar);
      doc.addFont("Shruti Font-normal.ttf", "Shruti Font", "normal");
      doc.setFont("Shruti Font");
    }
    var line = 10; // Line height to start text at
    var lineHeight = 5;
    var leftMargin = 5;
    var wrapWidth = 120;
    let dataToSet = response.dtoLoadSheet;

    let pdfTitle = langugae[lang].billDispatch
      ? langugae[lang].billDispatch
      : "";

    var textWidth =
      (doc.getStringUnitWidth(pdfTitle) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    var pageWidth = doc.internal.pageSize.getWidth();
    var xPos = (pageWidth - textWidth) / 2;
    doc.text(pdfTitle, 50, 10, { maxWidth: 540 });
    line = line + 7;

    doc.setFontSize(10);
    let fromBillContactPersonNo = localStorage.getItem("distributorName");
    let distName = langugae[lang].distName
      ? langugae[lang].distName + " : "
      : "";
    doc.text(distName + fromBillContactPersonNo, leftMargin, line, {
      maxWidth: wrapWidth,
    });
    line = line + 7;

    let yAxis = line;
    doc.text(
      langugae[lang].beatName ? langugae[lang].beatName + " : " : "",
      leftMargin,
      line
    );
    let fontName = lang != 3 ? "TiroDevanagariHindi-Regular" : "Shruti Font";

    doc.setFont(fontName, "normal");
    var addressText = response.beatName ? response.beatName : "-";
    var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
    for (var i = 0, length = splitTextAddr.length; i < length; i++) {
      // loop thru each line and increase
      if (i == 0) {
        doc.text(splitTextAddr[i], leftMargin + 22, line);
      } else {
        doc.text(splitTextAddr[i], leftMargin, line);
      }

      line = lineHeight + line;
    }

    var pageWidth = doc.internal.pageSize.getWidth();
    var xPos = pageWidth - 51;
    var xPos1 = pageWidth - 55;

    const { fieldData } = this.state;
    yAxis = yAxis + 7;
    let amt = response.amt ? response.amt : "-";
    let date;
    let fromDate =
      fieldData.fromdate || moment(new Date()).format("DD-MM-YYYY");
    let toDate = fieldData.todate || moment(new Date()).format("DD-MM-YYYY");
    date = fromDate + " To " + toDate;
    let amount = langugae[lang].amount ? langugae[lang].amount + " : " : "";
    doc.text(amount + amt.toFixed(2), leftMargin, yAxis, {
      maxWidth: wrapWidth,
    });
    yAxis = yAxis + 7;
    let dateData = langugae[lang].date ? langugae[lang].date + " : " : "";
    doc.text(dateData + date, leftMargin, yAxis, { maxWidth: wrapWidth });

    yAxis = yAxis + 5;

    doc.line(150, 3, 150, yAxis);
    let pdfLogo =
      localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
        ? logo
        : ofc;
    doc.addImage(pdfLogo, "JPEG", xPos1, 5, 45, 35);

    doc.line(3, yAxis, pageWidth - 3, yAxis);

    let headData = [
      langugae[lang].productName ? langugae[lang].productName + " : " : "",
    ];
    dataToSet.packagingType
      ? dataToSet.packagingType.map((row) => {
        headData.push({ content: row.name, colSpan: 3 });
      })
      : [];

    var align = {
      1: { halign: "right" },
      4: { halign: "right" },
      5: { halign: "right" },
    };
    let headData2 = [""];
    let index = 0;
    dataToSet.packagingType
      ? dataToSet.packagingType.map((row) => {
        index = index + 1;
        let first = index;
        index = index + 1;
        let second = index;
        index = index + 1;
        let third = index;
        align = {
          ...align,
          [first]: { halign: "right" },
          [second]: { halign: "right" },
          [third]: { halign: "right" },
        };

        let all = langugae[lang].all ? langugae[lang].all : "";

        let strip = langugae[lang].strip ? langugae[lang].strip : "";

        let cartoon = langugae[lang].cartoon ? langugae[lang].cartoon : "";

        headData2.push(all);
        headData2.push(strip);
        headData2.push(cartoon);
      })
      : [];
    console.log(align);
    let head = [headData, headData2];
    let categoryWithGroups =
      dataToSet.categoryWithGroups != null ? dataToSet.categoryWithGroups : [];
    let packagingTypeList =
      dataToSet.packagingType != null ? dataToSet.packagingType : [];
    let orderSummaries =
      dataToSet.orderSummaries != null ? dataToSet.orderSummaries : [];
    let productList = [];
    let finalTotal = [
      {
        content: [langugae[lang].finalTotal ? langugae[lang].finalTotal : ""],
        styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
      },
    ];
    let finalTotalObj = {};
    categoryWithGroups.map((categoryData) => {
      let catList = [
        {
          content:
            categoryData.category.name +
            " ( " +
            categoryData.category.shortName +
            " ) ",

          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        },
      ];
      packagingTypeList.map((packType) => {
        catList = [
          ...catList,
          {
            content: "",
            colSpan: 3,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
        ];
      });
      productList.push(catList);
      let totalList = [
        {
          content: langugae[lang].total ? langugae[lang].total : "",
          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        },
      ];
      let totalObj = {};
      categoryData.groups.map((groupData) => {
        let GruopInfo = [groupData.name];
        packagingTypeList.map((packagingTypeData) => {
          let flag = 0;
          orderSummaries.map((rowData) => {
            if (
              groupData.id == rowData.groupId &&
              rowData.packagingTypeId == packagingTypeData.id
            ) {
              flag = 1;
              GruopInfo.push(rowData.totalQty);
              GruopInfo.push(rowData.secondaryUomValue);
              GruopInfo.push(rowData.primaryUomValue);
              totalObj = {
                ...totalObj,
                [packagingTypeData.id + "-totalQty"]: totalObj[
                  packagingTypeData.id + "-totalQty"
                ]
                  ? totalObj[packagingTypeData.id + "-totalQty"] +
                  rowData.totalQty
                  : rowData.totalQty,
                [packagingTypeData.id + "-secondaryUomValue"]: totalObj[
                  packagingTypeData.id + "-secondaryUomValue"
                ]
                  ? totalObj[packagingTypeData.id + "-secondaryUomValue"] +
                  rowData.secondaryUomValue
                  : rowData.secondaryUomValue,
                [packagingTypeData.id + "-primaryUomValue"]: totalObj[
                  packagingTypeData.id + "-primaryUomValue"
                ]
                  ? totalObj[packagingTypeData.id + "-primaryUomValue"] +
                  rowData.primaryUomValue
                  : rowData.primaryUomValue,
              };
            }
          });
          if (flag == 0) {
            GruopInfo.push("");
            GruopInfo.push("");
            GruopInfo.push("");
          }
        });
        productList.push(GruopInfo);
      });
      packagingTypeList.map((row) => {
        let totalQty = totalObj[row.id + "-totalQty"]
          ? totalObj[row.id + "-totalQty"]
          : 0;
        let secondaryUomValue = totalObj[row.id + "-secondaryUomValue"]
          ? totalObj[row.id + "-secondaryUomValue"]
          : 0;
        let primaryUomValue = totalObj[row.id + "-primaryUomValue"]
          ? totalObj[row.id + "-primaryUomValue"]
          : 0;
        finalTotalObj = {
          ...finalTotalObj,
          [row.id + "-totalQty"]: finalTotalObj[row.id + "-totalQty"]
            ? finalTotalObj[row.id + "-totalQty"] + totalQty
            : totalQty,
          [row.id + "-secondaryUomValue"]: finalTotalObj[
            row.id + "-secondaryUomValue"
          ]
            ? finalTotalObj[row.id + "-secondaryUomValue"] + secondaryUomValue
            : secondaryUomValue,
          [row.id + "-primaryUomValue"]: finalTotalObj[
            row.id + "-primaryUomValue"
          ]
            ? finalTotalObj[row.id + "-primaryUomValue"] + primaryUomValue
            : primaryUomValue,
        };
        totalList.push({
          content: totalObj[row.id + "-totalQty"]
            ? totalObj[row.id + "-totalQty"]
            : 0,
          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        });
        totalList.push({
          content: totalObj[row.id + "-secondaryUomValue"]
            ? totalObj[row.id + "-secondaryUomValue"]
            : 0,

          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        });
        totalList.push({
          content: totalObj[row.id + "-primaryUomValue"]
            ? totalObj[row.id + "-primaryUomValue"]
            : 0,

          styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
        });
      });
      productList.push(totalList);
    });
    packagingTypeList.map((row) => {
      finalTotal.push({
        content: finalTotalObj[row.id + "-totalQty"]
          ? finalTotalObj[row.id + "-totalQty"]
          : 0,
        styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
      });
      finalTotal.push({
        content: finalTotalObj[row.id + "-secondaryUomValue"]
          ? finalTotalObj[row.id + "-secondaryUomValue"]
          : 0,
        styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
      });
      finalTotal.push({
        content: finalTotalObj[row.id + "-primaryUomValue"]
          ? finalTotalObj[row.id + "-primaryUomValue"]
          : 0,
        styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
      });
    });
    var fontSize = 8; // Set your desired font size
    var styles = {
      fontSize: fontSize,
      fontColor: "#000",
      font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Shruti Font",
    };
    doc.autoTable({
      head: head,
      body: productList,
      styles: styles,
      startY: yAxis + 5,
      columnStyles: align,
      margin: { right: 5, left: 5, top: 20 },
      headStyles: {
        halign: "center",
        lineWidth: 0.2,
        lineColor: "#fff",
        fillColor: [3, 169, 244],
        textColor: [255, 255, 255],
      },
      theme: "grid",

      showHead: "everyPage",

      didDrawPage: function (data) {
        let lineN = 10;
        doc.setTextColor(40);
        if (data.pageNumber > 1) {
          let fromBillContactPersonNo = localStorage.getItem("distributorName");

          let distName = langugae[lang].distName
            ? langugae[lang].distName + " : "
            : "";
          doc.text(distName + fromBillContactPersonNo, leftMargin, lineN, {
            maxWidth: wrapWidth,
          });

          let date = langugae[lang].date ? langugae[lang].date + " : " : "";

          doc.text(date + date, 150, lineN, {
            maxWidth: wrapWidth,
          });
          lineN = lineN + 7;

          let beatName = langugae[lang].beatName
            ? langugae[lang].beatName + " : "
            : "";

          doc.text(beatName, leftMargin, lineN, {
            maxWidth: wrapWidth,
          });

          var addressText = response.beatName ? response.beatName : "-";
          var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
          for (var i = 0, length = splitTextAddr.length; i < length; i++) {
            // loop thru each line and increase
            if (i == 0) {
              doc.text(splitTextAddr[i], leftMargin + 22, lineN);
            } else {
              doc.text(splitTextAddr[i], leftMargin, lineN);
            }

            lineN = lineHeight + lineN;
          }
        }
        var pageWidth = doc.internal.pageSize.getWidth();

        var str = "Page " + doc.internal.getNumberOfPages();

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        let pb = "Powered By Bizintel DMS";
        const compName = localStorage.getItem("compName");
        var textWidthCN =
          (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;
        var textWidth =
          (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
          doc.internal.scaleFactor;

        // Calculate the X position to align to the right of the page

        doc.setLineWidth(0.2);
        if (data.pageNumber > 1) {
          doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
        } else {
          doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
        }
        // Adjust for a margin if needed
        doc.setFontSize(10);
        var xPosCN = (pageWidth - textWidthCN) / 2;
        var xPos = pageWidth - textWidth - 10;
        doc.text(compName, 10, pageHeight - 10);

        doc.setFontSize(10);
        doc.text(pb, xPos, pageHeight - 10);
        doc.text(str, xPosCN, pageHeight - 10);
      },
    });
    doc.save(pdfTitle + " " + moment().format("DD-MM-YYYY") + ".pdf");
  };
  rowPDF = (rowData) => {
    // downloadF({
    //   url:
    //     endpoint.outletBillHeader +
    //     "/bill-pdf-by-id?id=" +
    //     rowData.id +
    //     "&documentType=1",
    //   ext: "pdf",
    //   openNewTab: true,
    // }).then((response) => {
    //   if (!response) {
    //     this.props.showNotification({
    //       msg: serverMsg,
    //       severity: "error",
    //     });
    //   } else {
    //   }
    // });
    const { formData, lang } = this.state;
    let dateInfo = {
      fromdate: formData.fromdate
        ? formData.fromdate
        : dayjs().format("DD-MM-YYYY"),
      todate: formData.todate ? formData.todate : dayjs().format("DD-MM-YYYY"),
    };
    console.log(JSON.stringify(dateInfo));

    const showCompanyFlag = localStorage.getItem("showCompanyFlag");
    if (showCompanyFlag == 2) {
      localStorage.setItem("loadSheetID", rowData.id);
      localStorage.setItem("dateForPDF", JSON.stringify(dateInfo));
      localStorage.setItem("lang", lang);
      window.open("./loadsheetUpdated-pdf", "_blank");

    } else {

      localStorage.setItem("loadSheetID", rowData.id);
      localStorage.setItem("dateForPDF", JSON.stringify(dateInfo));
      localStorage.setItem("lang", lang);

      window.open("./loadsheet-pdf", "_blank");
    }

  };
  rowPDF1 = (rowData) => {
    const { showNotification, showLoador } = this.props;
    const { lang, fromDate, toDate } = this.state;
    localStorage.setItem("lang", lang);
    localStorage.setItem("paymentsummaryID", rowData.id);
    window.open("./paymentsummary-pdf/" + fromDate + "/" + toDate, "_blank");
  };
  getPaymentList = (response, index) => {
    const { formData, lang } = this.state;
    let Total = 0;
    let indexData = index * 50;
    let t = "";
    for (let i in response) {
      indexData = indexData + 1;
      const rowData = response[i];
      t =
        t +
        `<tr>
        <td >${indexData}</td>
        <td >${rowData.billNo}</td>
        <td >${rowData.outlet.firmName}</td>
        <td >${rowData.outlet.ownerName}</td>
        <td >${rowData.outlet.beat.beatName}</td>
        <td >${rowData.outlet.mobileNo}</td>
        <td >${rowData.amt.toFixed(2)}</td>
        <td ></td>
            </tr>`;
    }
    t =
      t +
      `<tr> 
    <td >${langugae[lang].total ? langugae[lang].total : ""}</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >${Total.toFixed(2)}</td>
    <td ></td>
  </tr>`;
    return t;
  };
  pdfMethod = async (response) => {
    const { formData, lang } = this.state;
    let pdfTitle = langugae[lang].paymentSumm ? langugae[lang].paymentSumm : "";
    let date =
      formData.fromdate && formData.todate
        ? formData.fromdate + " To " + formData.todate
        : dayjs().format("DD-MM-YYYY") + " To " + dayjs().format("DD-MM-YYYY");
    let pdfTitle1 = " (" + date + ")";

    let rowListData = [
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
      ...response,
    ];
    const resultArray = this.splitArrayIntoNArrays(rowListData, 50);
    console.log(resultArray);
    var pdf = new jsPDF();
    let canvasRefs = resultArray.map((rowList, index) => {
      let htmlContent = "";
      if (index == 0) {
        htmlContent = `
      <style type="text/css">
      table {
       border-collapse: collapse;
       font-size: 10;
       width:100%;
     page-break-inside: auto !important ;

     } 
     p  {
         color: black;
         font-family: arial;
         font-size: 60%;
       margin-top: 0;
       padding: 0;

     }
     h6  {
         color: black;
         font-family: arial;
         font-size: 80%;
     }
     th {
       background-color: #03a9f4;
       color: white;
       width:50px;
       border: 1px solid #fff;
       text-align:center;
       font-size:14px;
     }
     td {
       border: 1px solid #dddddd;
       border-collapse: collapse;
       text-align:center;
       word-wrap: break-word;
       padding :4px;
       font-size:12px;
     }
     </style>

     <div style="padding:20px">
     <div> <h5 style="text-align: center;">  ${pdfTitle} ${pdfTitle1} </h5></div>
       <table>
         <thead>
           <tr> <th >${langugae[lang].srNO ? langugae[lang].srNO : ""}</th>
             <th > ${langugae[lang].billNO ? langugae[lang].billNO : ""}</th>
             <th  >${langugae[lang].outletName ? langugae[lang].outletName : ""
          }</th>
             <th  >${langugae[lang].ownerName ? langugae[lang].ownerName : ""
          }</th>
             <th >${langugae[lang].beatName ? langugae[lang].beatName : ""}</th>
             <th  >${langugae[lang].mobileNo ? langugae[lang].mobileNo : ""
          }</th>
             <th  >${langugae[lang].amount ? langugae[lang].amount : ""}</th>
             <th >${langugae[lang].recAmt ? langugae[lang].recAmt : ""}</th>
           </tr>
         </thead>
         <tbody>

         ${this.getPaymentList(rowList, index)}
         </tbody>
       </table>
       </div>`;
      } else {
        htmlContent = `
    <style type="text/css">
    table {
     border-collapse: collapse;
     font-size: 10;
     width:100%;
   page-break-inside: auto !important ;

   } 
   p  {
       color: black;
       font-family: arial;
       font-size: 60%;
     margin-top: 0;
     padding: 0;

   }
   h6  {
       color: black;
       font-family: arial;
       font-size: 80%;
   }
   th {
     background-color: #03a9f4;
     color: white;
     width:50px;
     border: 1px solid #fff;
     text-align:center;
     font-size:14px;
   }
   td {
     border: 1px solid #dddddd;
     border-collapse: collapse;
     text-align:center;
     word-wrap: break-word;
     padding :4px;
     font-size:12px;
   }
   </style>

   <div style="padding:20px">
   <div>
     <table>
       <thead>
         <tr> <th >${langugae[lang].srNO ? langugae[lang].srNO : ""}</th>
           <th > ${langugae[lang].billNO ? langugae[lang].billNO : ""}</th>
           <th  >${langugae[lang].outletName ? langugae[lang].outletName : ""
          }</th>
           <th  >${langugae[lang].ownerName ? langugae[lang].ownerName : ""
          }</th>
           <th >${langugae[lang].beatName ? langugae[lang].beatName : ""}</th>
           <th  >${langugae[lang].mobileNo ? langugae[lang].mobileNo : ""}</th>
           <th  >${langugae[lang].amount ? langugae[lang].amount : ""}</th>
           <th >${langugae[lang].recAmt ? langugae[lang].recAmt : ""}</th>
         </tr>
       </thead>
       <tbody>

       ${this.getPaymentList(rowList, index)}
       </tbody>
     </table>
     </div>`;
      }
      return htmlContent;
    });

    for (let i = 0; i < canvasRefs.length; i++) {
      const htmlContent = canvasRefs[i];
      const virtualDiv = document.createElement("div");
      virtualDiv.innerHTML = htmlContent;
      document.body.appendChild(virtualDiv);

      const canvas = await html2canvas(virtualDiv);
      document.body.removeChild(virtualDiv);
      if (i > 0) {
        pdf.addPage();
      }
      var y = 0;
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "JPEG",
        0,
        y,
        pdf.internal.pageSize.width,
        0
      );
    }
    pdf.save(pdfTitle + " " + moment().format("DD-MM-YYYY") + ".pdf");
  };

  rowPDFBill = (rowData) => {
    const { showLoador, showNotification } = this.props;
    const { lang } = this.state;
    localStorage.setItem("lang", lang);
    const showPdfType = localStorage.getItem("showPdf");

    if (showPdfType == 0) {
      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      if (showCompanyFlag == 2) {
        localStorage.setItem("billPdfId", rowData.id);
        window.open("./simple-bill-pdf", "_blank");
      } else {
        localStorage.setItem("billPdfId", rowData.id);
        window.open("./bill-pdf", "_blank");
      }
    }

    if (showPdfType == 1) {
      localStorage.setItem("billPdfNewId", rowData.id);
      window.open("./bill-pdf-new", "_blank");
    }
    if (showPdfType == 2) {
      if (navigator.onLine) {
        let billPdfNewData = {
          outletBillHeaderIds: rowData.id.split(","),
          languageId: lang,
        };

        showLoador({ loador: true });
        apiPost({
          url: endpoint.outletBillHeader + "/report-by-ids",
          postBody: billPdfNewData,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            console.log(data.data);
            if (data.data.length != 0) {
              this.generatePDFHeaderOLd(data.data);
            }
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  generatePDFHeader = async (rowList) => {
    var doc = new jsPDF();
    const { formData, lang } = this.state;

    const { fieldData } = this.state;

    var pdf = new jsPDF();

    let pdfLogo =
      localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
        ? logo
        : ofc;

    let canvasRefs = rowList.map((dataToSet, indexMain) => {
      let toBill = dataToSet.outLet ? dataToSet.outLet : {};
      let stockStakeHolderFirmName = dataToSet.stockStakeHolderFirmName
        ? dataToSet.stockStakeHolderFirmName
        : "-";
      let ownerName = langugae[lang].ownerName
        ? langugae[lang].ownerName + " : "
        : "";

      var stockStakeHolderOwnerName = dataToSet.stockStakeHolderOwnerName
        ? ownerName + dataToSet.stockStakeHolderOwnerName
        : "-";

      let address = langugae[lang].address
        ? langugae[lang].address + " : "
        : "";

      var addressText = dataToSet.stockStakeHolderAddress
        ? address + dataToSet.stockStakeHolderAddress
        : "-";
      let fromBillContactPersonNo = dataToSet.stockStakeHolderContactPersonNo
        ? dataToSet.stockStakeHolderContactPersonNo
        : "-";
      let mobileNo = langugae[lang].mobileNo
        ? langugae[lang].mobileNo + " : "
        : "";
      let fromBillFssiFdiNo = dataToSet.stockStakeHolderFssiFdiNo
        ? dataToSet.stockStakeHolderFssiFdiNo
        : "-";
      let fromBillGstNo = dataToSet.stockStakeHolderGstNo
        ? dataToSet.stockStakeHolderGstNo
        : "-";
      let gSTNo = langugae[lang].gSTNo ? langugae[lang].gSTNo + " : " : "";
      let FSSAINo = langugae[lang].FSSAINo
        ? langugae[lang].FSSAINo + " : "
        : "";

      let billTo = langugae[lang].billTo ? langugae[lang].billTo + " : " : "";
      var firmName = toBill.firmName ? toBill.firmName : "";

      var ownerNameData = toBill.ownerName ? ownerName + toBill.ownerName : "-";

      let taluka =
        toBill.beat != null &&
          toBill.beat.area != null &&
          toBill.beat.area.taluka != null
          ? toBill.beat.area.taluka
          : "";
      let talukaName = taluka != null ? taluka.name : "";
      let districtName =
        taluka != null && taluka.district != null ? taluka.district.name : "";
      let addr = toBill.address
        ? toBill.address + " - " + talukaName + " - " + districtName
        : "";
      var addressDataText = address + addr;
      let fssiFdiNoData =
        toBill.fssiFdiNo != null && toBill.fssiFdiNo != ""
          ? toBill.fssiFdiNo
          : "-";
      let contactPersonNoData = toBill.mobileNo ? toBill.mobileNo : "";
      let gstNoData = toBill.gstNo != null ? toBill.gstNo : "-";

      let taxInvoice = langugae[lang].taxInvoice
        ? langugae[lang].taxInvoice
        : "";
      let billNoLan = langugae[lang].billNO
        ? langugae[lang].billNO + " : "
        : "";
      let billNo = dataToSet.billNo ? dataToSet.billNo : "-";

      let date = langugae[lang].date ? langugae[lang].date + " : " : "";
      let billDate = dataToSet.billDate ? dataToSet.billDate : "-";

      let time = langugae[lang].time ? langugae[lang].time + " : " : "";
      let insertDateTime = dataToSet.insertDateTime
        ? dataToSet.insertDateTime.split(" ")
        : [];
      let insertDateTimeData =
        insertDateTime.length == 2 ? insertDateTime[1] : "";
      let htmlContent = "";
      htmlContent = `
    <style type="text/css">
    table {
      border-collapse: collapse;
      font-size: 10;
      width: 100%;
      page-break-inside: auto !important
    }
    </style>
    <div style="padding:20px">

        <table width="100%" border="0" cellpadding="0" cellspacing="0"
          style="border-left: 1px solid #313131; border-right: 1px solid #313131; border-top: 1px solid #313131;">



          <tr>
            <td width="80%" colspan="6" rowspan="2"
              style="border-left: 1px solid #313131; padding: 5px; color: #000; font-size: 15px; text-align: left">


              <h4

              style="color: #000; font-size: 16px; text-align: left; margin: 05px; float: left; margin-left: 5px; margin-top: 20px">&nbsp;${stockStakeHolderFirmName}</h4>

              <p
                style="color: #000; font-size: 12px; text-align: left; margin: 15px 0 0 0; display: inline-block; width: 100%">
                  ${stockStakeHolderOwnerName}<br />
                 ${addressText}
                  <br />
                  ${mobileNo + "" + fromBillContactPersonNo}<br />
                  ${gSTNo +
        "" +
        fromBillGstNo +
        " , " +
        FSSAINo +
        "" +
        fromBillFssiFdiNo
        }

              </p> 

            </td>
            <td colspan="4" width="20%"
              style="border-left: 1px solid #313131; border-right: 1px solid #313131; padding: 10px; color: #FFF; font-size: 15px;">
              <img style="float: left;"
              src=${pdfLogo}
              alt="" /> 
            </td>

          </tr>


        </table>
        <table width="100%" border="0" cellpadding="0" cellspacing="0"
        style="border-left: 1px solid #313131; border-right: 1px solid #313131; border-top: 1px solid #313131;">



        <tr>
          <td width="80%" colspan="6" rowspan="2"
            style="border-left: 1px solid #313131; padding: 5px; color: #000; font-size: 15px; text-align: left">




            <p
              style="color: #000; font-size: 12px; text-align: left; margin: 15px 0 0 0; display: inline-block; width: 100%">
                ${billTo + "" + firmName}<br />
               ${ownerNameData}
                <br />
                ${addressDataText}<br />
                ${mobileNo +
        "" +
        contactPersonNoData +
        " , " +
        gSTNo +
        "" +
        gstNoData
        }<br />
                ${FSSAINo + "" + fssiFdiNoData}

            </p> 

          </td>
          <td colspan="4" width="20%"
            style="border-left: 1px solid #313131; border-right: 1px solid #313131; padding: 10px; color: #FFF; font-size: 15px;">
            <p
            style="color: #000; font-size: 12px; text-align: left; margin: 15px 0 0 0; display: inline-block; width: 100%">
              ${taxInvoice}<br />
             ${billNoLan + "" + billNo}
              <br />
              ${date + "" + billDate}<br />
              ${time + "" + insertDateTimeData}

          </p> 
          </td>

        </tr>


      </table>

        <table width="100%" border="1" cellpadding="0" cellspacing="0"
          style="border-top: 1px solid #313131; border-right: 1px solid #313131; border-left: 1px solid #313131; border:1px solid #313131">

      ${this.getBillP(dataToSet)}
        </table>
        <br/>
        <table width="100%" border="1" cellpadding="0" cellspacing="0"
        style="border-top: 1px solid #313131; border-right: 1px solid #313131; border-left: 1px solid #313131; border:1px solid #313131">
        <tr> <td  colspan="3" align="left" "
        style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${langugae[lang].note ? langugae[lang].note + " : " : ""
        }${dataToSet.orderRemarks != null ? dataToSet.orderRemarks : ""}</td> 
            </tr>
        <tr> <td  align="left" "
        style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${langugae[lang].orderBy ? langugae[lang].orderBy + " : " : ""
        }${dataToSet.salesTeamNames ? dataToSet.salesTeamNames : ""
        }</td> <td rowspan="3" align="left" "
            style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left"></td>
                <td rowspan="2" align="left" "
        style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left"></td>
                </tr>
                <tr> <td  align="left" "
                style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${langugae[lang].goodsCheckBeforeReceived
          ? langugae[lang].goodsCheckBeforeReceived + " : "
          : ""
        }</td> 
                  </tr>

                        <tr> <td  align="left" "
                        style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${langugae[lang].subjectToJurisdiction
          ? langugae[lang].subjectToJurisdiction + " : "
          : ""
        }</td> <td  align="left" "
                        style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${langugae[lang].aurthorisedSign
          ? langugae[lang].aurthorisedSign
          : ""
        }</td>
                                </tr>
      </table>
    </div>

    `;
      return htmlContent;
    });

    for (let i = 0; i < canvasRefs.length; i++) {
      const htmlContent = canvasRefs[i];
      const virtualDiv = document.createElement("div");
      virtualDiv.innerHTML = htmlContent;
      document.body.appendChild(virtualDiv);

      const canvas = await html2canvas(virtualDiv);
      document.body.removeChild(virtualDiv);
      if (i > 0) {
        pdf.addPage();
      }
      var y = 0;
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "JPEG",
        0,
        y,
        pdf.internal.pageSize.width,
        0
      );
    }
    let bill = langugae[lang].bill ? langugae[lang].bill : "";
    pdf.save(bill + moment().format("DD-MM-YYYY") + ".pdf");
  };

  getBillP = (dataToSet) => {
    const { formData, lang } = this.state;

    let fillingType = langugae[lang].fillingType
      ? langugae[lang].fillingType + " : "
      : "";
    let t = ``;
    t =
      t +
      `<tr> <td  align="left" "
    style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${fillingType}</td>
    `;
    dataToSet.categories.map((categoryData, index) => {
      t =
        t +
        `
<td  align="left" "
style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${categoryData.name}</td>

`;
    });

    t = t + `</tr>`;
    dataToSet.packagingTypes.map((packagingTypeData) => {
      t =
        t +
        `<tr> <td  align="left" "
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${packagingTypeData.name}</td>
      `;
      dataToSet.categories.map((categoryData) => {
        let flag = 0;
        dataToSet.dtoCategoryWithPackagingTypes.map((rowData) => {
          if (
            categoryData.id == rowData.category.id &&
            rowData.packagingType.id == packagingTypeData.id
          ) {
            flag = 1;
            t =
              t +
              `
      <td  align="left" "
      style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left">${rowData.totalQty}</td>

      `;
          }
        });
        if (flag == 0) {
          t =
            t +
            `
    <td  align="left" "
    style="border-bottom: 1px solid #313131; border-left: 1px solid #313131; padding: 15px; color: #000; font-size: 10px; text-align: left"></td>

    `;
        }
      });
      t = t + `</tr>`;
    });
    return t;
  };
  generatePDFHeaderOLd = (rowList) => {
    var doc = new jsPDF();
    const { lang } = this.state;
    if (lang != 3) {
      doc.addFileToVFS(
        "TiroDevanagariHindi-Regular-normal.ttf",
        TiroDevanagariHindi
      );
      doc.addFont(
        "TiroDevanagariHindi-Regular-normal.ttf",
        "TiroDevanagariHindi-Regular",
        "normal"
      );
      doc.setFont("TiroDevanagariHindi-Regular");
    } else {
      doc.addFileToVFS("Shruti Font-normal.ttf", Aakar);
      doc.addFont("Shruti Font-normal.ttf", "Shruti Font", "normal");
      doc.setFont("Shruti Font");
    }
    let fontName = lang != 3 ? "TiroDevanagariHindi-Regular" : "Shruti Font";
    rowList.map((dataToSet, indexMain) => {
      let indexInfoData = 0;
      var line = 10; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var wrapWidth = 120;
      doc.setFontSize(12);
      var DelerText = dataToSet.stockStakeHolderFirmName
        ? dataToSet.stockStakeHolderFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }

      let ownerName = langugae[lang].ownerName
        ? langugae[lang].ownerName + " : "
        : "";

      doc.setFontSize(10);
      line = line + 2;
      var DelerText = dataToSet.stockStakeHolderOwnerName
        ? ownerName + dataToSet.stockStakeHolderOwnerName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      line = line + 2;

      doc.setFont(fontName, "normal");
      let address = langugae[lang].address
        ? langugae[lang].address + " : "
        : "";

      var addressText = dataToSet.stockStakeHolderAddress
        ? address + dataToSet.stockStakeHolderAddress
        : "-";
      var splitTextAddr = doc.splitTextToSize(addressText, wrapWidth);
      for (var i = 0, length = splitTextAddr.length; i < length; i++) {
        // loop thru each line and increase
        if (i == 0) {
          doc.text(splitTextAddr[i], leftMargin, line);
        } else {
          doc.text(splitTextAddr[i], leftMargin, line);
        }

        line = lineHeight + line;
      }
      let yAxis = line + 2;
      var pageWidth = doc.internal.pageSize.getWidth();
      var xPos = pageWidth - 50;
      var xPos1 = pageWidth - 55;
      var top = 0;

      let fromBillContactPersonNo = dataToSet.stockStakeHolderContactPersonNo
        ? dataToSet.stockStakeHolderContactPersonNo
        : "-";
      let mobileNo = langugae[lang].mobileNo
        ? langugae[lang].mobileNo + " : "
        : "";
      doc.text(mobileNo + fromBillContactPersonNo, leftMargin, yAxis, {
        maxWidth: wrapWidth,
      });

      yAxis = yAxis + 7;
      let fromBillFssiFdiNo = dataToSet.stockStakeHolderFssiFdiNo
        ? dataToSet.stockStakeHolderFssiFdiNo
        : "-";
      let fromBillGstNo = dataToSet.stockStakeHolderGstNo
        ? dataToSet.stockStakeHolderGstNo
        : "-";
      let gSTNo = langugae[lang].gSTNo ? langugae[lang].gSTNo + " : " : "";
      let FSSAINo = langugae[lang].FSSAINo
        ? langugae[lang].FSSAINo + " : "
        : "";

      doc.text(
        gSTNo + fromBillGstNo + " , " + FSSAINo + fromBillFssiFdiNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );
      doc.line(150, 5, 150, 50);
      let pdfLogo =
        localStorage.getItem("compId") == "5c140f80-3929-46dc-83b1-592b7bc30d1f"
          ? logo
          : ofc;
      doc.addImage(pdfLogo, "JPEG", xPos1, 5, 45, 35);
      yAxis = yAxis + 5;
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      yAxis = yAxis + 7;
      var xPosTax = pageWidth - 57;
      let yAxisTax = yAxis;
      doc.setFontSize(10);
      doc.setFont(fontName, "bold");
      let taxInvoice = langugae[lang].taxInvoice
        ? langugae[lang].taxInvoice + " : "
        : "";
      doc.text("Tax Invoice", xPos, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;

      let billNoLan = langugae[lang].billNO
        ? langugae[lang].billNO + " : "
        : "";
      doc.text("Bill No.", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let billNo = dataToSet.billNo ? dataToSet.billNo : "-";

      doc.text(billNo, xPosTax + 17, yAxisTax, { maxWidth: 100 });
      yAxisTax = yAxisTax + 7;
      doc.setFont(fontName, "bold");
      let date = langugae[lang].date ? langugae[lang].date + " : " : "";
      doc.text("Date", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");
      let billDate = dataToSet.billDate ? dataToSet.billDate : "-";

      doc.text(billDate, xPosTax + 17, yAxisTax, { maxWidth: wrapWidth });
      yAxisTax = yAxisTax + 7;
      doc.setFont(fontName, "bold");
      let insertDateTime = dataToSet.insertDateTime
        ? dataToSet.insertDateTime.split(" ")
        : [];
      let insertDateTimeData =
        insertDateTime.length == 2 ? insertDateTime[1] : "";
      let time = langugae[lang].time ? langugae[lang].time + " : " : "";
      doc.text("Time", xPosTax, yAxisTax, { maxWidth: wrapWidth });
      doc.setFont(fontName, "normal");

      doc.text(insertDateTimeData, xPosTax + 17, yAxisTax, {
        maxWidth: wrapWidth,
      });
      line = yAxis;
      top = 10;
      doc.setFontSize(10);
      let billTo = langugae[lang].billTo ? langugae[lang].billTo + " : " : "";
      doc.text(billTo, leftMargin, line);
      let toBill = dataToSet.outLet ? dataToSet.outLet : {};
      var DText = toBill.firmName ? toBill.firmName : "";
      var splitTextDText = doc.splitTextToSize(DText, wrapWidth);
      for (var i = 0, length = splitTextDText.length; i < length; i++) {
        if (i == 0) {
          doc.text(splitTextDText[i], leftMargin + 15, line);
        } else {
          doc.text(splitTextDText[i], leftMargin, line);
        }
        line = lineHeight + line;
        top = top + lineHeight;
      }

      line = line + 2;
      var DelerText = toBill.ownerName ? ownerName + toBill.ownerName : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin, line);
        line = lineHeight + line;
      }
      doc.setFont(fontName, "normal");

      doc.setFontSize(10);
      line = line + 2;
      top = top + 2;
      let taluka =
        toBill.beat != null &&
          toBill.beat.area != null &&
          toBill.beat.area.taluka != null
          ? toBill.beat.area.taluka
          : "";
      let talukaName = taluka != null ? taluka.name : "";
      let districtName =
        taluka != null && taluka.district != null ? taluka.district.name : "";
      let addr = toBill.address
        ? toBill.address + " - " + talukaName + " - " + districtName
        : "";
      var DAddrText = address + addr;
      var splitTextDAddrText = doc.splitTextToSize(DAddrText, wrapWidth);
      for (var i = 0, length = splitTextDAddrText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitTextDAddrText[i], leftMargin, line);
        line = lineHeight + line;
        top = top + lineHeight;
      }
      yAxis = line + 2;
      top = top + 2;
      let contactPersonNo = toBill.mobileNo ? toBill.mobileNo : "";
      let gstNo = toBill.gstNo != null ? toBill.gstNo : "-";

      let phoneNo = langugae[lang].phoneNo
        ? langugae[lang].phoneNo + " : "
        : "";
      doc.text(
        phoneNo + contactPersonNo + " , " + gSTNo + gstNo,
        leftMargin,
        yAxis,
        { maxWidth: wrapWidth }
      );

      yAxis = yAxis + 7;
      top = top + 7;
      let fssiFdiNo =
        toBill.fssiFdiNo != null && toBill.fssiFdiNo != ""
          ? toBill.fssiFdiNo
          : "-";

      doc.text(FSSAINo + fssiFdiNo, leftMargin, yAxis, { maxWidth: 100 });

      yAxis = yAxis + 7;
      top = top + 7;
      doc.line(150, 3, 150, yAxis);
      doc.line(3, yAxis, pageWidth - 3, yAxis);
      let toBillState = toBill.state != null ? toBill.state : {};
      let dataToSetState = dataToSet.state != null ? dataToSet.state : {};

      let HSN = langugae[lang].HSN ? langugae[lang].HSN + " : " : "";

      let itemName = langugae[lang].itemName
        ? langugae[lang].itemName + " : "
        : "";

      let uom = langugae[lang].uom ? langugae[lang].uom + " : " : "";

      let qty = langugae[lang].qty ? langugae[lang].qty + " : " : "";

      let rate = langugae[lang].rate ? langugae[lang].rate + " : " : "";

      let CGST = langugae[lang].CGST ? langugae[lang].CGST + " : " : "";

      let SGST = langugae[lang].SGST ? langugae[lang].SGST + " : " : "";

      let amount = langugae[lang].amount ? langugae[lang].amount + " : " : "";

      let IGST = langugae[lang].IGST ? langugae[lang].IGST + " : " : "";

      let total = langugae[lang].total ? langugae[lang].total + " : " : "";

      let headData = [
        { content: "#" },
        { content: HSN },
        { content: itemName },
        { content: uom },
        { content: qty },
        { content: rate },
      ];

      var align = {
        1: { halign: "center" },
        4: { halign: "right" },
        5: { halign: "right" },
      };
      let headData2 = [];
      if (toBillState.id == dataToSetState.id) {
        headData = [
          ...headData,
          { content: CGST, colSpan: 2 },
          { content: SGST, colSpan: 2 },
          { content: amount },
        ];
        headData2 = ["", "", "", "", "", "", "%", amount, "%", amount, ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
          9: { halign: "right" },
          10: { halign: "right" },
        };
      } else {
        headData = [
          ...headData,
          { content: IGST, colSpan: 2 },
          { content: amount },
        ];
        headData2 = ["", "", "", "", "", "", "%", amount, ""];
        align = {
          ...align,
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
        };
      }

      let head = [headData, headData2];
      let stockStakeHolderBillDetails =
        dataToSet.outletBillDetails != null ? dataToSet.outletBillDetails : [];
      let productList = [];
      // dataToSet.packagingTypes.map((packaginType)=>{})

      console.log(productList);
      var data = [];
      let totalAmt = 0;
      let qtyTot = 0;
      dataToSet.packagingTypes.map((packagingType) => {
        data.push([
          {
            content: packagingType.name,
            colSpan: 11,

            styles: { fontWeight: 900, fontSize: 10, fontColor: "#000" },
          },
        ]);
        let indexInfo = 0;
        stockStakeHolderBillDetails.map((stockDetails, indexData) => {
          let packagingTypeDetails =
            stockDetails.product != null &&
              stockDetails.product.packagingType != null
              ? stockDetails.product.packagingType
              : {};

          if (packagingType.id == packagingTypeDetails.id) {
            let productData = [];
            let product =
              stockDetails.product != null ? stockDetails.product : {};
            indexInfo = indexInfo + 1;
            productData.push(indexInfo);
            productData.push(stockDetails.hsnCode);
            productData.push(product.name);
            productData.push(
              stockDetails.uom != null ? stockDetails.uom.name : "-"
            );
            productData.push(stockDetails.qty);
            qtyTot = qtyTot + stockDetails.qty;
            productData.push(stockDetails.rate.toFixed(2));
            if (toBillState.id == dataToSetState.id) {
              productData.push(stockDetails.cgstPer.toFixed(2));
              productData.push(stockDetails.cgstAmt.toFixed(2));
              productData.push(stockDetails.sgstPer.toFixed(2));
              productData.push(stockDetails.sgstAmt.toFixed(2));
            } else {
              productData.push(stockDetails.igstPer.toFixed(2));
              productData.push(stockDetails.igstAmt.toFixed(2));
            }
            totalAmt = totalAmt + stockDetails.totalAmt;
            productData.push(stockDetails.totalAmt.toFixed(2));
            data.push(productData);
          }
        });
      });

      if (toBillState.id == dataToSetState.id) {
        data.push([
          {
            content: total,
            colSpan: 4,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          {
            content: qtyTot,
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
          "",
          "",
          "",
          "",
          "",
          {
            content: totalAmt.toFixed(2),
            styles: { fontWeight: 900, fontSize: 12, fontColor: "#000" },
          },
        ]);
      } else {
        data.push([
          {
            content: total,
            colSpan: 4,
            styles: { fontWeight: "bold" },
          },
          qtyTot,
          "",
          "",
          "",
          totalAmt.toFixed(2),
        ]);
      }
      var fontSize = 8; // Set your desired font size
      var styles = {
        fontSize: fontSize,
        fontColor: "#000",
        font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Shruti Font",
      };

      doc.autoTable({
        head: head,
        body: data,
        startY: yAxis + 5,
        columnStyles: align,
        margin: { right: 5, left: 5, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        styles: styles,
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) {
          doc.setTextColor(40);
          var pageWidth = doc.internal.pageSize.getWidth();
          if (data.pageNumber > 1) {
            var xPos1 = pageWidth - 45;
            var line1 = 15;
            var yAxisTax = 10;
            doc.setFontSize(10);
            doc.setFont(fontName);
            let taxInvoice = langugae[lang].taxInvoice
              ? langugae[lang].taxInvoice + " : "
              : "";
            doc.text(taxInvoice, 90, 10);
            var lineHeight1 = 5;
            var wrapWidth1 = 130;
            doc.setFontSize(10);

            let billTO = langugae[lang].billTO
              ? langugae[lang].billTO + " : "
              : "";
            doc.text(billTO, leftMargin, line1);
            doc.setFontSize(10);
            let toBill = dataToSet.outLet ? dataToSet.outLet : {};

            var DText = toBill.ownerName ? toBill.ownerName : "";
            var splitTextDText = doc.splitTextToSize(DText, wrapWidth1);
            for (var i = 0, length = splitTextDText.length; i < length; i++) {
              // loop thru each line and increase

              if (i == 0) {
                doc.text(splitTextDText[i], leftMargin + 15, line1);
              } else {
                doc.text(splitTextDText[i], leftMargin, line1);
              }
              line1 = lineHeight1 + line1;
            }
            let billDate = dataToSet.billDate ? dataToSet.billDate : "-";

            let text = dataToSet.billNo
              ? dataToSet.billNo + " (" + billDate + ")"
              : "-";
            var textWidth1 =
              (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
              doc.internal.scaleFactor;

            var xPos1 = pageWidth - textWidth1 - 10;
            doc.setFont(fontName, "normal");
            doc.text(text, xPos1, 15, { maxWidth: 100 });
          }
          indexInfoData = indexInfoData + 1;
          var str = "Page " + indexInfoData;

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          let pb = "Powered By Bizintel DMS";
          const compName = localStorage.getItem("compName");
          var textWidthCN =
            (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;
          var textWidth =
            (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

          // Calculate the X position to align to the right of the page

          doc.setLineWidth(0.2);
          if (data.pageNumber > 1) {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          } else {
            doc.rect(3, 3, pageWidth - 6, pageHeight - 8);
          }
          // Adjust for a margin if needed
          doc.setFontSize(10);
          var xPosCN = (pageWidth - textWidthCN) / 2;
          var xPos = pageWidth - textWidth - 10;
          doc.text(compName, 10, pageHeight - 10);

          doc.setFontSize(10);
          doc.text(pb, xPos, pageHeight - 10);
          doc.text(str, xPosCN, pageHeight - 10);
        },
      });

      let fillingType = langugae[lang].fillingType
        ? langugae[lang].fillingType + " : "
        : "";

      let productHeadWithPack =
        dataToSet.packagingTypes.length != 0 ? [fillingType] : [];
      let productListWithPack = [];
      let productListalign = { 0: { cellWidth: 50 } };
      dataToSet.categories.map((categoryData, index) => {
        productHeadWithPack.push(categoryData.name);
        productListalign = {
          ...productListalign,
          [index + 1]: { halign: "right" },
        };
      });

      dataToSet.packagingTypes.map((packagingTypeData) => {
        let GruopInfo = [packagingTypeData.name];
        dataToSet.categories.map((categoryData) => {
          let flag = 0;
          dataToSet.dtoCategoryWithPackagingTypes.map((rowData) => {
            if (
              categoryData.id == rowData.category.id &&
              rowData.packagingType.id == packagingTypeData.id
            ) {
              flag = 1;
              GruopInfo.push(rowData.totalQty);
            }
          });
          if (flag == 0) {
            GruopInfo.push("");
          }
        });
        productListWithPack.push(GruopInfo);
      });
      var styles1 = {
        font: lang != 3 ? "TiroDevanagariHindi-Regular" : "Shruti Font",
      };
      console.log(productListWithPack);
      console.log(productHeadWithPack);
      doc.autoTable({
        head: [productHeadWithPack],
        body: productListWithPack,
        styles: styles1,
        startY: doc.autoTableEndPosY() + 5,
        columnStyles: productListalign,
        margin: { right: 6, left: 6, top: 20 },
        headStyles: {
          halign: "center",
          lineWidth: 0.2,
          lineColor: "#fff",
          fillColor: [3, 169, 244],
          textColor: [255, 255, 255],
        },
        theme: "grid",

        showHead: "everyPage",

        didDrawPage: function (data) { },
      });
      var line = doc.autoTableEndPosY() + 5; // Line height to start text at
      var lineHeight = 5;
      var leftMargin = 5;
      var leftMarginData = 152;
      var wrapWidth = 180;
      let note = langugae[lang].note ? langugae[lang].note + " : " : "";
      doc.text(note, leftMargin, line);
      var DelerText =
        dataToSet.orderRemarks != null ? dataToSet.orderRemarks : "";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMargin + 10, line);
        line = lineHeight + line;
      }
      let orderBy = langugae[lang].orderBy
        ? langugae[lang].orderBy + " : "
        : "";
      doc.line(3, line, pageWidth - 3, line);
      let lineData = line;
      var line = line + 5;
      let salesTeamNames = dataToSet.salesTeamNames
        ? dataToSet.salesTeamNames
        : "";
      doc.text("1." + orderBy + salesTeamNames, leftMargin, line);
      let lineOrder = line;
      var wrapWidth = 50;
      var DelerText = dataToSet.stockStakeHolderFirmName
        ? dataToSet.stockStakeHolderFirmName
        : "-";
      var splitText = doc.splitTextToSize(DelerText, wrapWidth + 10);
      for (var i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        doc.text(splitText[i], leftMarginData, lineOrder);
        lineOrder = lineHeight + lineOrder;
      }
      var line = line + 2;
      doc.line(3, line, 90, line);
      var line = line + 5;
      let subjectToJurisdiction = langugae[lang].subjectToJurisdiction
        ? langugae[lang].subjectToJurisdiction + " : "
        : "";
      let distName = localStorage.getItem("districtName");
      let aurthorisedSign = langugae[lang].aurthorisedSign
        ? langugae[lang].aurthorisedSign + " : "
        : "";

      let goodsCheckBeforeReceived = langugae[lang].goodsCheckBeforeReceived
        ? langugae[lang].goodsCheckBeforeReceived + " : "
        : "";

      doc.text("2." + goodsCheckBeforeReceived, leftMargin, line);
      var line = line + 2;
      doc.line(3, line, 90, line);
      var line = line + 5;
      doc.text("3. " + subjectToJurisdiction + distName, leftMargin, line);

      doc.text(aurthorisedSign, leftMarginData, line);
      var line = line + 5;
      doc.line(90, lineData, 90, line);
      doc.line(150, lineData, 150, line);
      doc.line(3, line, pageWidth - 3, line);
      if (indexMain + 1 != rowList.length) {
        doc.addPage();
      }
    });

    let bill = langugae[lang].bill ? langugae[lang].bill + " : " : "";
    doc.save(bill + moment().format("DD-MM-YYYY") + ".pdf");
  };

  onDeleteStatic = async (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url:
          endpoint.outletBillHeader + "/update?id=" + rowData.id + "&status=2",
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          showNotification({ msg: cancelledMsg, severity: "success" });
        }
      });
      showLoador({ loador: false });
      if (this.onSearchList(this.state.formData)) {
        showNotification({ msg: cancelledMsg, severity: "success" });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getSelectedList = (e) => {
    const { billingHistoryList } = this.props;
    this.setState({
      selection: e,
    });
    const rowList = this.props.billingHistoryList.billingHistory.map(
      (rowData) => {
        const filterData = e.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      }
    );
  };
  onLoadSheet = () => {
    const { showNotification, billingHistoryList } = this.props;
    const { selection, toDate, fromDate } = this.state;


    if (fromDate && toDate) {
      const dateDiff = Math.abs(dayjs(toDate, "DD-MM-YYYY") - dayjs(fromDate, "DD-MM-YYYY"));
      const diffDays = Math.ceil(dateDiff / (1000 * 60 * 60 * 24));

      if (diffDays > 31) {
        showNotification({
          msg: "Select date between one month",
          severity: "error",
        });
        return;
      }
    }


    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      const rowList = billingHistoryList.billingHistory.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      console.log(selection);
      this.rowPDF({
        id: selection.join(),
      });
    }
  };
  onPDFBill = () => {
    const { showNotification, billingHistoryList } = this.props;
    const { selection, fromDate, toDate } = this.state;

    if (fromDate && toDate) {
      const dateDiff = Math.abs(dayjs(toDate, "DD-MM-YYYY") - dayjs(fromDate, "DD-MM-YYYY"));
      const diffDays = Math.ceil(dateDiff / (1000 * 60 * 60 * 24));

      if (diffDays > 31) {
        showNotification({
          msg: "Select date between one month",
          severity: "error",
        });
        return;
      }
    }


    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      const rowList = billingHistoryList.billingHistory.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      console.log(selection);
      this.rowPDFBill({
        id: selection.join(),
      });
    }
  };


  onPaymentSummary = () => {
    const { showNotification, billingHistoryList } = this.props;
    const { selection, fromDate, toDate } = this.state;


    if (fromDate && toDate) {
      const dateDiff = Math.abs(dayjs(toDate, "DD-MM-YYYY") - dayjs(fromDate, "DD-MM-YYYY"));
      const diffDays = Math.ceil(dateDiff / (1000 * 60 * 60 * 24));

      if (diffDays > 31) {
        showNotification({
          msg: "Select date between one month",
          severity: "error",
        });
        return;
      }
    }

    if (selection.length === 0) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      const rowList = billingHistoryList.billingHistory.map((rowData) => {
        const filterData = selection.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      });
      console.log(selection);
      this.rowPDF1({
        id: selection.join(),
      });
    }
  };
  rowView = (rowData) => {
    this.props.navigate(
      BillingHistoryTableJson.formPathView + "/" + rowData.id
    );
  };
  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        lang: newValue,
        langNew: newValue,
      });
    } else {
      this.setState({
        lang: "",
        langNew: "",
      });
    }
  };
  onClickApply = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.outletBillHeader +
          "/generated-invoice?outletBillHeaderId=" +
          rowData.id,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          let newList = [];
          newList.push(response.data.data);
          const jsonString = JSON.stringify(newList, null, 2);
          const blob = new Blob([jsonString], { type: "application/json" });
          const url = URL.createObjectURL(blob);

          // Create an anchor element and trigger a click event to download the file
          const a = document.createElement("a");
          a.href = url;
          a.download = "data.json";
          a.click();

          // Clean up the URL.createObjectURL
          URL.revokeObjectURL(url);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  handleCloses = () => {
    this.setState({
      editFlag: false,
    });
  };
  onClickAdd = (row) => {
    console.log(row);
    this.setState({
      editFlag: true,
      editData: row.id,
    });
  };

  render() {
    const {
      billingHistoryList,
      multilingualList,
      getTodaysBillingHistoryUpdated,
    } = this.props;
    const {
      dynamicMasterData,
      lang,
      langNew,
      selection,
      editData,
      editFlag,
      formData,
      searchBy,
      fieldDataForModule,
    } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicMainScreen
            showViewButtonInTable={true}
            showDeleteIcon={true}
            dynamicMasterData={dynamicMasterData}
            searchList={
              searchBy == 0
                ? BillingHistoryTableJson.searchList
                : BillingHistoryTableJson.searchListBySalsPerson
            }
            onSearchData={this.onSearchList}
            formPathView={BillingHistoryTableJson.formPathView}
            formPath={BillingHistoryTableJson.formPath}
            screenTitle={BillingHistoryTableJson.screenTitle}
            fieldMeta={BillingHistoryTableJson.fieldMeta}
            getListById={this.getListById}
            showPdfDownload={false}
            showExcelDownload={false}
            formData={formData}
            isActiveURL={BillingHistoryTableJson.isActiveURL}
            showAddButton={false}
            tableColumnsToFilter={BillingHistoryTableJson.tableColumnsToFilter}
            tableColumns={[]}
            tableData={billingHistoryList?.billingHistory}
            getTableData={getTodaysBillingHistoryUpdated}
            apiBaseURL={BillingHistoryTableJson.apiBaseURL}
            callApi={false}
            getTableDataById={true}
            showSearchBox={false}
            rowPDF={this.rowPDF}
            showPdf={true}
            deleteURL={BillingHistoryTableJson.deleteURL}
            deleteURLStatic={true}
            showPegination={false}
            onDeleteStatic={this.onDeleteStatic}
            showSearch={false}
            deleteConditionName="status"
            deleteConditionValue="0"
            editConditionName="status"
            editConditionValue="0"
            showEditIcon={false}
          />
          <CheckBoxSelectionTable
            selection={selection}
            onSelectionChange={this.getSelectedList}
            columns={billColumns}
            rows={(billColumns, billingHistoryList?.billingHistory)}
            isActionColActive={true}
            rowPrint={this.rowPDF}
            SummarizeRoundedShow={true}
            showViewButtonInTable={true}
            rowView={this.rowView}
            showDelete={false}
            rowDelete={this.onDeleteStatic}
            showSearchBox={true}
            showApply={true}
            showApplyTitle={"Export Bill"}
            rowApply={this.onClickApply}
            showAdd={true}
            showAddTitle={"Import Bill"}
            rowAdd={this.onClickAdd}
          />
          {editFlag && (
            <>
              <BillingHistoryPopup
                open={editFlag}
                handleCloses={this.handleCloses}
                onSave={this.saveHandler}
                editData={editData}
              />
            </>
          )}
          <br />
          {fieldDataForModule.view == 1 ? (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="right"
            >
              <Grid container xs={12} sm={3} md={3} lg={3}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label={"*"}
                  />
                  <LabelCompo
                    className="text-black"
                    style={{
                      fontSize: 16,
                      color: titleColor,
                    }}
                    label={"Language" + " :"}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <AutoComplete
                    name={"Language"}
                    label={"Language"}
                    placeHolder={"Select Language"}
                    keyColName={"id"}
                    value={lang}
                    options={multilingualList.language}
                    onChange={this.onAutocompleteChange}
                    errorText={""}
                  />
                </Grid>
              </Grid>
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Payment Summary"
                onClick={this.onPaymentSummary}
              />
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Bill PDF"
                onClick={this.onPDFBill}
              />
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name={"Bill Dispatch Summary"}
                onClick={this.onLoadSheet}
              />
            </Grid>
          ) : (
            ""
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  billingHistoryList: state.billingHistory,
  multilingualList: state.multilingual,
  salesExecutive: state.salesExecutive,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAreaByUserType,
  getOutletByBeatIdsPost,
  getBeatByUserType,
  getOutletByUserType,
  getBillingHistoryByOutletAndDate,
  getActiveLanguage,
  getTodaysBillingHistoryUpdated,
  getSalesExecutiveByBeatIds,
  getBillingHistoryBySalesPersonUpdated,
  getSalesExecutiveByLoginAreas,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingHistoryTable);

import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ProductRateStructureJson } from "../../DynamicFormsJson/Transaction/productRateStructure";
import { getAllActiveProductForAssignation } from "../../Slice/product.slice";
import { withRouter } from "../../components/withRouter";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  updateData,
  updateMsg,
} from "../../config/messageconstant";

import dayjs from "dayjs";
import swal from "sweetalert";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { mandatoryCheck } from "../../config/ValidationCheck";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import "./product.css";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { Pages } from "@mui/icons-material";
import MultipleStructurePopUp from "./MultipleStructurePopUp";
import { redColor } from "../../config/ColorObj";

class ProductRateStructureForApplyingMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixDataList: [],
      fieldData: {},
      editData: "",
      formErrors: {},
      searchValueForProductRateStructure: "",
      allChecked: false,
      selection: [],
      matrixDataForForward: "",
      multipleStructurePopUpFlag: false,
    };
  }

  async componentDidMount() {
    const {
      getAllActiveProductForAssignation,
      getStockStakeHolderType,
      stakeHolderTypeList,
      showLoador,
      showNotification,
      productList,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAllActiveProductForAssignation().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showLoador({ loador: true });
          getStockStakeHolderType({ userTypeIds: 2, reverseTrue: true }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                this.matrixData();
              }
            }
          );
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  matrixData = () => {
    const { productList, stakeHolderTypeList } = this.props;
    let newDataToSet = productList?.activeAllProduct.map((productObject) => {
      let matrixObject = {
        productId: productObject.id,
        brandName: productObject.group.brand.name,
        productName: productObject.name,
        categtoryName: productObject.group.subCategory.category.name,
        mrp: 0,
        outletRate: 0,
        productObject: productObject,
        isChecked: false,
      };
      stakeHolderTypeList?.stockStakeHolderType.map((stakeholderObejct) => {
        matrixObject = {
          ...matrixObject,
          [stakeholderObejct.name.replaceAll(" ", "").toLowerCase()]: 0,
          [stakeholderObejct.name.replaceAll(" ", "").toLowerCase() + "Id"]:
            stakeholderObejct.id,
        };
        return matrixObject;
      });
      return matrixObject;
    });

    this.setState({
      matrixDataList: newDataToSet,
    });
  };


  onSubmitHandler = () => {
    const { matrixDataList, selection } = this.state;
    const { stakeHolderTypeList, showNotification } = this.props;


    if (selection.length > 0) {

      let isError = false;
      let newData = [];

      const matData = matrixDataList
        .filter((row) => row.isChecked)
        .map((matrixObject) => {

          if (matrixObject.mrpError) {
            isError = true;
          }

          const dataToSave = {
            mrp: matrixObject.mrp === "" ? 0 : matrixObject.mrp,
            product: matrixObject.productObject,
            outletRate: matrixObject.outletRate === "" ? 0 : matrixObject.outletRate,
            dtoTypeWiseRates: stakeHolderTypeList?.stockStakeHolderType.map(
              (stakeholderObject) => ({
                rate: matrixObject[stakeholderObject.name.toLowerCase()] === "" ? 0 : matrixObject[stakeholderObject.name.toLowerCase()],
                stockStakeHolderType: {
                  id: stakeholderObject.id,
                },
              })
            ),
          };

          newData.push(dataToSave);

        });

      this.setState({ matrixDataListForSave: matData });



      if (newData.length > 0) {

        const jsonToSave = {
          productMrpHolderWiseRates: newData,
        };

        console.log("matrixDataListForSave to Save:", jsonToSave);
        this.setState({ matrixDataForForward: jsonToSave, multipleStructurePopUpFlag: true });

      } else {
        showNotification({
          msg: "Add at least one record",
          severity: "error",
        });
      }

    } else {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    }
  };


  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;

    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    ProductRateStructureJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");

        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  onSearchProductRateStructure = (searchValueForProductRateStructure) => {
    this.setState({
      searchValueForProductRateStructure: searchValueForProductRateStructure,
    });
  };




  getFilteredTableData = (searchData) => {
    const { searchValueForProductRateStructure } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow["brandName"] &&
        currentRow["brandName"]
          .toString()
          .toLowerCase()
          .includes(searchValueForProductRateStructure.trim().toLowerCase()) ||
        currentRow["productName"] &&
        currentRow["productName"]
          .toString()
          .toLowerCase()
          .includes(searchValueForProductRateStructure.trim().toLowerCase()) ||
        currentRow["categtoryName"] &&
        currentRow["categtoryName"]
          .toString()
          .toLowerCase()
          .includes(searchValueForProductRateStructure.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
    });
  };

  onChange = (e, productObject) => {
    const { matrixDataList } = this.state;
    const { name, value } = e.target;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      let newMatrixData = matrixDataList.map((matrixObject) => {
        if (matrixObject.productId == productObject.productId) {
          let newData = {
            ...matrixObject,
            [name]: value,
          };
          return newData;
        } else {
          return matrixObject;
        }
      });
      this.setState({
        matrixDataList: newMatrixData,
      });
    }
  };


  handleAllCheckedClick = (e) => {
    const { matrixDataList } = this.state;
    const isChecked = e.target.checked;

    const updatedDataList = matrixDataList.map((dataObj) => ({
      ...dataObj,
      isChecked: isChecked,
    }));

    const updatedSelection = isChecked
      ? matrixDataList.map((dataObj) => dataObj.productId)
      : [];

    this.setState({
      matrixDataList: updatedDataList,
      selection: updatedSelection,
      allChecked: isChecked,
    });

  };




  handleClick = (e, productId) => {
    const { matrixDataList, selection } = this.state;
    const isChecked = e.target.checked;

    const updatedDataList = matrixDataList.map((dataObj) => {
      if (dataObj.productId === productId) {
        return { ...dataObj, isChecked: isChecked };
      }
      return dataObj;
    });

    const updatedSelection = isChecked
      ? [...selection, productId]
      : selection.filter((id) => id !== productId);

    this.setState({
      matrixDataList: updatedDataList,
      selection: updatedSelection,
    });

    console.log(updatedSelection);
  };

  toggle = () => {
    this.setState({ multipleStructurePopUpFlag: false })
  }


  render() {
    const { formErrors, multipleStructurePopUpFlag, matrixDataList, fieldData, allChecked, matrixDataForForward } = this.state;
    const { productList, stakeHolderTypeList, showLoador, showNotification } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >



          <DynamicFormWithoutValidation
            showBackToList={true}
            showTitle={ProductRateStructureJson.showTitle}
            screenTitle={"Multiple Product Rate Structure Configuration"}
            apiBaseURL={ProductRateStructureJson.apiBaseURL}
            showCancel={ProductRateStructureJson.showCancel}
            showSaveNextBtn={ProductRateStructureJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            showSaveBtn={false}
            fieldData={fieldData}
            formErrors={formErrors}
            onDataChange={this.onDataChange}
          />

          <br />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearchProductRateStructure}
          />

          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="error"
                      onClick={(event) => this.handleAllCheckedClick(event)}
                      checked={allChecked}
                    />
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "70px" }}>
                    Sr. No.
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Brand
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Product Name
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Category
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    MRP Rate
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "150px" }}>
                    Outlet Rate
                  </TableCell>
                  {stakeHolderTypeList?.stockStakeHolderType.map(
                    (stakeHolderObject) => {
                      return (
                        <TableCell align="center" style={{ minWidth: "150px" }}>
                          {stakeHolderObject.name}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.getFilteredTableData(matrixDataList).map(
                  (productObject, indexRow) => {
                    return (
                      <TableRow key={productObject.productId}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onClick={(event) => this.handleClick(event, productObject.productId)}
                            checked={productObject.isChecked}
                          />
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {indexRow + 1}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {productObject.brandName}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {productObject.productName}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {productObject.categtoryName}
                        </TableCell>
                        <TableCell align="right">
                          <input
                            className={"customTextfield"}
                            min="0"
                            step=".01"
                            placeHolder={"MRP Rate"}
                            value={productObject.mrp}
                            name="mrp"
                            onChange={(e) => this.onChange(e, productObject)}
                            type="number"
                            id="mrp"
                          ></input>
                          <span style={{ color: redColor, fontSize: "12px" }}>
                            {productObject.mrp === "" || productObject.mrp == 0 && productObject.mrpError
                              ? "Rate Required "
                              : ""}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <input
                            className={"customTextfield"}
                            placeHolder={"Outlet Rate"}
                            value={productObject.outletRate}
                            name="outletRate"
                            onChange={(e) => this.onChange(e, productObject)}
                            type="text"
                            id="outletRate"
                          ></input>

                          <span style={{ color: redColor, fontSize: "12px" }}>
                            {productObject.outletRate === "" || productObject.stockist == 0 &&
                              productObject.outletRateError
                              ? "Rate Required "
                              : productObject.outletRateError
                                ? "Rate Should be small than MRP "
                                : ""}
                          </span>
                        </TableCell>
                        {stakeHolderTypeList?.stockStakeHolderType.map(
                          (stakeHolderObject, index) => {
                            return (
                              <TableCell align="right">
                                <input
                                  className={"customTextfield"}
                                  placeHolder={stakeHolderObject.name + " Rate"}
                                  value={
                                    productObject[
                                    stakeHolderObject.name.toLowerCase()
                                    ]
                                  }
                                  name={stakeHolderObject.name.toLowerCase()}
                                  onChange={(e) =>
                                    this.onChange(e, productObject)
                                  }
                                  type="text"
                                  id={stakeHolderObject.name.toLowerCase()}
                                ></input>

                                <span style={{ color: redColor, fontSize: "12px" }}>
                                  {
                                    productObject[
                                    stakeHolderObject.name.toLowerCase()
                                    ] === "" && "Rate Required"
                                  }
                                </span>
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitHandler}
            />
          </Grid>

          {multipleStructurePopUpFlag && (
            <MultipleStructurePopUp
              open={multipleStructurePopUpFlag}
              rows={matrixDataForForward}
              handleClose={this.toggle}
              showLoador={showLoador}
              showNotification={showNotification}
            />
          )}


        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  multipleStockList: state.manageStockByDate,
  stakeHolderTypeList: state.stockStakeHolderType,
  productList: state.product,
});
const mapDispatchToProps = {
  getAllActiveProductForAssignation,
  getStockStakeHolderType,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductRateStructureForApplyingMultiple);

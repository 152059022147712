import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  stackholder: [],
  orderTo: [],
  documentForStakeHolder: [],
  stackholderName: [],
  stackholderNameFromType: [],
  area: [],
  orderToAdmin: "",
  stockStakeHolders: [],
  stockholderByArea: [],
  areaByBeatTaluka: [],
  areaByDistrictPost: [],
};
let URL = endpoints.stackholder;
let URL1 = endpoints.stackholderDocument;
let URL2 = endpoints.document;
const stakeHolderSlice = createSlice({
  name: "stackholder",
  initialState,
  reducers: {
    stakeHolderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stackholder: row,
      };
    },

    areaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        area: row,
      };
    },
    areaByDistrictPostSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        areaByDistrictPost: row,
      };
    },
    areaByBeatTalukaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        areaByBeatTaluka: row,
      };
    },
    orderToSuccess: (state = cloneDeep(initialState), action) => {
      const { row, isAdmin } = action.payload;
      return {
        ...state,
        orderTo: row,
        orderToAdmin: isAdmin,
      };
    },

    documentForStakeHolderSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        documentForStakeHolder: row,
      };
    },
    stakeHolderNameSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stackholderName: row,
      };
    },
    stakeHolderNameFromTypeSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stackholderNameFromType: row,
      };
    },
    stockStakeHoldersSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stockStakeHolders: row,
      };
    },
    stockStakeHoldersByAreaSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stockholderByArea: row,
      };
    },
  },
});

export const {
  stakeHolderSuccess,
  resetState,
  orderToSuccess,
  documentForStakeHolderSuccess,
  stakeHolderNameSuccess,
  stakeHolderNameFromTypeSuccess,
  areaSuccess,
  areaByBeatTalukaSuccess,
  stockStakeHoldersSuccess,
  stockStakeHoldersByAreaSuccess,
  stockHolderStockIdsAndAreaIdsSuccess,
  areaByDistrictPostSuccess,
} = stakeHolderSlice.actions;

export default stakeHolderSlice.reducer;

export const getStackHolder = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stakeholderData, index) => {
          let stakeholderObject = {
            index: index + 1,
            id: stakeholderData.id === null ? "" : stakeholderData.id,
            userName:
              stakeholderData.userName === null ? "" : stakeholderData.userName,
            firmName:
              stakeholderData.firmName === null ? "" : stakeholderData.firmName,
            ownerName:
              stakeholderData.ownerName === null
                ? ""
                : stakeholderData.ownerName,
            orderTo:
              //stakeholderData.orderTo === null ? "" : stakeholderData.orderTo,
              stakeholderData.orderTo == null
                ? ""
                : stakeholderData.orderTo == 0
                  ? "Admin"
                  : "-",
            state:
              stakeholderData.state !== null && stakeholderData.state.id
                ? stakeholderData.state.id
                : "",
            stateName:
              stakeholderData.state !== null && stakeholderData.state.name
                ? stakeholderData.state.name
                : "",
            areaNames:
              stakeholderData.areaNames === null
                ? ""
                : stakeholderData.areaNames,
            shCode:
              stakeholderData.shCode === null ? "" : stakeholderData.shCode,
            contactPersonName:
              stakeholderData.contactPersonName === null
                ? ""
                : stakeholderData.contactPersonName,
            contactPersonNo:
              stakeholderData.contactPersonNo === null
                ? ""
                : stakeholderData.contactPersonNo,
            emailId:
              stakeholderData.emailId === null ? "" : stakeholderData.emailId,
            stockStakeHolderTypeName:
              stakeholderData.stockStakeHolderType !== null &&
                stakeholderData.stockStakeHolderType.name !== null
                ? stakeholderData.stockStakeHolderType.name
                : "",

            stockStakeHolderType:
              stakeholderData.stockStakeHolderType !== null &&
                stakeholderData.stockStakeHolderType.id
                ? stakeholderData.stockStakeHolderType.id
                : "",

            isActive: stakeholderData.isActive,

            address:
              stakeholderData.address === null ? "" : stakeholderData.address,

            region:
              stakeholderData.region !== null && stakeholderData.region.id
                ? stakeholderData.region.id
                : "",
            regionName:
              stakeholderData.region !== null && stakeholderData.region.name
                ? stakeholderData.region.name
                : "",

            zoneIds:
              stakeholderData.zoneIds === null ? "" : stakeholderData.zoneIds,

            areaIds:
              stakeholderData.areaIds === null ? "" : stakeholderData.areaIds,
          };
          return stakeholderObject;
        });
        dispatch(stakeHolderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getUpdatedStackHolder = () => async (dispatch) => {
  try {
    const response = apiGet({
      // http://localhost:8088/api/distribution/v1/stock-stake-holder/find-all
      url: URL + '/find-all?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stakeholderData, index) => {
          let stakeholderObject = {
            index: index + 1,
            id: stakeholderData.id === null ? "" : stakeholderData.id,
            userName:
              stakeholderData.userName === null ? "" : stakeholderData.userName,
            firmName:
              stakeholderData.firmName === null ? "" : stakeholderData.firmName,
            ownerName:
              stakeholderData.ownerName === null
                ? ""
                : stakeholderData.ownerName,
            orderTo:
              //stakeholderData.orderTo === null ? "" : stakeholderData.orderTo,
              stakeholderData.orderTo == null
                ? ""
                : stakeholderData.orderTo == 0
                  ? "Admin"
                  : "-",
            orderToName:
              stakeholderData.orderToName !== null
                ? stakeholderData.orderToName
                : "",
            state:
              stakeholderData.stateId !== null
                ? stakeholderData.stateId
                : "",
            stateName:
              stakeholderData.stateName !== null
                ? stakeholderData.stateName
                : "",
            areaNames:
              stakeholderData.areaNames && stakeholderData.areaNames === null
                ? ""
                : stakeholderData.areaNames,
            shCode:
              stakeholderData.shCode === null ? "" : stakeholderData.shCode,
            contactPersonName:
              stakeholderData.contactPersonName === null
                ? ""
                : stakeholderData.contactPersonName,
            contactPersonNo:
              stakeholderData.contactPersonNo === null
                ? ""
                : stakeholderData.contactPersonNo,
            emailId:
              stakeholderData.emailId === null ? "" : stakeholderData.emailId,
            stockStakeHolderTypeName:
              stakeholderData.stockStakeHolderTypeName !== null

                ? stakeholderData.stockStakeHolderTypeName
                : "",

            stockStakeHolderType:
              stakeholderData.stockStakeHolderTypeId !== null

                ? stakeholderData.stockStakeHolderTypeId
                : "",

            isActive: stakeholderData.isActive,

            address:
              stakeholderData.address === null ? "" : stakeholderData.address,

            region:
              stakeholderData.regionId !== null
                ? stakeholderData.regionId
                : "",
            regionName:
              stakeholderData.regionName !== null
                ? stakeholderData.regionName
                : "",

            zoneIds:
              stakeholderData.zoneIds === null ? "" : stakeholderData.zoneIds,

            areaIds:
              stakeholderData.areaIds === null ? "" : stakeholderData.areaIds,
          };
          return stakeholderObject;
        });
        dispatch(stakeHolderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOrderToList =
  ({ StockStakeHolderTypeId, areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/order-to-list?StockStakeHolderTypeId=" +
            StockStakeHolderTypeId +
            "&areaIds=" +
            areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.stockStakeHolders.map((orderToData, index) => {
              let orderToObject = {
                index: index + 1,
                id: orderToData.id === null ? "" : orderToData.id,
                userId: orderToData.userId === null ? "" : orderToData.userId,
                userName:
                  orderToData.userName === null ? "" : orderToData.userName,
                name: orderToData.ownerName === null ? "" : orderToData.ownerName,
                stockStakeHolderTypeId:
                  orderToData.stockStakeHolderType === null
                    ? ""
                    : orderToData.stockStakeHolderType.id,
                stockStakeHolderTypeName:
                  orderToData.stockStakeHolderType === null
                    ? ""
                    : orderToData.stockStakeHolderType.name,
                userType:
                  orderToData.stockStakeHolderType === null
                    ? ""
                    : orderToData.stockStakeHolderType.userType.name,
                ownerName:
                  orderToData.ownerName === null ? "" : orderToData.ownerName,
                firmName:
                  orderToData.firmName === null ? "" : orderToData.firmName,
                contactPersonName:
                  orderToData.contactPersonName === null
                    ? ""
                    : orderToData.contactPersonName,
                contactPersonNo:
                  orderToData.contactPersonNo === null
                    ? ""
                    : orderToData.contactPersonNo,

                orderTo: orderToData.orderTo === null ? "" : orderToData.orderTo,
                region: orderToData.region === null ? "" : orderToData.region,
                stockStakeHolderType:
                  orderToData.stockStakeHolderType === null
                    ? ""
                    : orderToData.stockStakeHolderType,
              };
              return orderToObject;
            });
            dispatch(orderToSuccess({ row, isAdmin: data.data.flag }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOrderToListPost =
  ({ jsonData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url:
            URL + "/order-to-list",
          postBody: jsonData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.stockStakeHolders.map((orderToData, index) => {
              let orderToObject = {
                index: index + 1,
                id: orderToData.id === null ? "" : orderToData.id,
                userId: orderToData.userId === null ? "" : orderToData.userId,
                userName:
                  orderToData.userName === null ? "" : orderToData.userName,
                name: orderToData.firmName === null ? "" : orderToData.firmName,
                stockStakeHolderTypeId:
                  orderToData.stockStakeHolderType === null
                    ? ""
                    : orderToData.stockStakeHolderType.id,
                stockStakeHolderTypeName:
                  orderToData.stockStakeHolderType === null
                    ? ""
                    : orderToData.stockStakeHolderType.name,
                userType:
                  orderToData.stockStakeHolderType === null
                    ? ""
                    : orderToData.stockStakeHolderType.userType.name,
                ownerName:
                  orderToData.ownerName === null ? "" : orderToData.ownerName,
                firmName:
                  orderToData.firmName === null ? "" : orderToData.firmName,
                contactPersonName:
                  orderToData.contactPersonName === null
                    ? ""
                    : orderToData.contactPersonName,
                contactPersonNo:
                  orderToData.contactPersonNo === null
                    ? ""
                    : orderToData.contactPersonNo,

                orderTo: orderToData.orderTo === null ? "" : orderToData.orderTo,
                region: orderToData.region === null ? "" : orderToData.region,
                stockStakeHolderType:
                  orderToData.stockStakeHolderType === null
                    ? ""
                    : orderToData.stockStakeHolderType,
              };
              return orderToObject;
            });
            dispatch(orderToSuccess({ row, isAdmin: data.data.flag }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const setStakeDocList =
  ({ row }) =>
    async (dispatch) => {
      dispatch(documentForStakeHolderSuccess({ row }));
    };

export const getDocumentForStakeHolder =
  ({ docList, type }) =>
    async (dispatch) => {
      try {
        console.log(docList);
        const response = apiGet({
          url: URL2 + "/by-type?type=" + type,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeholderData, index) => {
              let stakeholderObject = {
                index: index + 1,
                id: stakeholderData.id === null ? "" : stakeholderData.id,
                documentName:
                  stakeholderData.docName === null ? "" : stakeholderData.docName,
                type: stakeholderData.type === null ? "" : stakeholderData.type,
                imagePath: "",
                imagePathToSave:
                  stakeholderData.fileName === null
                    ? ""
                    : stakeholderData.fileName,
                insertDateTime:
                  stakeholderData.insertDateTime === null
                    ? ""
                    : stakeholderData.insertDateTime,
              };
              return stakeholderObject;
            });
            dispatch(documentForStakeHolderSuccess({ row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return { response: [], success: false };
      }
    };
export const getStakeHolderName =
  ({ StockStakeHolderTypeIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-stock-stake-holder-types?StockStakeHolderTypeIds=" +
            StockStakeHolderTypeIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeHolderTypeObj) => {
              const stakeHolderTypeData = {
                ...stakeHolderTypeObj,
                name:
                  stakeHolderTypeObj.ownerName !== null &&
                    stakeHolderTypeObj.ownerName
                    ? stakeHolderTypeObj.ownerName
                    : "",
              };
              return stakeHolderTypeData;
            });
            dispatch(stakeHolderNameSuccess({ row: row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getAreaByStockStakeHolderTypeIdAndZoneIds =
  ({ StockStakeHolderTypeId, zoneIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-areas?StockStakeHolderTypeId=" +
            StockStakeHolderTypeId +
            "&zoneIds=" +
            zoneIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((areaObject, index) => {
              let areaData = {
                index: index + 1,
                id: areaObject.id === null ? "" : areaObject.id,
                name:
                  areaObject.name === null
                    ? ""
                    : areaObject.name + " (" + areaObject.shortName + ")",
                zoneName:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district.zone.name
                    ? areaObject.taluka.district.zone.name
                    : "",
                zone:
                  areaObject.taluka !== null && areaObject.taluka.district.zone.id
                    ? areaObject.taluka.district.zone.id
                    : "",
                shortName:
                  areaObject.shortName === null ? "" : areaObject.shortName,
                sortOrder:
                  areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              };
              return areaData;
            });
            dispatch(areaSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getAreaByStockStakeHolderTypeZoneAndTaluka =
  ({ StockStakeHolderTypeId, zoneIds, talukaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-areas-by-zones-talukas?StockStakeHolderTypeId=" +
            StockStakeHolderTypeId +
            "&zoneIds=" +
            zoneIds +
            "&talukaIds=" +
            talukaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((areaObject, index) => {
              let areaData = {
                index: index + 1,
                id: areaObject.id === null ? "" : areaObject.id,
                name:
                  areaObject.name === null
                    ? ""
                    : areaObject.name + " (" + areaObject.shortName + ")",
                zoneName:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district.zone.name
                    ? areaObject.taluka.district.zone.name
                    : "",
                zone:
                  areaObject.taluka !== null && areaObject.taluka.district.zone.id
                    ? areaObject.taluka.district.zone.id
                    : "",
                shortName:
                  areaObject.shortName === null ? "" : areaObject.shortName,
                sortOrder:
                  areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              };
              return areaData;
            });
            dispatch(areaByBeatTalukaSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getAreaByStockStakeHolderTypeIdAndZoneIdsEdit =
  ({ saleExcId, StockStakeHolderTypeId, zoneIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/" +
            saleExcId +
            "/get-areas-on-edit?StockStakeHolderTypeId=" +
            StockStakeHolderTypeId +
            "&zoneIds=" +
            zoneIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((areaObject, index) => {
              let areaData = {
                index: index + 1,
                id: areaObject.id === null ? "" : areaObject.id,
                name:
                  areaObject.name === null
                    ? ""
                    : areaObject.name + " (" + areaObject.shortName + ")",
                taluka: areaObject.taluka === null ? "" : areaObject.taluka,
                zone:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district !== null &&
                    areaObject.taluka.district.zone !== null &&
                    areaObject.taluka.district.zone.id
                    ? areaObject.taluka.district.zone.id
                    : "",
                zoneName:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district !== null &&
                    areaObject.taluka.district.zone !== null &&
                    areaObject.taluka.district.zone.name
                    ? areaObject.taluka.district.zone.name
                    : "",

                shortName:
                  areaObject.shortName === null ? "" : areaObject.shortName,
                sortOrder:
                  areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              };
              return areaData;
            });
            dispatch(areaSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getStockStackHolders = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/holders",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stakeholdersData, index) => {
          let stakeholdersObject = {
            index: index + 1,
            id: stakeholdersData.id === null ? "" : stakeholdersData.id,
            userId:
              stakeholdersData.userId === null ? "" : stakeholdersData.userId,
            userName:
              stakeholdersData.userName === null
                ? ""
                : stakeholdersData.userName,
            stockStakeHolderType:
              stakeholdersData.stockStakeHolderType === null
                ? ""
                : stakeholdersData.stockStakeHolderType,
            name:
              stakeholdersData.ownerName === null &&
                stakeholdersData.firmName === null
                ? ""
                : stakeholdersData.firmName +
                " - " +
                stakeholdersData.ownerName,
            ownerName:
              stakeholdersData.ownerName === null
                ? ""
                : stakeholdersData.ownerName,
            firmName:
              stakeholdersData.firmName === null
                ? ""
                : stakeholdersData.firmName,
            contactPersonName:
              stakeholdersData.contactPersonName === null
                ? ""
                : stakeholdersData.contactPersonName,
            contactPersonNo:
              stakeholdersData.contactPersonNo === null
                ? ""
                : stakeholdersData.contactPersonNo,
            address:
              stakeholdersData.address === null ? "" : stakeholdersData.address,
            state:
              stakeholdersData.state === null
                ? ""
                : stakeholdersData.state.name,
            stateCode:
              stakeholdersData.state === null
                ? ""
                : stakeholdersData.state.code,
            emailId:
              stakeholdersData.emailId === null ? "" : stakeholdersData.emailId,
            gstNo:
              stakeholdersData.gstNo === null ? "" : stakeholdersData.gstNo,

            panNo:
              stakeholdersData.panNo === null ? "" : stakeholdersData.panNo,
            orderTo:
              stakeholdersData.orderTo === null ? "" : stakeholdersData.orderTo,
            region:
              stakeholdersData.region === null ? "" : stakeholdersData.region,
            zoneIds:
              stakeholdersData.zoneIds === null ? "" : stakeholdersData.zoneIds,
            areaIds:
              stakeholdersData.areaIds === null ? "" : stakeholdersData.areaIds,
            accessRoleId:
              stakeholdersData.accessRoleId === null
                ? ""
                : stakeholdersData.accessRoleId,
            stockStakeHolder:
              stakeholdersData.stockStakeHolder === null
                ? ""
                : stakeholdersData.stockStakeHolder,

            isActive: stakeholdersData.isActive,
          };
          return stakeholdersObject;
        });
        dispatch(stockStakeHoldersSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStockStakeHolderOrderTo = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-to-order",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stakeholderData, index) => {
          let stakeholderObject = {
            index: index + 1,
            id: stakeholderData.id === null ? "" : stakeholderData.id,
            userName:
              stakeholderData.userName === null ? "" : stakeholderData.userName,
            firmName:
              stakeholderData.firmName === null ? "" : stakeholderData.firmName,
            ownerName:
              stakeholderData.ownerName === null
                ? ""
                : stakeholderData.ownerName,
            contactPersonName:
              stakeholderData.contactPersonName === null
                ? ""
                : stakeholderData.contactPersonName,
            contactPersonNo:
              stakeholderData.contactPersonNo === null
                ? ""
                : stakeholderData.contactPersonNo,
            address:
              stakeholderData.address === null ? "" : stakeholderData.address,
            emailId:
              stakeholderData.emailId === null ? "" : stakeholderData.emailId,
            stockStakeHolderTypeName:
              stakeholderData.stockStakeHolderType !== null &&
                stakeholderData.stockStakeHolderType.name !== null
                ? stakeholderData.stockStakeHolderType.name
                : "",

            stockStakeHolderType:
              stakeholderData.stockStakeHolderType !== null &&
                stakeholderData.stockStakeHolderType.id
                ? stakeholderData.stockStakeHolderType.id
                : "",
            orderTo:
              stakeholderData.orderTo === null ? "" : stakeholderData.orderTo,
            region:
              stakeholderData.region !== null && stakeholderData.region.id
                ? stakeholderData.region.id
                : "",
            regionName:
              stakeholderData.region !== null && stakeholderData.region.name
                ? stakeholderData.region.name
                : "",
            zoneIds:
              stakeholderData.zoneIds === null ? "" : stakeholderData.zoneIds,

            areaIds:
              stakeholderData.areaIds === null ? "" : stakeholderData.areaIds,

            isActive: stakeholderData.isActive,
          };
          return stakeholderObject;
        });
        dispatch(stakeHolderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStockholderByUser = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-areas",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stakeholderByUserData, index) => {
          let stakeholderByUserAreaObject = {
            index: index + 1,
            id:
              stakeholderByUserData.id === null ? "" : stakeholderByUserData.id,
            userName:
              stakeholderByUserData.userName === null
                ? ""
                : stakeholderByUserData.userName,
            firmName:
              stakeholderByUserData.firmName === null
                ? ""
                : stakeholderByUserData.firmName,
            ownerName:
              stakeholderByUserData.ownerName === null
                ? ""
                : stakeholderByUserData.ownerName,
            name:
              stakeholderByUserData.ownerName === null &&
                stakeholderByUserData.firmName === null
                ? ""
                : stakeholderByUserData.firmName +
                " - " +
                stakeholderByUserData.ownerName,
            orderToOwnerName:
              stakeholderByUserData.orderToOwnerName === null
                ? ""
                : stakeholderByUserData.orderToOwnerName,
            state:
              stakeholderByUserData.state !== null &&
                stakeholderByUserData.state.id
                ? stakeholderByUserData.state.id
                : "",
            stateName:
              stakeholderByUserData.state !== null &&
                stakeholderByUserData.state.name
                ? stakeholderByUserData.state.name
                : "",
            areaNames:
              stakeholderByUserData.areaNames === null
                ? ""
                : stakeholderByUserData.areaNames,
            contactPersonName:
              stakeholderByUserData.contactPersonName === null
                ? ""
                : stakeholderByUserData.contactPersonName,
            contactPersonNo:
              stakeholderByUserData.contactPersonNo === null
                ? ""
                : stakeholderByUserData.contactPersonNo,
            address:
              stakeholderByUserData.address === null
                ? ""
                : stakeholderByUserData.address,
            emailId:
              stakeholderByUserData.emailId === null
                ? ""
                : stakeholderByUserData.emailId,
            stockStakeHolderTypeName:
              stakeholderByUserData.stockStakeHolderType !== null &&
                stakeholderByUserData.stockStakeHolderType.name !== null
                ? stakeholderByUserData.stockStakeHolderType.name
                : "",

            stockStakeHolderType:
              stakeholderByUserData.stockStakeHolderType !== null &&
                stakeholderByUserData.stockStakeHolderType.id
                ? stakeholderByUserData.stockStakeHolderType.id
                : "",

            region:
              stakeholderByUserData.region !== null &&
                stakeholderByUserData.region.id
                ? stakeholderByUserData.region.id
                : "",
            regionName:
              stakeholderByUserData.region !== null &&
                stakeholderByUserData.region.name
                ? stakeholderByUserData.region.name
                : "",

            zoneIds:
              stakeholderByUserData.zoneIds === null
                ? ""
                : stakeholderByUserData.zoneIds,

            areaIds:
              stakeholderByUserData.areaIds === null
                ? ""
                : stakeholderByUserData.areaIds,

            isActive: stakeholderByUserData.isActive,
          };
          return stakeholderByUserAreaObject;
        });
        dispatch(stockStakeHoldersSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};



export const getStockholderByUserRegion = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-regions",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stakeholderByUserData, index) => {
          let stakeholderByUserAreaObject = {
            index: index + 1,
            id:
              stakeholderByUserData.id === null ? "" : stakeholderByUserData.id,
            userName:
              stakeholderByUserData.userName === null
                ? ""
                : stakeholderByUserData.userName,
            firmName:
              stakeholderByUserData.firmName === null
                ? ""
                : stakeholderByUserData.firmName,
            ownerName:
              stakeholderByUserData.ownerName === null
                ? ""
                : stakeholderByUserData.ownerName,

            orderToOwnerName:
              stakeholderByUserData.orderToOwnerName === null
                ? ""
                : stakeholderByUserData.orderToOwnerName,
            state:
              stakeholderByUserData.state !== null &&
                stakeholderByUserData.state.id
                ? stakeholderByUserData.state.id
                : "",
            stateName:
              stakeholderByUserData.state !== null &&
                stakeholderByUserData.state.name
                ? stakeholderByUserData.state.name
                : "",

            brandName:
              stakeholderByUserData.brand !== null &&
                stakeholderByUserData.brand.name
                ? stakeholderByUserData.brand.name
                : "",

            name:
              stakeholderByUserData.ownerName === null &&
                stakeholderByUserData.firmName === null
                ? ""
                : stakeholderByUserData.firmName +
                " - " +
                stakeholderByUserData.ownerName,

            areaNames:
              stakeholderByUserData.areaNames === null
                ? ""
                : stakeholderByUserData.areaNames,
            contactPersonName:
              stakeholderByUserData.contactPersonName === null
                ? ""
                : stakeholderByUserData.contactPersonName,
            contactPersonNo:
              stakeholderByUserData.contactPersonNo === null
                ? ""
                : stakeholderByUserData.contactPersonNo,
            address:
              stakeholderByUserData.address === null
                ? ""
                : stakeholderByUserData.address,
            emailId:
              stakeholderByUserData.emailId === null
                ? ""
                : stakeholderByUserData.emailId,
            stockStakeHolderTypeName:
              stakeholderByUserData.stockStakeHolderType !== null &&
                stakeholderByUserData.stockStakeHolderType.name !== null
                ? stakeholderByUserData.stockStakeHolderType.name
                : "",

            stockStakeHolderType:
              stakeholderByUserData.stockStakeHolderType !== null &&
                stakeholderByUserData.stockStakeHolderType.id
                ? stakeholderByUserData.stockStakeHolderType.id
                : "",

            region:
              stakeholderByUserData.region !== null &&
                stakeholderByUserData.region.id
                ? stakeholderByUserData.region.id
                : "",
            regionName:
              stakeholderByUserData.region !== null &&
                stakeholderByUserData.region.name
                ? stakeholderByUserData.region.name
                : "",

            zoneIds:
              stakeholderByUserData.zoneIds === null
                ? ""
                : stakeholderByUserData.zoneIds,

            areaIds:
              stakeholderByUserData.areaIds === null
                ? ""
                : stakeholderByUserData.areaIds,

            isActive: stakeholderByUserData.isActive,
            isChecked: false,
          };
          return stakeholderByUserAreaObject;
        });
        dispatch(stockStakeHoldersSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getStockholderByUserArea = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-active-by-user-areas",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stakeholderByUserData, index) => {
          let stakeholderByUserAreaObject = {
            index: index + 1,
            id:
              stakeholderByUserData.id === null ? "" : stakeholderByUserData.id,
            userName:
              stakeholderByUserData.userName === null
                ? ""
                : stakeholderByUserData.userName,
            firmName:
              stakeholderByUserData.firmName === null
                ? ""
                : stakeholderByUserData.firmName,
            ownerName:
              stakeholderByUserData.ownerName === null
                ? ""
                : stakeholderByUserData.ownerName,

            orderToOwnerName:
              stakeholderByUserData.orderToOwnerName === null
                ? ""
                : stakeholderByUserData.orderToOwnerName,
            state:
              stakeholderByUserData.state !== null &&
                stakeholderByUserData.state.id
                ? stakeholderByUserData.state.id
                : "",
            stateName:
              stakeholderByUserData.state !== null &&
                stakeholderByUserData.state.name
                ? stakeholderByUserData.state.name
                : "",

            brandName:
              stakeholderByUserData.brand !== null &&
                stakeholderByUserData.brand.name
                ? stakeholderByUserData.brand.name
                : "",

            name:
              stakeholderByUserData.ownerName === null &&
                stakeholderByUserData.firmName === null
                ? ""
                : stakeholderByUserData.firmName +
                " - " +
                stakeholderByUserData.ownerName,

            areaNames:
              stakeholderByUserData.areaNames === null
                ? ""
                : stakeholderByUserData.areaNames,
            contactPersonName:
              stakeholderByUserData.contactPersonName === null
                ? ""
                : stakeholderByUserData.contactPersonName,
            contactPersonNo:
              stakeholderByUserData.contactPersonNo === null
                ? ""
                : stakeholderByUserData.contactPersonNo,
            address:
              stakeholderByUserData.address === null
                ? ""
                : stakeholderByUserData.address,
            emailId:
              stakeholderByUserData.emailId === null
                ? ""
                : stakeholderByUserData.emailId,
            stockStakeHolderTypeName:
              stakeholderByUserData.stockStakeHolderType !== null &&
                stakeholderByUserData.stockStakeHolderType.name !== null
                ? stakeholderByUserData.stockStakeHolderType.name
                : "",

            stockStakeHolderType:
              stakeholderByUserData.stockStakeHolderType !== null &&
                stakeholderByUserData.stockStakeHolderType.id
                ? stakeholderByUserData.stockStakeHolderType.id
                : "",

            region:
              stakeholderByUserData.region !== null &&
                stakeholderByUserData.region.id
                ? stakeholderByUserData.region.id
                : "",
            regionName:
              stakeholderByUserData.region !== null &&
                stakeholderByUserData.region.name
                ? stakeholderByUserData.region.name
                : "",

            zoneIds:
              stakeholderByUserData.zoneIds === null
                ? ""
                : stakeholderByUserData.zoneIds,

            areaIds:
              stakeholderByUserData.areaIds === null
                ? ""
                : stakeholderByUserData.areaIds,

            isActive: stakeholderByUserData.isActive,
            isChecked: false,
          };
          return stakeholderByUserAreaObject;
        });
        dispatch(stockStakeHoldersSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};



export const getStockholderByAreaIds =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-areas?areaIds=" + areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeholderData, index) => {
              let stakeholderByAreaObject = {
                index: index + 1,
                id: stakeholderData.id === null ? "" : stakeholderData.id,
                userName:
                  stakeholderData.userName === null
                    ? ""
                    : stakeholderData.userName,
                firmName:
                  stakeholderData.firmName === null
                    ? ""
                    : stakeholderData.firmName,
                ownerName:
                  stakeholderData.ownerName === null
                    ? ""
                    : stakeholderData.ownerName,
                orderToOwnerName:
                  stakeholderData.orderToOwnerName === null
                    ? ""
                    : stakeholderData.orderToOwnerName,
                state:
                  stakeholderData.state !== null && stakeholderData.state.id
                    ? stakeholderData.state.id
                    : "",
                stateName:
                  stakeholderData.state !== null && stakeholderData.state.name
                    ? stakeholderData.state.name
                    : "",
                areaNames:
                  stakeholderData.areaNames === null
                    ? ""
                    : stakeholderData.areaNames,
                name:
                  stakeholderData.contactPersonName === null
                    ? ""
                    : stakeholderData.contactPersonName,
                shCode:
                  stakeholderData.shCode === null ? "" : stakeholderData.shCode,
                contactPersonName:
                  stakeholderData.contactPersonName === null
                    ? ""
                    : stakeholderData.contactPersonName,
                contactPersonNo:
                  stakeholderData.contactPersonNo === null
                    ? ""
                    : stakeholderData.contactPersonNo,
                address:
                  stakeholderData.address === null ? "" : stakeholderData.address,
                emailId:
                  stakeholderData.emailId === null ? "" : stakeholderData.emailId,
                stockStakeHolderTypeName:
                  stakeholderData.stockStakeHolderType !== null &&
                    stakeholderData.stockStakeHolderType.name !== null
                    ? stakeholderData.stockStakeHolderType.name
                    : "",

                stockStakeHolderType:
                  stakeholderData.stockStakeHolderType !== null &&
                    stakeholderData.stockStakeHolderType.id
                    ? stakeholderData.stockStakeHolderType.id
                    : "",

                region:
                  stakeholderData.region !== null && stakeholderData.region.id
                    ? stakeholderData.region.id
                    : "",
                regionName:
                  stakeholderData.region !== null && stakeholderData.region.name
                    ? stakeholderData.region.name
                    : "",

                zoneIds:
                  stakeholderData.zoneIds === null ? "" : stakeholderData.zoneIds,

                areaIds:
                  stakeholderData.areaIds === null ? "" : stakeholderData.areaIds,

                isActive: stakeholderData.isActive,
              };
              return stakeholderByAreaObject;
            });
            dispatch(stockStakeHoldersByAreaSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getStockholderByAreaIdsForFilter =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-areas?areaIds=" + areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeholderData, index) => {
              let stakeholderByAreaObject = {
                index: index + 1,
                id: stakeholderData.id === null ? "" : stakeholderData.id,
                userName:
                  stakeholderData.userName === null
                    ? ""
                    : stakeholderData.userName,
                firmName:
                  stakeholderData.firmName === null
                    ? ""
                    : stakeholderData.firmName,
                ownerName:
                  stakeholderData.ownerName === null
                    ? ""
                    : stakeholderData.ownerName,
                orderTo:
                  //stakeholderData.orderTo === null ? "" : stakeholderData.orderTo,
                  stakeholderData.orderTo == null
                    ? ""
                    : stakeholderData.orderTo == 0
                      ? "Admin"
                      : "-",
                state:
                  stakeholderData.state !== null && stakeholderData.state.id
                    ? stakeholderData.state.id
                    : "",
                stateName:
                  stakeholderData.state !== null && stakeholderData.state.name
                    ? stakeholderData.state.name
                    : "",
                areaNames:
                  stakeholderData.areaNames === null
                    ? ""
                    : stakeholderData.areaNames,
                shCode:
                  stakeholderData.shCode === null ? "" : stakeholderData.shCode,
                contactPersonName:
                  stakeholderData.contactPersonName === null
                    ? ""
                    : stakeholderData.contactPersonName,
                contactPersonNo:
                  stakeholderData.contactPersonNo === null
                    ? ""
                    : stakeholderData.contactPersonNo,
                emailId:
                  stakeholderData.emailId === null ? "" : stakeholderData.emailId,
                stockStakeHolderTypeName:
                  stakeholderData.stockStakeHolderType !== null &&
                    stakeholderData.stockStakeHolderType.name !== null
                    ? stakeholderData.stockStakeHolderType.name
                    : "",

                stockStakeHolderType:
                  stakeholderData.stockStakeHolderType !== null &&
                    stakeholderData.stockStakeHolderType.id
                    ? stakeholderData.stockStakeHolderType.id
                    : "",

                isActive: stakeholderData.isActive,

                address:
                  stakeholderData.address === null ? "" : stakeholderData.address,

                region:
                  stakeholderData.region !== null && stakeholderData.region.id
                    ? stakeholderData.region.id
                    : "",
                regionName:
                  stakeholderData.region !== null && stakeholderData.region.name
                    ? stakeholderData.region.name
                    : "",

                zoneIds:
                  stakeholderData.zoneIds === null ? "" : stakeholderData.zoneIds,

                areaIds:
                  stakeholderData.areaIds === null ? "" : stakeholderData.areaIds,
              };
              return stakeholderByAreaObject;
            });
            dispatch(stakeHolderSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getStockholderByAreaIdsForFilterPost =
  ({ areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-areas-new",
          postBody: areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeholderData, index) => {
              let stakeholderByAreaObject = {
                index: index + 1,
                id: stakeholderData.id === null ? "" : stakeholderData.id,
                userName:
                  stakeholderData.userName === null
                    ? ""
                    : stakeholderData.userName,
                firmName:
                  stakeholderData.firmName === null
                    ? ""
                    : stakeholderData.firmName,
                ownerName:
                  stakeholderData.ownerName === null
                    ? ""
                    : stakeholderData.ownerName,
                orderTo:
                  //stakeholderData.orderTo === null ? "" : stakeholderData.orderTo,
                  stakeholderData.orderTo == null
                    ? ""
                    : stakeholderData.orderTo == 0
                      ? "Admin"
                      : "-",
                orderToName:
                  stakeholderData.orderToFirmName !== null ? stakeholderData.orderToFirmName : "",
                state:
                  stakeholderData.stateId !== null ? stakeholderData.stateId : "",
                stateName:
                  stakeholderData.stateName !== null
                    ? stakeholderData.stateName
                    : "",
                areaNames:
                  stakeholderData.areaNames === null
                    ? ""
                    : stakeholderData.areaNames,
                shCode:
                  stakeholderData.shCode === null ? "" : stakeholderData.shCode,
                contactPersonName:
                  stakeholderData.contactPersonName === null
                    ? ""
                    : stakeholderData.contactPersonName,
                contactPersonNo:
                  stakeholderData.contactPersonNo === null
                    ? ""
                    : stakeholderData.contactPersonNo,
                emailId:
                  stakeholderData.emailId === null ? "" : stakeholderData.emailId,
                stockStakeHolderTypeName:
                  stakeholderData.stockStakeHolderTypeName !== null
                    ? stakeholderData.stockStakeHolderTypeName
                    : "",

                stockStakeHolderType:
                  stakeholderData.stockStakeHolderTypeId !== null
                    ? stakeholderData.stockStakeHolderTypeId
                    : "",

                isActive: stakeholderData.isActive,

                address:
                  stakeholderData.address === null ? "" : stakeholderData.address,

                region: stakeholderData.regionId ? stakeholderData.regionId : "",
                regionName: stakeholderData.regionName
                  ? stakeholderData.regionName
                  : "",

                zoneIds:
                  stakeholderData.zoneIds === null ? "" : stakeholderData.zoneIds,

                areaIds:
                  stakeholderData.areaIds === null ? "" : stakeholderData.areaIds,
              };
              return stakeholderByAreaObject;
            });
            dispatch(stakeHolderSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getStakeHolderNameByStakeholderTypeAndArea =
  ({ type, areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-type-and-area-ids?type=" + type + "&areaIds=" + areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeHolderTypeObj, index) => {
              const stakeHolderTypeData = {
                index: index + 1,
                ...stakeHolderTypeObj,
                name:
                  stakeHolderTypeObj.ownerName !== null &&
                    stakeHolderTypeObj.firmName &&
                    stakeHolderTypeObj.ownerName &&
                    stakeHolderTypeObj.firmName
                    ? stakeHolderTypeObj.firmName +
                    "" +
                    "(" +
                    stakeHolderTypeObj.ownerName +
                    ")"
                    : "",
              };
              return stakeHolderTypeData;
            });
            dispatch(stakeHolderNameFromTypeSuccess({ row: row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getStakeHolderNameByStakeholderTypeAndAreaPost =
  ({ jsonData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-stock-stake-holder-type-and-areas",
          postBody: jsonData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeHolderTypeObj, index) => {
              const stakeHolderTypeData = {
                index: index + 1,
                ...stakeHolderTypeObj,
                name:
                  stakeHolderTypeObj.ownerName !== null &&
                    stakeHolderTypeObj.firmName &&
                    stakeHolderTypeObj.ownerName &&
                    stakeHolderTypeObj.firmName
                    ? stakeHolderTypeObj.firmName +
                    "" +
                    "(" +
                    stakeHolderTypeObj.ownerName +
                    ")"
                    : "",
              };
              return stakeHolderTypeData;
            });
            dispatch(stakeHolderNameFromTypeSuccess({ row: row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getInActiveStakeHolderNameByStakeholderTypeAndAreaPost =
  ({ jsonData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/inactive-stock-stake-holder-by-type-and-areas",
          postBody: jsonData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeHolderTypeObj, index) => {
              const stakeHolderTypeData = {
                index: index + 1,
                ...stakeHolderTypeObj,
                name:
                  stakeHolderTypeObj.ownerName !== null &&
                    stakeHolderTypeObj.firmName &&
                    stakeHolderTypeObj.ownerName &&
                    stakeHolderTypeObj.firmName
                    ? stakeHolderTypeObj.firmName +
                    "" +
                    "(" +
                    stakeHolderTypeObj.ownerName +
                    ")"
                    : "",
              };
              return stakeHolderTypeData;
            });
            dispatch(stakeHolderNameFromTypeSuccess({ row: row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getInActiveStockStakeHolder =
  ({ jsonData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/inactive-stock-stake-holder-by-type",
          postBody: jsonData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeHolderTypeObj, index) => {
              const stakeHolderTypeData = {
                index: index + 1,
                ...stakeHolderTypeObj,
                name:
                  stakeHolderTypeObj.ownerName !== null &&
                    stakeHolderTypeObj.firmName &&
                    stakeHolderTypeObj.ownerName &&
                    stakeHolderTypeObj.firmName
                    ? stakeHolderTypeObj.firmName +
                    "" +
                    "(" +
                    stakeHolderTypeObj.ownerName +
                    ")"
                    : "",
              };
              return stakeHolderTypeData;
            });
            dispatch(stakeHolderNameFromTypeSuccess({ row: row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };



export const getStakeHolderNameByStakeholderTypeAndAreaUpdated =
  ({ stockStakeHolderTypeIds, areaIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-stock-stake-holder-types-and-areas?stockStakeHolderTypeIds=" +
            stockStakeHolderTypeIds +
            "&areaIds=" +
            areaIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((stakeHolderTypeObj, index) => {
              const stakeHolderTypeData = {
                index: index + 1,
                ...stakeHolderTypeObj,
                name:
                  stakeHolderTypeObj.ownerName !== null &&
                    stakeHolderTypeObj.firmName &&
                    stakeHolderTypeObj.ownerName &&
                    stakeHolderTypeObj.firmName
                    ? stakeHolderTypeObj.firmName +
                    "" +
                    "(" +
                    stakeHolderTypeObj.ownerName +
                    ")"
                    : "",
              };
              return stakeHolderTypeData;
            });
            dispatch(stakeHolderNameFromTypeSuccess({ row: row }));
            return { response: [], success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setStakeHolderNameByStakeholderTypeAndArea =
  ({ row }) =>
    async (dispatch) => {
      dispatch(stakeHolderNameFromTypeSuccess({ row }));
    };
export const getAreaByDistrictPost =
  ({ areaDataForPost }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-areas-by-zones-districts",
          postBody: areaDataForPost,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((areaObject, index) => {
              let areaPostData = {
                index: index + 1,
                id: areaObject.id === null ? "" : areaObject.id,
                name:
                  areaObject.name === null
                    ? ""
                    : areaObject.name + " (" + areaObject.shortName + ")",
                taluka: areaObject.taluka === null ? "" : areaObject.taluka,
                zone:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district !== null &&
                    areaObject.taluka.district.zone !== null &&
                    areaObject.taluka.district.zone.id
                    ? areaObject.taluka.district.zone.id
                    : "",
                zoneName:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district !== null &&
                    areaObject.taluka.district.zone !== null &&
                    areaObject.taluka.district.zone.name
                    ? areaObject.taluka.district.zone.name
                    : "",

                shortName:
                  areaObject.shortName === null ? "" : areaObject.shortName,
                sortOrder:
                  areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              };

              return areaPostData;
            });
            dispatch(areaByDistrictPostSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getAreaByDistrictPostNew =
  ({ areaDataForPost }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-areas-by-zones-districts-new",
          postBody: areaDataForPost,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((areaObject, index) => {
              let areaPostData = {
                index: index + 1,
                id: areaObject.id === null ? "" : areaObject.id,
                name:
                  areaObject.name === null
                    ? ""
                    : areaObject.name + " (" + areaObject.shortName + ")",
                taluka: areaObject.taluka === null ? "" : areaObject.taluka,
                zone:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district !== null &&
                    areaObject.taluka.district.zone !== null &&
                    areaObject.taluka.district.zone.id
                    ? areaObject.taluka.district.zone.id
                    : "",
                zoneName:
                  areaObject.taluka !== null &&
                    areaObject.taluka.district !== null &&
                    areaObject.taluka.district.zone !== null &&
                    areaObject.taluka.district.zone.name
                    ? areaObject.taluka.district.zone.name
                    : "",

                shortName:
                  areaObject.shortName === null ? "" : areaObject.shortName,
                sortOrder:
                  areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              };

              return areaPostData;
            });
            dispatch(areaByDistrictPostSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

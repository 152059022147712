import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  product: [],
  isFetch: false,
  productByCategory: [],
  productByCategoryAndOutletAndDateWise: [],
  productByCategoryForStakeholder: [],
  activeProduct: [],
  activeAllProduct: [],
  activeProductByCategory: [],
};
let URL = endpoints.product;
const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        product: row,
        isFetch: true,
      };
    },

    productByCategorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        productByCategory: row,
      };
    },

    productByCategoryForStakeholderSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        productByCategoryForStakeholder: row,
      };
    },

    productByCategoryAndOutletAndDateWiseSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        productByCategoryAndOutletAndDateWise: row,
      };
    },

    activeProductSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activeProduct: row,
      };
    },
    activeAllProductSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activeAllProduct: row,
      };
    },
    activeProductByCategorySuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        activeProductByCategory: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  productSuccess,
  productByCategorySuccess,
  productByCategoryForStakeholderSuccess,
  activeProductSuccess,
  activeAllProductSuccess,
  activeProductByCategorySuccess,
  resetState,
  productByCategoryAndOutletAndDateWiseSuccess,
} = productSlice.actions;

export default productSlice.reducer;

export const getProduct = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((productObject, index) => {
          let productData = {
            index: index + 1,
            id: productObject.id === null ? "" : productObject.id,
            name:
              productObject.name === null
                ? ""
                : productObject.name + " (" + productObject.shortName + ")",

            code: productObject.code === null ? "" : productObject.code,
            group: productObject.group === null ? "" : productObject.group,
            categoryName:
              productObject.group !== null &&
                productObject.group.subCategory !== null &&
                productObject.group.subCategory.category !== null &&
                productObject.group.subCategory.category.name !== null
                ? productObject.group.subCategory.category.name
                : "",
            category:
              productObject.group !== null &&
                productObject.group.subCategory !== null &&
                productObject.group.subCategory.category !== null &&
                productObject.group.subCategory.category.id !== null
                ? productObject.group.subCategory.category.id
                : "",
            subCategoryName:
              productObject.group !== null &&
                productObject.group.subCategory !== null &&
                productObject.group.subCategory.name !== null
                ? productObject.group.subCategory.name
                : "",
            subCategory:
              productObject.group.subCategory !== null &&
                productObject.group.subCategory.id
                ? productObject.group.subCategory.id
                : "",
            stakeHolderUom:
              productObject.stakeHolderUom !== null &&
                productObject.stakeHolderUom.id
                ? productObject.stakeHolderUom.id
                : "",
            stakeHolderUomName:
              productObject.stakeHolderUom !== null &&
                productObject.stakeHolderUom.name
                ? productObject.stakeHolderUom.name
                : "",
            uom:
              productObject.outletUom !== null && productObject.outletUom.id
                ? productObject.outletUom.id
                : "",
            outletUomName:
              productObject.outletUom !== null && productObject.outletUom.name
                ? productObject.outletUom.name
                : "",

            uomConversionValue:
              productObject.uomConversionValue === null
                ? ""
                : productObject.uomConversionValue,
            description:
              productObject.description === null
                ? ""
                : productObject.description,
            taxName: productObject.tax === null ? "" : productObject.tax,
            tax: productObject.tax === null ? "" : productObject.tax,
            selfLife:
              productObject.selfLife === null ? "" : productObject.selfLife,
            image: productObject.image === null ? "" : productObject.image,
            weight: productObject.weight === null ? "" : productObject.weight,
            shortName:
              productObject.shortName === null ? "" : productObject.shortName,
            sortOrder:
              productObject.sortOrder === null ? "" : productObject.sortOrder,
            isActive:
              productObject.isActive === null ? "" : productObject.isActive,
          };
          return productData;
        });
        dispatch(productSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const setProductList =
  ({ row }) =>
    async (dispatch) => {
      dispatch(productSuccess({ row }));
    };
export const getProductByCategory =
  ({ categoryId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-category?categoryId=" + categoryId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id:
                  productObject.product.id === null
                    ? ""
                    : productObject.product.id,
                name:
                  productObject.product === null
                    ? ""
                    : productObject.product.name +
                    " (" +
                    productObject.product.shortName +
                    ")",
                rate:
                  productObject.rate === null
                    ? ""
                    : (+productObject.rate).toFixed(2),
                code:
                  productObject.product.code === null
                    ? ""
                    : productObject.product.code,
                categoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.name !== null
                    ? productObject.product.group.subCategory.category.name
                    : "",
                category:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory != null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.id !== null
                    ? productObject.product.group.subCategory.category.id
                    : "",
                subCategoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.name
                    ? productObject.product.group.subCategory.name
                    : "",
                subCategory:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.id
                    ? productObject.product.group.subCategory.id
                    : "",
                stakeHolderUom:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.id
                    ? productObject.product.stakeHolderUom.id
                    : "",
                stakeHolderUomName:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.name
                    ? productObject.product.stakeHolderUom.name
                    : "",
                uom:
                  productObject.product !== null &&
                    productObject.product.outletUom !== null &&
                    productObject.product.outletUom.id
                    ? productObject.product.outletUom.id
                    : "",
                outletUomName:
                  productObject.product !== null &&
                    productObject.product.outletUom !== null &&
                    productObject.product.outletUom.name
                    ? productObject.product.outletUom.name
                    : "",

                uomConversionValue:
                  productObject.product === null &&
                    productObject.product.uomConversionValue === null
                    ? ""
                    : productObject.product.uomConversionValue,
                description:
                  productObject.product === null &&
                    productObject.product.description === null
                    ? ""
                    : productObject.product.description,
                taxName:
                  productObject.product === null &&
                    productObject.product.tax === null
                    ? ""
                    : productObject.product.tax,
                tax:
                  productObject.product === null &&
                    productObject.product.tax === null
                    ? ""
                    : productObject.product.tax,
                selfLife:
                  productObject.product === null &&
                    productObject.product.selfLife === null
                    ? ""
                    : productObject.product.selfLife,
                image:
                  productObject.product === null &&
                    productObject.product.image === null
                    ? ""
                    : productObject.product.image,
                weight:
                  productObject.product === null &&
                    productObject.product.weight === null
                    ? ""
                    : productObject.product.weight,
                shortName:
                  productObject.product === null &&
                    productObject.product.shortName === null
                    ? ""
                    : productObject.product.shortName,
                sortOrder:
                  productObject.product === null &&
                    productObject.product.sortOrder === null
                    ? ""
                    : productObject.product.sortOrder,
                isActive:
                  productObject.product === null &&
                    productObject.product.isActive === null
                    ? ""
                    : productObject.product.isActive,
                outletUom:
                  productObject.product === null &&
                    productObject.product.outletUom === null
                    ? ""
                    : productObject.product.outletUom,
              };
              return productData;
            });
            dispatch(productByCategorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getAllProductByCategories =
  ({ categoryIds }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-categories?categoryIds=" + categoryIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id:
                  productObject.id === null
                    ? ""
                    : productObject.id,
                name:
                  productObject.name !== null && productObject.shortName !== null
                    ? productObject.name +
                    " (" +
                    productObject.shortName +
                    ")"
                    : "",
                rate:
                  productObject.rate === null
                    ? ""
                    : (+productObject.rate).toFixed(2),
                code:
                  productObject.code === null
                    ? ""
                    : productObject.code,
                categoryName:
                  productObject.group !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.category !== null &&
                    productObject.group.subCategory.category.name !== null
                    ? productObject.group.subCategory.category.name
                    : "",

                category:
                  productObject.group !== null &&
                    productObject.group.subCategory != null &&
                    productObject.group.subCategory.category !== null &&
                    productObject.group.subCategory.category.id !== null
                    ? productObject.group.subCategory.category.id
                    : "",

                subCategoryName:
                  productObject.group !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.name
                    ? productObject.group.subCategory.name
                    : "",

                subCategory:
                  productObject.group !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.id
                    ? productObject.group.subCategory.id
                    : "",

                stakeHolderUom:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.id
                    ? productObject.stakeHolderUom.id
                    : "",

                stakeHolderUomName:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.name
                    ? productObject.stakeHolderUom.name
                    : "",
                uom:
                  productObject.outletUom !== null &&
                    productObject.outletUom.id
                    ? productObject.outletUom.id
                    : "",

                outletUomName:
                  productObject.outletUom !== null &&
                    productObject.outletUom.name
                    ? productObject.outletUom.name
                    : "",

                uomConversionValue:
                  productObject.uomConversionValue === null
                    ? ""
                    : productObject.uomConversionValue,

                description:
                  productObject.product === null &&
                    productObject.description === null
                    ? ""
                    : productObject.description,

                taxName:
                  productObject.product === null &&
                    productObject.tax === null
                    ? ""
                    : productObject.tax,
                tax:
                  productObject.product === null &&
                    productObject.tax === null
                    ? ""
                    : productObject.tax,
                selfLife:
                  productObject.product === null &&
                    productObject.selfLife === null
                    ? ""
                    : productObject.selfLife,
                image:
                  productObject.product === null &&
                    productObject.image === null
                    ? ""
                    : productObject.image,
                weight:
                  productObject.product === null &&
                    productObject.weight === null
                    ? ""
                    : productObject.weight,
                shortName:
                  productObject.product === null &&
                    productObject.shortName === null
                    ? ""
                    : productObject.shortName,
                sortOrder:
                  productObject.product === null &&
                    productObject.sortOrder === null
                    ? ""
                    : productObject.sortOrder,
                isActive:
                  productObject.product === null &&
                    productObject.isActive === null
                    ? ""
                    : productObject.isActive,
                outletUom:
                  productObject.product === null &&
                    productObject.outletUom === null
                    ? ""
                    : productObject.outletUom,
              };
              return productData;
            });
            dispatch(productByCategorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getProductByCategoryList =
  ({ categoryId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/list-by-category?categoryId=" + categoryId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id: productObject.id === null ? "" : productObject.id,
                name:
                  productObject.name === null
                    ? ""
                    : productObject.name + " (" + productObject.shortName + ")",
                rate:
                  productObject.rate === null
                    ? ""
                    : (+productObject.rate).toFixed(2),
                code: productObject.code === null ? "" : productObject.code,
              };
              return productData;
            });
            dispatch(productByCategorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getProductByCategoryForStakeHolder =
  ({ categoryId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-categories-for-stake-holder?categoryId=" + categoryId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id:
                  productObject.product.id === null
                    ? ""
                    : productObject.product.id,
                name:
                  productObject.product === null
                    ? ""
                    : productObject.product.name +
                    " (" +
                    productObject.product.shortName +
                    ")",
                mrp:
                  productObject.mrp === null
                    ? ""
                    : (+productObject.mrp).toFixed(2),
                rate:
                  productObject.rate === null
                    ? ""
                    : (+productObject.rate).toFixed(2),

                code:
                  productObject.product.code === null
                    ? ""
                    : productObject.product.code,
                categoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.name !== null
                    ? productObject.product.group.subCategory.category.name
                    : "",
                category:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory != null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.id !== null
                    ? productObject.product.group.subCategory.category.id
                    : "",
                subCategoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.name
                    ? productObject.product.group.subCategory.name
                    : "",
                subCategory:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.id
                    ? productObject.product.group.subCategory.id
                    : "",
                stakeHolderUomName:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.name
                    ? productObject.product.stakeHolderUom.name
                    : "",
                stakeHolderUom:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.id
                    ? productObject.product.stakeHolderUom.id
                    : "",
                uom:
                  productObject.product !== null &&
                    productObject.product.uom !== null
                    ? productObject.product.uom
                    : "",
                outletUomName:
                  productObject.product !== null &&
                    productObject.product.outletUom !== null &&
                    productObject.product.outletUom.name
                    ? productObject.product.outletUom.name
                    : "",

                uomConversionValue:
                  productObject.product === null &&
                    productObject.product.uomConversionValue === null
                    ? ""
                    : productObject.product.uomConversionValue,
                description:
                  productObject.product === null &&
                    productObject.product.description === null
                    ? ""
                    : productObject.product.description,
                taxName:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.name,
                tax:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage,
                selfLife:
                  productObject.product === null &&
                    productObject.product.selfLife === null
                    ? ""
                    : productObject.product.selfLife,
                image:
                  productObject.product === null &&
                    productObject.product.image === null
                    ? ""
                    : productObject.product.image,
                weight:
                  productObject.product === null &&
                    productObject.product.weight === null
                    ? ""
                    : productObject.product.weight,
                shortName:
                  productObject.product === null &&
                    productObject.product.shortName === null
                    ? ""
                    : productObject.product.shortName,
                sortOrder:
                  productObject.product === null &&
                    productObject.product.sortOrder === null
                    ? ""
                    : productObject.product.sortOrder,
                isActive:
                  productObject.product === null &&
                    productObject.product.isActive === null
                    ? ""
                    : productObject.product.isActive,
                outletUom:
                  productObject.product === null &&
                    productObject.product.outletUom === null
                    ? ""
                    : productObject.product.outletUom,
                hsnCode:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.hsnCode,
                cgst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.cgst,
                cess:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.cess,
                igst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.igst,
                sgst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.sgst,
              };
              return productData;
            });
            dispatch(productByCategoryForStakeholderSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getProductByCategoryIdForStakeHolder =
  ({ categoryId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL + "/by-category-and-stock-stake-holder?categoryId=" + categoryId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id:
                  productObject.product.id === null
                    ? ""
                    : productObject.product.id,
                name:
                  productObject.product === null
                    ? ""
                    : productObject.product.name +
                    " (" +
                    productObject.product.shortName +
                    ")",
                mrp:
                  productObject.mrp === null
                    ? ""
                    : (+productObject.mrp).toFixed(2),
                rate:
                  productObject.rate === null
                    ? ""
                    : (+productObject.rate).toFixed(2),

                code:
                  productObject.product.code === null
                    ? ""
                    : productObject.product.code,
                categoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.name !== null
                    ? productObject.product.group.subCategory.category.name
                    : "",
                category:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory != null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.id !== null
                    ? productObject.product.group.subCategory.category.id
                    : "",
                subCategoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.name
                    ? productObject.product.group.subCategory.name
                    : "",
                subCategory:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.id
                    ? productObject.product.group.subCategory.id
                    : "",
                stakeHolderUomName:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.name
                    ? productObject.product.stakeHolderUom.name
                    : "",
                stakeHolderUom:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.id
                    ? productObject.product.stakeHolderUom.id
                    : "",
                uom:
                  productObject.product !== null &&
                    productObject.product.uom !== null
                    ? productObject.product.uom
                    : "",
                outletUomName:
                  productObject.product !== null &&
                    productObject.product.outletUom !== null &&
                    productObject.product.outletUom.name
                    ? productObject.product.outletUom.name
                    : "",

                uomConversionValue:
                  productObject.product === null &&
                    productObject.product.uomConversionValue === null
                    ? ""
                    : productObject.product.uomConversionValue,
                description:
                  productObject.product === null &&
                    productObject.product.description === null
                    ? ""
                    : productObject.product.description,
                taxName:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.name,
                tax:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage,
                selfLife:
                  productObject.product === null &&
                    productObject.product.selfLife === null
                    ? ""
                    : productObject.product.selfLife,
                image:
                  productObject.product === null &&
                    productObject.product.image === null
                    ? ""
                    : productObject.product.image,
                weight:
                  productObject.product === null &&
                    productObject.product.weight === null
                    ? ""
                    : productObject.product.weight,
                shortName:
                  productObject.product === null &&
                    productObject.product.shortName === null
                    ? ""
                    : productObject.product.shortName,
                sortOrder:
                  productObject.product === null &&
                    productObject.product.sortOrder === null
                    ? ""
                    : productObject.product.sortOrder,
                isActive:
                  productObject.product === null &&
                    productObject.product.isActive === null
                    ? ""
                    : productObject.product.isActive,
                outletUom:
                  productObject.product === null &&
                    productObject.product.outletUom === null
                    ? ""
                    : productObject.product.outletUom,
                hsnCode:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.hsnCode,
                cgst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.cgst,
                cess:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.cess,
                igst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.igst,
                sgst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.sgst,
                product:
                  productObject.product === null ? "" : productObject.product,
              };
              return productData;
            });
            dispatch(productByCategoryForStakeholderSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getProductByCategoryIdAndStockHolderIdForStakeHolder =
  ({ categoryId, stockStakeHolderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-stock-stake-holder-and-category?categoryId=" +
            categoryId +
            "&stockStakeHolderId=" +
            stockStakeHolderId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id:
                  productObject.product.id === null
                    ? ""
                    : productObject.product.id,
                name:
                  productObject.product === null
                    ? ""
                    : productObject.product.name +
                    " (" +
                    productObject.product.shortName +
                    ")",
                mrp:
                  productObject.mrp === null
                    ? ""
                    : (+productObject.mrp).toFixed(2),
                rate:
                  productObject.rate === null
                    ? ""
                    : (+productObject.rate).toFixed(2),

                code:
                  productObject.product.code === null
                    ? ""
                    : productObject.product.code,
                categoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.name !== null
                    ? productObject.product.group.subCategory.category.name
                    : "",
                category:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory != null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.id !== null
                    ? productObject.product.group.subCategory.category.id
                    : "",
                subCategoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.name
                    ? productObject.product.group.subCategory.name
                    : "",
                subCategory:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.id
                    ? productObject.product.group.subCategory.id
                    : "",
                stakeHolderUomName:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.name
                    ? productObject.product.stakeHolderUom.name
                    : "",
                stakeHolderUom:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.id
                    ? productObject.product.stakeHolderUom.id
                    : "",
                uom:
                  productObject.product !== null &&
                    productObject.product.uom !== null
                    ? productObject.product.uom
                    : "",
                outletUomName:
                  productObject.product !== null &&
                    productObject.product.outletUom !== null &&
                    productObject.product.outletUom.name
                    ? productObject.product.outletUom.name
                    : "",

                uomConversionValue:
                  productObject.product === null &&
                    productObject.product.uomConversionValue === null
                    ? ""
                    : productObject.product.uomConversionValue,
                description:
                  productObject.product === null &&
                    productObject.product.description === null
                    ? ""
                    : productObject.product.description,
                taxName:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.name,
                tax:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage,
                selfLife:
                  productObject.product === null &&
                    productObject.product.selfLife === null
                    ? ""
                    : productObject.product.selfLife,
                image:
                  productObject.product === null &&
                    productObject.product.image === null
                    ? ""
                    : productObject.product.image,
                weight:
                  productObject.product === null &&
                    productObject.product.weight === null
                    ? ""
                    : productObject.product.weight,
                shortName:
                  productObject.product === null &&
                    productObject.product.shortName === null
                    ? ""
                    : productObject.product.shortName,
                sortOrder:
                  productObject.product === null &&
                    productObject.product.sortOrder === null
                    ? ""
                    : productObject.product.sortOrder,
                isActive:
                  productObject.product === null &&
                    productObject.product.isActive === null
                    ? ""
                    : productObject.product.isActive,
                outletUom:
                  productObject.product === null &&
                    productObject.product.outletUom === null
                    ? ""
                    : productObject.product.outletUom,
                hsnCode:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.hsnCode,
                cgst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.cgst,
                cess:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.cess,
                igst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.igst,
                sgst:
                  productObject.product === null &&
                    productObject.product.taxPercentage === null
                    ? ""
                    : productObject.product.taxPercentage.sgst,
              };
              return productData;
            });
            dispatch(productByCategoryForStakeholderSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getProductByCategoryOutletAndDateWise =
  ({ orderDate, categoryId, outletId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-category-for-batch-billing?orderDate=" +
            orderDate +
            "&categoryId=" +
            categoryId +
            "&outletId=" +
            outletId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                outletOrderDetail:
                  productObject.id === null ? "" : productObject.id,
                id: productObject.id === null ? "" : productObject.id,
                productId:
                  productObject.product.id === null
                    ? ""
                    : productObject.product.id,
                name:
                  (productObject.product === null
                    ? ""
                    : productObject.product.name +
                    " (" +
                    productObject.product.shortName +
                    ") - ") +
                  (productObject.outletOrderHerader === null
                    ? ""
                    : productObject.outletOrderHerader.orderNo +
                    " - " +
                    productObject.outletOrderHerader.orderDate) +
                  " - " +
                  productObject.qty +
                  " - " +
                  (productObject.product.outletUom !== null &&
                    productObject.product.outletUom.name
                    ? productObject.product.outletUom.name
                    : ""),
                mrp:
                  productObject.mrp === null
                    ? ""
                    : (+productObject.mrp).toFixed(2),
                rate:
                  productObject.rate === null
                    ? ""
                    : (+productObject.rate).toFixed(2),
                orderQty: productObject.qty === null ? "" : productObject.qty,
                code:
                  productObject.product.code === null
                    ? ""
                    : productObject.product.code,
                cgstPer:
                  productObject.cgstPer === null ? "" : productObject.cgstPer,
                sgstPer:
                  productObject.sgstPer === null ? "" : productObject.sgstPer,
                igstPer:
                  productObject.igstPer === null ? "" : productObject.igstPer,
                categoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.name !== null
                    ? productObject.product.group.subCategory.category.name
                    : "",
                category:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory != null &&
                    productObject.product.group.subCategory.category !== null &&
                    productObject.product.group.subCategory.category.id !== null
                    ? productObject.product.group.subCategory.category.id
                    : "",
                subCategoryName:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.name
                    ? productObject.product.group.subCategory.name
                    : "",
                subCategory:
                  productObject.product !== null &&
                    productObject.product.group !== null &&
                    productObject.product.group.subCategory !== null &&
                    productObject.product.group.subCategory.id
                    ? productObject.product.group.subCategory.id
                    : "",
                stakeHolderUomName:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.name
                    ? productObject.product.stakeHolderUom.name
                    : "",
                stakeHolderUom:
                  productObject.product !== null &&
                    productObject.product.stakeHolderUom !== null &&
                    productObject.product.stakeHolderUom.id
                    ? productObject.product.stakeHolderUom.id
                    : "",
                uom:
                  productObject.product !== null &&
                    productObject.product.uom !== null
                    ? productObject.product.uom
                    : "",
                uomId: productObject.uom !== null ? productObject.uom.id : "",
                outletUomName:
                  productObject.product !== null &&
                    productObject.product.outletUom !== null &&
                    productObject.product.outletUom.name
                    ? productObject.product.outletUom.name
                    : "",

                uomConversionValue:
                  productObject.product === null &&
                    productObject.product.uomConversionValue === null
                    ? ""
                    : productObject.product.uomConversionValue,
                description:
                  productObject.product === null &&
                    productObject.product.description === null
                    ? ""
                    : productObject.product.description,
                taxName:
                  productObject.product === null &&
                    productObject.product.tax === null
                    ? ""
                    : productObject.product.tax,
                tax:
                  productObject.product === null &&
                    productObject.product.tax === null
                    ? ""
                    : productObject.product.tax,
                selfLife:
                  productObject.product === null &&
                    productObject.product.selfLife === null
                    ? ""
                    : productObject.product.selfLife,
                image:
                  productObject.product === null &&
                    productObject.product.image === null
                    ? ""
                    : productObject.product.image,
                weight:
                  productObject.product === null &&
                    productObject.product.weight === null
                    ? ""
                    : productObject.product.weight,
                shortName:
                  productObject.product === null &&
                    productObject.product.shortName === null
                    ? ""
                    : productObject.product.shortName,
                sortOrder:
                  productObject.product === null &&
                    productObject.product.sortOrder === null
                    ? ""
                    : productObject.product.sortOrder,
                isActive:
                  productObject.product === null &&
                    productObject.product.isActive === null
                    ? ""
                    : productObject.product.isActive,
                outletUom:
                  productObject.product === null &&
                    productObject.product.outletUom === null
                    ? ""
                    : productObject.product.outletUom,
              };
              return productData;
            });
            dispatch(productByCategoryAndOutletAndDateWiseSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getActiveProduct = () => async (dispatch) => {
  try {
    const responseData = apiGet({
      url: URL + "/get-active",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((productObject, index) => {
          let productData = {
            index: index + 1,
            id: productObject.id === null ? "" : productObject.id,
            name:
              productObject.name === null
                ? ""
                : productObject.name + " (" + productObject.shortName + ")",

            productName:
              productObject.name === null
                ? ""
                : productObject.name,

            code: productObject.code === null ? "" : productObject.code,
            group: productObject.group === null ? "" : productObject.group,
            categoryName:
              productObject.group.subCategory !== null &&
                productObject.group.subCategory.category !== null &&
                productObject.group.subCategory.category.name !== null
                ? productObject.group.subCategory.category.name
                : "",
            category:
              productObject.group.subCategory !== null &&
                productObject.group.subCategory.category !== null &&
                productObject.group.subCategory.category.id !== null
                ? productObject.group.subCategory.category.id
                : "",
            subCategoryName:
              productObject.group.subCategory !== null &&
                productObject.group.subCategory.name
                ? productObject.group.subCategory.name
                : "",
            subCategory:
              productObject.group.subCategory !== null &&
                productObject.group.subCategory.id
                ? productObject.group.subCategory.id
                : "",
            stakeHolderUom:
              productObject.stakeHolderUom !== null &&
                productObject.stakeHolderUom.id
                ? productObject.stakeHolderUom.id
                : "",
            stakeHolderUomName:
              productObject.stakeHolderUom !== null &&
                productObject.stakeHolderUom.name
                ? productObject.stakeHolderUom.name
                : "",
            uom:
              productObject.outletUom !== null && productObject.outletUom.id
                ? productObject.outletUom.id
                : "",
            outletUomName:
              productObject.outletUom !== null && productObject.outletUom.name
                ? productObject.outletUom.name
                : "",

            uomConversionValue:
              productObject.uomConversionValue === null
                ? ""
                : productObject.uomConversionValue,
            description:
              productObject.description === null
                ? ""
                : productObject.description,
            selfLife:
              productObject.selfLife === null ? "" : productObject.selfLife,
            image: productObject.image === null ? "" : productObject.image,
            weight: productObject.weight === null ? "" : productObject.weight,
            shortName:
              productObject.shortName === null ? "" : productObject.shortName,
            sortOrder:
              productObject.sortOrder === null ? "" : productObject.sortOrder,
            isActive:
              productObject.isActive === null ? "" : productObject.isActive,
            packagingType:
              productObject.packagingType === null
                ? ""
                : productObject.packagingType,
            tagIds: productObject.tagIds === null ? "" : productObject.tagIds,
            taxPercentage:
              productObject.taxPercentage === null
                ? ""
                : productObject.taxPercentage,
            mrp: productObject.mrp === null ? "" : productObject.mrp,
            rate: productObject.rate === null ? "" : productObject.rate,
            languageMarathi: "",
            languageHindi: "",
            languageEnglish: "",
            languageGujarati: "",
            languageMarathiName: "Marathi",
            languageHindiName: "Hindi",
            languageEnglishName: "English",
            languageGujaratiName: "Gujarati",
          };
          return productData;
        });
        dispatch(activeProductSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return responseData;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getActiveProductByCategory =
  ({ categoryId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/get-active-by-category?categoryId=" + categoryId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id: productObject.id === null ? "" : productObject.id,
                name:
                  productObject === null
                    ? ""
                    : productObject.name + " (" + productObject.shortName + ")",
                code: productObject.code === null ? "" : productObject.code,
                group: productObject.group === null ? "" : productObject.group.id,
                categoryName:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.category !== null &&
                    productObject.group.subCategory.category.name !== null
                    ? productObject.group.subCategory.category.name
                    : "",
                category:
                  productObject !== null &&
                    productObject.group.subCategory != null &&
                    productObject.group.subCategory.category !== null &&
                    productObject.group.subCategory.category.id !== null
                    ? productObject.group.subCategory.category.id
                    : "",
                subCategoryName:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.name
                    ? productObject.group.subCategory.name
                    : "",
                subCategory:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.id
                    ? productObject.group.subCategory.id
                    : "",
                stakeHolderUom:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.id
                    ? productObject.stakeHolderUom.id
                    : "",
                stakeHolderUomName:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.name
                    ? productObject.stakeHolderUom.name
                    : "",
                uom:
                  productObject !== null &&
                    productObject.outletUom !== null &&
                    productObject.outletUom.id
                    ? productObject.outletUom.id
                    : "",
                outletUomName:
                  productObject !== null &&
                    productObject.outletUom !== null &&
                    productObject.outletUom.name
                    ? productObject.outletUom.name
                    : "",

                uomConversionValue:
                  productObject === null &&
                    productObject.uomConversionValue === null
                    ? ""
                    : productObject.uomConversionValue,
                description:
                  productObject === null && productObject.description === null
                    ? ""
                    : productObject.description,
                taxName:
                  productObject === null && productObject.tax === null
                    ? ""
                    : productObject.tax,
                tax:
                  productObject === null && productObject.tax === null
                    ? ""
                    : productObject.tax,
                selfLife:
                  productObject === null && productObject.selfLife === null
                    ? ""
                    : productObject.selfLife,
                image:
                  productObject === null && productObject.image === null
                    ? ""
                    : productObject.image,
                weight:
                  productObject === null && productObject.weight === null
                    ? ""
                    : productObject.weight,
                shortName:
                  productObject === null && productObject.shortName === null
                    ? ""
                    : productObject.shortName,
                sortOrder:
                  productObject === null && productObject.sortOrder === null
                    ? ""
                    : productObject.sortOrder,
                isActive:
                  productObject === null && productObject.isActive === null
                    ? ""
                    : productObject.isActive,
                packagingType:
                  productObject.packagingType === null
                    ? ""
                    : productObject.packagingType,
                tagIds: productObject.tagIds === null ? "" : productObject.tagIds,
                taxPercentage:
                  productObject.taxPercentage === null
                    ? ""
                    : productObject.taxPercentage,
                mrp: productObject.mrp === null ? "" : productObject.mrp,
              };
              return productData;
            });
            dispatch(activeProductByCategorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getActiveProductByCategoryForAll =
  ({ categoryIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-active-by-categories",
          postBody: categoryIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id: productObject.id === null ? "" : productObject.id,
                name:
                  productObject === null
                    ? ""
                    : productObject.name + " (" + productObject.shortName + ")",
                code: productObject.code === null ? "" : productObject.code,
                group: productObject.group === null ? "" : productObject.group.id,
                categoryName:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.category !== null &&
                    productObject.group.subCategory.category.name !== null
                    ? productObject.group.subCategory.category.name
                    : "",
                category:
                  productObject !== null &&
                    productObject.group.subCategory != null &&
                    productObject.group.subCategory.category !== null &&
                    productObject.group.subCategory.category.id !== null
                    ? productObject.group.subCategory.category.id
                    : "",
                subCategoryName:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.name
                    ? productObject.group.subCategory.name
                    : "",
                subCategory:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.id
                    ? productObject.group.subCategory.id
                    : "",
                stakeHolderUom:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.id
                    ? productObject.stakeHolderUom.id
                    : "",
                stakeHolderUomName:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.name
                    ? productObject.stakeHolderUom.name
                    : "",
                uom:
                  productObject !== null &&
                    productObject.outletUom !== null &&
                    productObject.outletUom.id
                    ? productObject.outletUom.id
                    : "",
                outletUomName:
                  productObject !== null &&
                    productObject.outletUom !== null &&
                    productObject.outletUom.name
                    ? productObject.outletUom.name
                    : "",

                uomConversionValue:
                  productObject === null &&
                    productObject.uomConversionValue === null
                    ? ""
                    : productObject.uomConversionValue,
                description:
                  productObject === null && productObject.description === null
                    ? ""
                    : productObject.description,
                taxName:
                  productObject === null && productObject.tax === null
                    ? ""
                    : productObject.tax,
                tax:
                  productObject === null && productObject.tax === null
                    ? ""
                    : productObject.tax,
                selfLife:
                  productObject === null && productObject.selfLife === null
                    ? ""
                    : productObject.selfLife,
                image:
                  productObject === null && productObject.image === null
                    ? ""
                    : productObject.image,
                weight:
                  productObject === null && productObject.weight === null
                    ? ""
                    : productObject.weight,
                shortName:
                  productObject === null && productObject.shortName === null
                    ? ""
                    : productObject.shortName,
                sortOrder:
                  productObject === null && productObject.sortOrder === null
                    ? ""
                    : productObject.sortOrder,
                isActive:
                  productObject === null && productObject.isActive === null
                    ? ""
                    : productObject.isActive,
                packagingType:
                  productObject.packagingType === null
                    ? ""
                    : productObject.packagingType,
                tagIds: productObject.tagIds === null ? "" : productObject.tagIds,
                taxPercentage:
                  productObject.taxPercentage === null
                    ? ""
                    : productObject.taxPercentage,
                mrp: productObject.mrp === null ? "" : productObject.mrp,
              };
              return productData;
            });
            dispatch(activeProductByCategorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getActiveProductByCategoryForAllPost =
  ({ categoryIds }) =>
    async (dispatch) => {
      let postBodyToSave = {
        categoryIds: categoryIds,
      };
      try {
        const response = apiPost({
          url: URL + "/get-active-by-categories",
          postBody: postBodyToSave,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id: productObject.id === null ? "" : productObject.id,
                name:
                  productObject === null
                    ? ""
                    : productObject.name + " (" + productObject.shortName + ")",
                code: productObject.code === null ? "" : productObject.code,
                group: productObject.group === null ? "" : productObject.group.id,
                categoryName:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.category !== null &&
                    productObject.group.subCategory.category.name !== null
                    ? productObject.group.subCategory.category.name
                    : "",
                category:
                  productObject !== null &&
                    productObject.group.subCategory != null &&
                    productObject.group.subCategory.category !== null &&
                    productObject.group.subCategory.category.id !== null
                    ? productObject.group.subCategory.category.id
                    : "",
                subCategoryName:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.name
                    ? productObject.group.subCategory.name
                    : "",
                subCategory:
                  productObject !== null &&
                    productObject.group.subCategory !== null &&
                    productObject.group.subCategory.id
                    ? productObject.group.subCategory.id
                    : "",
                stakeHolderUom:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.id
                    ? productObject.stakeHolderUom.id
                    : "",
                stakeHolderUomName:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.name
                    ? productObject.stakeHolderUom.name
                    : "",
                uom:
                  productObject !== null &&
                    productObject.outletUom !== null &&
                    productObject.outletUom.id
                    ? productObject.outletUom.id
                    : "",
                outletUomName:
                  productObject !== null &&
                    productObject.outletUom !== null &&
                    productObject.outletUom.name
                    ? productObject.outletUom.name
                    : "",

                uomConversionValue:
                  productObject === null &&
                    productObject.uomConversionValue === null
                    ? ""
                    : productObject.uomConversionValue,
                description:
                  productObject === null && productObject.description === null
                    ? ""
                    : productObject.description,
                taxName:
                  productObject === null && productObject.tax === null
                    ? ""
                    : productObject.tax,
                tax:
                  productObject === null && productObject.tax === null
                    ? ""
                    : productObject.tax,
                selfLife:
                  productObject === null && productObject.selfLife === null
                    ? ""
                    : productObject.selfLife,
                image:
                  productObject === null && productObject.image === null
                    ? ""
                    : productObject.image,
                weight:
                  productObject === null && productObject.weight === null
                    ? ""
                    : productObject.weight,
                shortName:
                  productObject === null && productObject.shortName === null
                    ? ""
                    : productObject.shortName,
                sortOrder:
                  productObject === null && productObject.sortOrder === null
                    ? ""
                    : productObject.sortOrder,
                isActive:
                  productObject === null && productObject.isActive === null
                    ? ""
                    : productObject.isActive,
                packagingType:
                  productObject.packagingType === null
                    ? ""
                    : productObject.packagingType,
                tagIds: productObject.tagIds === null ? "" : productObject.tagIds,
                taxPercentage:
                  productObject.taxPercentage === null
                    ? ""
                    : productObject.taxPercentage,
                mrp: productObject.mrp === null ? "" : productObject.mrp,
              };
              return productData;
            });
            dispatch(activeProductByCategorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getChangeActiveProduct =
  ({ row }) =>
    async (dispatch) => {
      dispatch(activeProductSuccess({ row }));
    };
export const getAllActiveProduct = () => async (dispatch) => {
  try {
    const responseData = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((productObject, index) => {
          let productData = {
            index: index + 1,
            id: productObject.id === null ? "" : productObject.id,
            name: productObject.name === null ? "" : productObject.name,
            code: productObject.code === null ? "" : productObject.code,
            stakeHolderUomName:
              productObject.stakeHolderUom !== null &&
                productObject.stakeHolderUom.name
                ? productObject.stakeHolderUom.name
                : "",
            stakeHolderUom:
              productObject.stakeHolderUom !== null &&
                productObject.stakeHolderUom.id
                ? productObject.stakeHolderUom
                : "",
            outletUomName:
              productObject.outletUom !== null && productObject.outletUom.name
                ? productObject.outletUom.name
                : "",
            outletUom:
              productObject.outletUom !== null && productObject.outletUom.id
                ? productObject.outletUom
                : "",
            group: productObject.group === null ? "" : productObject.group,
            packagingType:
              productObject.packagingType === null
                ? ""
                : productObject.packagingType,
            tagIds: productObject.tagIds === null ? "" : productObject.tagIds,
            taxPercentage:
              productObject.taxPercentage === null
                ? ""
                : productObject.taxPercentage,
            uomConversionValue:
              productObject.uomConversionValue === null
                ? ""
                : productObject.uomConversionValue,
            description:
              productObject.description === null
                ? ""
                : productObject.description,
            selfLife:
              productObject.selfLife === null ? "" : productObject.selfLife,
            image: productObject.image === null ? "" : productObject.image,
            weight: productObject.weight === null ? "" : productObject.weight,
            shortName:
              productObject.shortName === null ? "" : productObject.shortName,
            sortOrder:
              productObject.sortOrder === null ? "" : productObject.sortOrder,
            isActive:
              productObject.isActive === null ? "" : productObject.isActive,
            mrp: productObject.mrp === null ? "" : productObject.mrp,
          };
          return productData;
        });
        dispatch(activeAllProductSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return responseData;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getProductByAssignedRateStructure = () => async (dispatch) => {
  try {
    const responseData = apiGet({
      url: URL + "/by-product-structure-assign",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((productObject, index) => {
          let productData = {
            index: index + 1,
            id: productObject.id === null ? "" : productObject.id,
            name: productObject.name === null ? "" : productObject.name,
            code: productObject.code === null ? "" : productObject.code,
            stakeHolderUomName:
              productObject.stakeHolderUomName !== null
                ? productObject.stakeHolderUomName
                : "",
            stakeHolderUom:
              productObject.stakeHolderUomId !== null
                ? productObject.stakeHolderUomId
                : "",
            outletUomName:
              productObject.outletUomName !== null
                ? productObject.outletUomName
                : "",
            outletUom:
              productObject.outletUomId !== null ? productObject.outletUomId
                : "",
            group: productObject.groupId === null ? "" : productObject.groupId,
            packagingType:
              productObject.packagingTypeName === null
                ? ""
                : productObject.packagingTypeName,
            tagIds: productObject.tagIds === null ? "" : productObject.tagIds,
            taxPercentage:
              productObject.taxPercentageName === null
                ? ""
                : productObject.taxPercentageName,
            uomConversionValue:
              productObject.uomConversionValue === null
                ? ""
                : productObject.uomConversionValue,
            description:
              productObject.description === null
                ? ""
                : productObject.description,
            selfLife:
              productObject.selfLife === null ? "" : productObject.selfLife,
            image: productObject.image === null ? "" : productObject.image,
            weight: productObject.weight === null ? "" : productObject.weight,
            shortName:
              productObject.shortName === null ? "" : productObject.shortName,
            sortOrder:
              productObject.sortOrder === null ? "" : productObject.sortOrder,
            isActive:
              productObject.isActive === null ? "" : productObject.isActive,
            mrp: productObject.mrp === null ? "" : productObject.mrp,
          };
          return productData;
        });
        dispatch(activeAllProductSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return responseData;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setAllProductList =
  ({ row }) =>
    async (dispatch) => {
      dispatch(activeAllProductSuccess({ row }));
    };

export const getAllActiveProductForAssignation = () => async (dispatch) => {
  try {
    const responseData = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        dispatch(activeAllProductSuccess({ row: data.data }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return responseData;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getProductBySubCategory =
  ({ subCategoryId }) =>
    async (dispatch) => {
      try {
        const responseData = apiGet({
          url: URL + "/by-sub-category?subCategoryId=" + subCategoryId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id: productObject.id === null ? "" : productObject.id,
                name: productObject.name === null ? "" : productObject.name,
                code: productObject.code === null ? "" : productObject.code,
                stakeHolderUomName:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.name
                    ? productObject.stakeHolderUom.name
                    : "",
                stakeHolderUom:
                  productObject.stakeHolderUom !== null &&
                    productObject.stakeHolderUom.id
                    ? productObject.stakeHolderUom
                    : "",
                outletUomName:
                  productObject.outletUom !== null && productObject.outletUom.name
                    ? productObject.outletUom.name
                    : "",
                outletUom:
                  productObject.outletUom !== null && productObject.outletUom.id
                    ? productObject.outletUom
                    : "",
                group: productObject.group === null ? "" : productObject.group,
                packagingType:
                  productObject.packagingType === null
                    ? ""
                    : productObject.packagingType,
                tagIds: productObject.tagIds === null ? "" : productObject.tagIds,
                taxPercentage:
                  productObject.taxPercentage === null
                    ? ""
                    : productObject.taxPercentage,
                uomConversionValue:
                  productObject.uomConversionValue === null
                    ? ""
                    : productObject.uomConversionValue,
                rate: productObject.rate === null
                  ? ""
                  : productObject.rate,
                description:
                  productObject.description === null
                    ? ""
                    : productObject.description,
                selfLife:
                  productObject.selfLife === null ? "" : productObject.selfLife,
                image: productObject.image === null ? "" : productObject.image,
                weight: productObject.weight === null ? "" : productObject.weight,
                shortName:
                  productObject.shortName === null ? "" : productObject.shortName,
                sortOrder:
                  productObject.sortOrder === null ? "" : productObject.sortOrder,
                isActive:
                  productObject.isActive === null ? "" : productObject.isActive,
                mrp: productObject.mrp === null ? "" : productObject.mrp,
              };
              return productData;
            });
            dispatch(activeAllProductSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return responseData;
      } catch (e) {
        return console.error(e.message);
      }
    };




export const getProductBySubCategoryAndProductStructureAssign =
  ({ subCategoryIds }) =>
    async (dispatch) => {
      try {
        const responseData = apiGet({
          url: URL + "/by-sub-categories-and-product-structure-assign?subCategoryIds=" + subCategoryIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((productObject, index) => {
              let productData = {
                index: index + 1,
                id: productObject.id === null ? "" : productObject.id,
                groupId: productObject.groupId === null ? "" : productObject.groupId,
                categoryId: productObject.categoryId === null ? "" : productObject.categoryId,
                categoryName: productObject.categoryName === null ? "" : productObject.categoryName,
                subCategoryId: productObject.subCategoryId === null ? "" : productObject.subCategoryId,
                subCategoryName: productObject.subCategoryName === null ? "" : productObject.subCategoryName,
                packagingTypeId: productObject.packagingTypeId === null ? "" : productObject.packagingTypeId,
                packagingTypeName: productObject.packagingTypeName === null ? "" : productObject.packagingTypeName,
                tagIds: productObject.tagIds === null ? "" : productObject.tagIds,
                taxPercentageId: productObject.taxPercentageId === null ? "" : productObject.taxPercentageId,
                taxPercentageName: productObject.taxPercentageName === null ? "" : productObject.taxPercentageName,
                sgst: productObject.sgst === null ? "" : productObject.sgst,
                cgst: productObject.cgst === null ? "" : productObject.cgst,
                igst: productObject.igst === null ? "" : productObject.igst,
                hsnCode: productObject.hsnCode === null ? "" : productObject.hsnCode,
                stakeHolderUomId: productObject.stakeHolderUomId === null ? "" : productObject.stakeHolderUomId,
                stakeHolderUomName: productObject.stakeHolderUomName === null ? "" : productObject.stakeHolderUomName,
                outletUomId: productObject.outletUomId === null ? "" : productObject.outletUomId,
                outletUomName: productObject.outletUomName === null ? "" : productObject.outletUomName,
                uomConversionValue: productObject.uomConversionValue === null ? "" : productObject.uomConversionValue,
                code: productObject.code === null ? "" : productObject.code,
                productCode: productObject.productCode === null ? "" : productObject.productCode,
                name: productObject.name === null ? "" : productObject.name,
                shortName: productObject.shortName === null ? "" : productObject.shortName,
                weight: productObject.weight === null ? "" : productObject.weight,
                description: productObject.description === null ? "" : productObject.description,
                selfLife: productObject.selfLife === null ? "" : productObject.selfLife,
                image: productObject.image === null ? "" : productObject.image,
                sortOrder: productObject.sortOrder === null ? "" : productObject.sortOrder,
                isActive: productObject.isActive === null ? "" : productObject.isActive,
                mrp: productObject.mrp === null ? "" : productObject.mrp,
                outletRate: productObject.outletRate === null ? "" : productObject.outletRate,
                rate: productObject.rate === null ? "" : productObject.rate,
                value: productObject.value === null ? "" : productObject.value,
                qty: productObject.qty === null ? "" : productObject.qty,
                insertDateTime: productObject.insertDateTime === null ? "" : productObject.insertDateTime,
              };
              return productData;
            });
            dispatch(activeAllProductSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return responseData;
      } catch (e) {
        return console.error(e.message);
      }
    };
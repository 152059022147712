import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadF } from "../../Pages/PDF/PDFDownload";
import { LabelCompo } from "../Comman/Label";
import { serverMsg } from "../../config/messageconstant";

import { generateExcel } from "../Comman/GenerateExcel";
import { Loading1 } from "../Loading1";
import { generatePDF } from "../Comman/GeneratePDF";
import LandingScreenHeader from "../Comman/NewLandingScreenHeader";
import DynamicForm from "./DynamicReportForm";
import ReportTable from "../Comman/RT/ReportTable";
import { labelColor } from "../../config/ColorObj";
import { showNotification, showLoador } from "../../Pages/Landing/Landing.slice";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      formErrors: {},
      fieldDataForModule: {},
    };
  }

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "reportName",
      title: "Report Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];



  componentDidMount() {
    const moduleObjData = localStorage.getItem("moduleObj");

    this.setState({
      fieldDataForModule:
        moduleObjData != null ? JSON.parse(moduleObjData) : {},
    });

  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    const { fieldMeta } = this.props;
    fieldMeta.map((currentField) => {
      if (currentField.defaultValue) {
        fieldData[currentField.dataKey] = currentField.defaultValue;
      }
    });

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };
  checkValidationOnSubmit = (dataToSave, filter, tableRowData) => {
    if (this.mandatoryCheck(dataToSave, filter)) {
      if (this.onSubmitCheck(dataToSave, filter)) {
        if (this.props.maxDateValid) {
          return this.onDateValidation(dataToSave, filter, tableRowData);
        }
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  onDateValidation = (dataToSave, filter, tableRowData) => {
    let formValid = true;
    const { fieldMeta } = this.props;
    let formErrors = {};
    const dataList = filter.split(",");
    fieldMeta.forEach((currentField) => {
      const compareWithFieldLabel = dataList.filter(
        (item) => item === currentField.dataKey
      );
      if (
        compareWithFieldLabel.length !== 0 &&
        currentField.maxDatekey && dataToSave[currentField.maxDatekey] && dataToSave[currentField.dataKey]
     &&tableRowData.days
      ) {
        let isError = false;
        let condErrorText = "";
        let dataKeyValue = ''
        let compareWithValue = ''
        let fromDate = dataToSave[currentField.maxDatekey]
        let toDate = dataToSave[currentField.dataKey]

        if (typeof fromDate === "string") {

          dataKeyValue = new Date(fromDate.split('-').reverse().join('-'))
          dataKeyValue.setDate(dataKeyValue.getDate() + tableRowData.days);
        }
        if (typeof toDate === "string") {

          compareWithValue = new Date(toDate.split('-').reverse().join('-'))
        }

        if (!(dataKeyValue >= compareWithValue)) {
          isError = true;
          condErrorText = "less than equal to";
        }

        if (isError) {

          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `Kindly select date range between 1 - ${tableRowData.days} days`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  onSubmitCheck = (dataToSave, filter) => {
    let formValid = true;
    const { fieldMeta } = this.props;
    let formErrors = {};
    const dataList = filter.split(",");
    fieldMeta.forEach((currentField) => {
      const compareWithFieldLabel = dataList.filter(
        (item) => item === currentField.dataKey
      );
      if (
        compareWithFieldLabel.length !== 0 &&
        currentField.onSubmit === "compare"
      ) {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = dataToSave[currentField.dataKey];
          let compareWithValue = dataToSave[compareWith];
          let fromDate = dataToSave[currentField.dataKey];
          let toDate = dataToSave[compareWith];

          if (typeof fromDate === "string") {

            dataKeyValue = new Date(fromDate.split('-').reverse().join('-'))
          }
          if (typeof toDate === "string") {

            compareWithValue = new Date(toDate.split('-').reverse().join('-'))
          }
          switch (compareCond) {
            case "l":
              {
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }
              }
              break;
            case "le":
              {
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }
              }
              break;
            case "g":
              {
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }
              }
              break;
            case "ge":
              {
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }
              }
              break;
            case "eq":
              {
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }
              }
              break;
          }

          if (isError) {
            const compareWithFieldLabel = fieldMeta.filter(
              (item) => item.dataKey === compareWith
            )[0].label;
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
              },
            };
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck = (dataToSave, filter) => {
    let formValid = true;
    const { fieldMeta } = this.props;
    let formErrors = {};
    const dataList = filter.split(",");
    fieldMeta.forEach((currentField) => {
      const compareWithFieldLabel = dataList.filter(
        (item) => item === currentField.dataKey
      );
      if (compareWithFieldLabel.length !== 0 && currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (dataToSave[currentField.dataKey] === undefined ||
              dataToSave[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            (dataToSave[currentField.dataKey] === undefined ||
              dataToSave[currentField.dataKey] === null ||
              dataToSave[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  PDFDownload = (tableRowData) => {
    const { fieldData } = this.state;
    const { fieldMeta } = this.props;
    const dataToSave = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        if (fieldData[currentField.dataKey]) {
          let date = new Date(fieldData[currentField.dataKey]);

          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          fieldData[currentField.dataKey] = year + "-" + month + "-" + day;
        }

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    this.onSave(dataToSave, tableRowData.filter, tableRowData.url, 1, tableRowData);
  };

  ExcelDownload = (tableRowData) => {
    const { fieldData } = this.state;
    const { fieldMeta } = this.props;
    const dataToSave = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        if (fieldData[currentField.dataKey]) {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].format("DD-MM-YYYY")
            : "";
        } else {
          dataToSave[currentField.dataKey] = "";
        }
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    if (tableRowData.excelUrl) {
      this.onSave(dataToSave, tableRowData.filter, tableRowData.excelUrl, 0, tableRowData);
    } else {
      this.onSave(dataToSave, tableRowData.filter, tableRowData.url, 0, tableRowData);
    }

  };

  onSave = (dataToSave, filter, apiBaseURL, documentType, tableRowData) => {
    console.log(tableRowData)
    const { getExcel } = this.props;
    if (this.checkValidationOnSubmit(dataToSave, filter, tableRowData)) {
      if (tableRowData.isStatic) {
        getExcel(dataToSave, tableRowData)
      } else {
        const { openNewTab = true } = this.props;
        let url = "";
        this.setState({
          isLoading: true,
        });
        const dataList = apiBaseURL.split("?");
        if (dataList !== 0) {
          dataList.map((urlData, index) => {
            if (index === 0) {
              const parameter = urlData.split(/[{,}]/);

              if (parameter.length === 3) {
                url =
                  url + parameter[0] + dataToSave[parameter[1]] + parameter[2];
              } else {
                url = url + urlData;
              }
              if (dataList.length - 1 !== index) {
                url = url + "?";
              }
            } else {
              const varList = urlData.split("&");
              varList.map((variableData, varIndex) => {
                const parameter = variableData.split(/[{,}]/);
                if (parameter.length === 3) {
                  url =
                    url + parameter[0] + dataToSave[parameter[1]] + parameter[2];
                } else if (parameter.length === 0) {
                  url = url + urlData;
                } else if (parameter.length === 1) {
                  url = url + parameter[0];
                }
                if (dataList.length - 1 !== index) {
                  url = url + "?";
                }
                if (varList.length - 1 !== varIndex) {
                  url = url + "&";
                }
              });
            }
          });

          if (dataList.length === 2) {
            url = url + "&documentType=" + documentType;
          } else {
            url = url + "?documentType=" + documentType;
          }
          downloadF({
            url: url,
            ext: documentType == 0 ? "xls" : "pdf",
            openNewTab: openNewTab,
          }).then((response) => {
            if (!response) {
              this.props.showNotification({
                msg: serverMsg,
                severity: "error",
              });
              this.setState({
                isLoading: false,
              });
            } else {
              this.setState({
                isLoading: false,
              });
            }
          });
          console.log(url);
        }
      }
    }
  };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    tableColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };
  resetData = () => {
    this.setState({
      fieldData: {},
    });
  };
  render() {
    const {
      screenTitle = "",
      fieldMeta,
      rowData = [],
      dynamicMasterData = {},
      PDFButton,
      buttonCenter,
      EXCELButton,
      marginTop,
      searchButton,
      condition = [],
      showHeadExcel = true,
    } = this.props;
    const { fieldData, formErrors, showTable, isLoading, fieldDataForModule } = this.state;

    return (
      <>
        {/* <Alert msg={"hi"} /> */}
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={screenTitle}
            showPdfDownload={false}
            generatePDF={this.generatePDF}
            showExcelDownload={false}
            generateExcel={this.generateExcel}
            showAddButton={false}
            onAddButtonClick={this.props.onAddButtonClick}
            showTable={showTable}
          />
          <br />
          <DynamicForm
            fieldMeta={fieldMeta}
            dynamicMasterData={dynamicMasterData}
            fieldData={fieldData}
            formErrors={formErrors}
            onDataChange={this.onDataChange}
            buttonCenter={buttonCenter}
            searchButton={searchButton}
            PDFButton={PDFButton}
            EXCELButton={EXCELButton}
            marginTop={marginTop}
            resetData={this.resetData}
          />
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 14, color: labelColor }}
              label="Note: Choose the particular filters mentioned in order to generate the report."
            />
          </Grid>

          <ReportTable
            data={rowData}
            tableHead={this.columns}
            showPegination={false}
            showHeadPdf={false}
            showHeadExcel={fieldDataForModule.editReject == 1 && showHeadExcel}
            pdfDetailsId={this.PDFDownload}
            excelDetailsId={this.ExcelDownload}
            condition={condition}
          />
        </Paper>
        {isLoading && <Loading1 />}
        {/* {!showLoader && <Loading1 />} */}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = { downloadF, showNotification, showLoador };
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
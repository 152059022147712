import endpoint from "../../config/endpoints";

export const ExcelReportsJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.area,
  screenTitle: "Reports",
  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
      format: "DD-MM-YYYY",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
      format: "DD-MM-YYYY",
      maxDatekey: "fromDate",
    },

    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      getListFrom: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Category",
      controlType: "multiSelect",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "multiSelect",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderTypeId",
      getListId: "stockStakeHolderTypeId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stockStakeHolder",
      getListFrom: "stockStakeHolderTypeId,area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name (Multiple)",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stockStakeHolderIds",
      getListFrom: "stockStakeHolderTypeId,area",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMetaForDistributor: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
      format: "DD-MM-YYYY",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
      format: "DD-MM-YYYY",
      maxDatekey: "fromDate",
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetailForDealer",
      dataKey: "area",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      getListFrom: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Category",
      controlType: "multiSelect",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "multiSelect",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderTypeId",
      getListId: "stockStakeHolderTypeId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stockStakeHolder",
      getListFrom: "stockStakeHolderTypeId,area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Sales Person",
      controlType: "multiSelect",
      placeHolder: "Select Sales Person",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "salesPersonDetail",
      dataKey: "salesPerson",
      getListFrom: "area",
      isRootLevelKey: true,
      isMandatory: true,
      
    },
  ],

  fieldMetaForSundar: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
      format: "DD-MM-YYYY",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
      format: "DD-MM-YYYY",
      maxDatekey: "fromDate",
      days: 7,
    },

    {
      label: "Month",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "month",
      isMandatory: true,
      format: "MM-YYYY",
    },

    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      getListFrom: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Category",
      controlType: "multiSelect",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "multiSelect",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Other Task",
      controlType: "multiSelect",
      placeHolder: "Select Other Task",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "otherTaskDetail",
      dataKey: "otherTaskIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderTypeId",
      getListId: "stockStakeHolderTypeId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stockStakeHolder",
      getListFrom: "stockStakeHolderTypeId,area",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-tabs-container {
  position: sticky; /* Use sticky to keep the tabs in view while scrolling */
  top: 10%; /* Keeps it at the top of the container or viewport */
  width: 100%;
  background-color: transparent; /* Ensures background visibility */
  z-index: 10; /* Keeps it above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0; /* Add some padding to prevent overlap */
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab {
  background-color: #939393;
  color: white;
  padding: 15px 30px; /* Rectangular shape */
  margin: 0 20px;
  border-radius: 5px; /* Slightly rounded corners */
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0.04, 0.33, 0.4);
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tab:hover {
  background-color: #5faaf5;
  transform: scale(1.1);
}

.tab.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  transform: scale(1.1); /* Add scaling effect on active state */
}

.tab-content {
  padding-top: 200px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Comman Dashboard/FloatingTabs.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,wDAAwD;EAC1E,QAAQ,EAAE,qDAAqD;EAC/D,WAAW;EACX,6BAA6B,EAAE,kCAAkC;EACjE,WAAW,EAAE,kCAAkC;EAC/C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,eAAe,EAAE,wCAAwC;AAC3D;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB,EAAE,sBAAsB;EAC1C,cAAc;EACd,kBAAkB,EAAE,6BAA6B;EACjD,eAAe;EACf,uDAAuD;EACvD,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,2CAA2C;EAC3C,qBAAqB,EAAE,uCAAuC;AAChE;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".floating-tabs-container {\n  position: sticky; /* Use sticky to keep the tabs in view while scrolling */\n  top: 10%; /* Keeps it at the top of the container or viewport */\n  width: 100%;\n  background-color: transparent; /* Ensures background visibility */\n  z-index: 10; /* Keeps it above other elements */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding: 20px 0; /* Add some padding to prevent overlap */\n}\n\n.tabs {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.tab {\n  background-color: #939393;\n  color: white;\n  padding: 15px 30px; /* Rectangular shape */\n  margin: 0 20px;\n  border-radius: 5px; /* Slightly rounded corners */\n  cursor: pointer;\n  transition: all 0.2s cubic-bezier(0.4, 0.04, 0.33, 0.4);\n  text-align: center;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.tab:hover {\n  background-color: #5faaf5;\n  transform: scale(1.1);\n}\n\n.tab.active {\n  background-color: #007bff;\n  color: white;\n  font-weight: bold;\n  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);\n  transform: scale(1.1); /* Add scaling effect on active state */\n}\n\n.tab-content {\n  padding-top: 200px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from "react";

import GoogleMapReact from "google-map-react";

import styled from "styled-components";
import "./maincss.css";
import { connect } from "react-redux";
import AutoComplete from "./Autocomplete";
import Marker from "./Marker";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";

const Wrapper = styled.main`
  width: 75%;
  height: 70%;
`;

class GoogleMapForAreaExploration extends Component {
    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 9,
        address: "",
        draggable: true,
        lat: null,
        lng: null,
    };

    componentWillMount() {
        const { setLocation } = this.props;
        if (setLocation.latitude != "" && setLocation.longitude != "") {
            this.setState({
                center: [+setLocation.latitude, +setLocation.longitude],
                lat: +setLocation.latitude,
                lng: +setLocation.longitude,
            });
        } else {
            this.setCurrentLocation();
        }
    }

    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng,
        });
    };
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        this._generateAddress();
    };

    _onChange = ({ center, zoom }) => {
        this.setState({
            center: center,
            zoom: zoom,
        });
    };

    _onClick = (value) => {
        this.setState({
            lat: value.lat,
            lng: value.lng,
        });
        this._generateAddress();
    };

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

        this._generateAddress();
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        });
        this._generateAddress();
    };

    _generateAddress() {
        const { mapApi } = this.state;

        const geocoder = new mapApi.Geocoder();

        geocoder.geocode(
            { location: { lat: this.state.lat, lng: this.state.lng } },
            (results, status) => {
                console.log(results);
                console.log(status);
                if (status === "OK") {
                    if (results[0]) {
                        this.zoom = 12;
                        this.setState({ address: results[0].formatted_address });
                    } else {
                        window.alert("No results found");
                    }
                } else {
                    window.alert("Geocoder failed due to: " + status);
                }
            }
        );
    }

    // Get Current Location Coordinates
    setCurrentLocation() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            });
        }
    }
    getLocationData = () => {
        this.props.getLocation(this.state.lat, this.state.lng);
    };
    render() {
        const { markPoint, mapApiLoaded, mapInstance, mapApi } = this.state;

        return (
            <div className="main-wrapper">
                <Wrapper>
                    {mapApiLoaded && (
                        <div>
                            <AutoComplete
                                map={mapInstance}
                                mapApi={mapApi}
                                addplace={this.addPlace}
                            />
                        </div>
                    )}
                    <GoogleMapReact
                        center={this.state.center}
                        zoom={this.state.zoom}
                        draggable={this.state.draggable}
                        onChange={this._onChange}
                        onChildMouseDown={this.onMarkerInteraction}
                        onChildMouseUp={this.onMarkerInteractionMouseUp}
                        onChildMouseMove={this.onMarkerInteraction}
                        onChildClick={() => console.log("child click")}
                        onClick={this._onClick}
                        bootstrapURLKeys={{
                            key: "AIzaSyDvKXkZ-S03qxR-8b8HLnCOKr36eYRxC2k",
                            libraries: ["places", "geometry"],
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                    >

                        <Marker
                            pinColor="green"

                            text={this.state.address}
                            lat={this.state.lat}
                            lng={this.state.lng}
                        />
                    </GoogleMapReact>

                    <div className="info-wrapper">
                        <div className="map-details">
                            Latitude: <span>{this.state.lat}</span>, Longitude:{" "}
                            <span>{this.state.lng}</span>
                        </div>
                        <div className="map-details">
                            Zoom: <span>{this.state.zoom}</span>
                        </div>
                        <div className="map-details">
                            Address: <span>{this.state.address}</span>
                        </div>
                    </div>

                    <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Get Location"
                        fullWidth={true}
                        onClick={this.getLocationData}
                    />
                </Wrapper>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(GoogleMapForAreaExploration);

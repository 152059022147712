import React, { useEffect, useState } from "react";
import { Button, Grid, InputAdornment, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { apiPost } from "../../utils/api_service";
import { noInternetMsg, savemsg, headerMsg, serverMsg, saveWarningMsg, addOneRecordMsg, addAtleastOneMomMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import { Add } from "@mui/icons-material";
import swal from "sweetalert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose && (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        )}
    </DialogTitle>
);

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
};

const AddMomPopUp = ({ showNotification, route, open, dataRow, showLoador, handleCloses, apiFetchAgainMethodMain }) => {
    const [header, setHeader] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [detailCommentList, setDetailCommentList] = useState([
        {
            index: 1,
            details: "",
            commentList: [
                {
                    index: 1,
                    headerIndex: 1,
                    comment: "",
                },
            ],
        },
    ]);
    const [detailCommentListObj, setDetailCommentListObj] = useState(detailCommentList[0]);

    useEffect(() => {
        if (navigator.onLine) {
            setDetailCommentListObj(detailCommentList[0]);
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }, []);

    const handleClose = () => {
        handleCloses();
    };



    const checkValidation = () => {
        let formIsValid = true;
        let errors = {};

        if (header === "") {
            formIsValid = false;
            errors["headerError"] = headerMsg;
        }
        setFormErrors(errors);
        return formIsValid;
    };

    const onSaveDataNew = async () => {

        const saveList = detailCommentList.map((dataObj) => {
            if (dataObj.details !== "") {
                return {
                    meetingDescription: dataObj.details,
                    minutesOfMeetingDetailsComments: dataObj.commentList.filter(row => row.comment !== "")
                        .map((row) => ({
                            comments: row.comment,
                        })),
                };
            }
            return null;
        }).filter(Boolean);

        console.log(saveList);

        const saveJson = {
            distributorInquiry: {
                id: dataRow.id,
            },
            meetingPurpose: header,
            minutesOfMeetingDetails: saveList,
        };

        if (checkValidation()) {
            if (saveList.length !== 0) {
                swal({
                    title: "Are you sure?",
                    text: saveWarningMsg,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        if (navigator.onLine) {
                            onSave(saveJson);
                        } else {
                            showNotification({ msg: noInternetMsg, severity: "error" });
                        }
                    }
                });
            } else {
                showNotification({ msg: addAtleastOneMomMsg, severity: "error" });
            }
        }
    };

    const onSave = async (saveJson) => {

        showLoador({ loador: true });
        apiPost({
            url: endpoint.minutesOfMeetingHeader + "/save",
            postBody: saveJson,
        }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
                showNotification({ msg: savemsg });
                apiFetchAgainMethodMain();
                handleClose();
            } else {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });
    };

    const onPressAdd = () => {
        const newIndex = detailCommentList.length + 1;

        const newDetail = {
            index: newIndex,
            details: "",
            commentList: [
                {
                    index: 1,
                    headerIndex: newIndex,
                    comment: "",
                },
            ],
        };

        setDetailCommentList((prevList) => {
            const updatedList = [...prevList, newDetail];
            setDetailCommentListObj(newDetail);
            return updatedList;
        });
    };



    const onChangeText = (e) => {
        const { name, value } = e.target;
        if (name === "header") {
            setHeader(value);
        }
    };

    const onPressAddComment = (row) => {
        setDetailCommentList((prevList) =>
            prevList.map((item) =>
                item.index === row.headerIndex
                    ? {
                        ...item,
                        commentList: [
                            ...item.commentList,
                            { index: item.commentList.length + 1, comment: "", headerIndex: item.index },
                        ],
                    }
                    : item
            )
        );
    };

    const onChangeTextField = (newValue, name, itemIndex, commentIndex = null) => {
        setDetailCommentList((prevList) =>
            prevList.map((item) =>
                item.index === itemIndex
                    ? {
                        ...item,
                        [name]: commentIndex === null
                            ? newValue
                            : item[name].map((comment, idx) =>
                                idx === commentIndex ? { ...comment, comment: newValue } : comment
                            ),
                    }
                    : item
            )
        );
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
            maxWidth="lg"
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Minutes Of Meeting
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography variant="h6">{"Name :" + " " + dataRow.fullName}</Typography>
                <br />

                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={3} sm={3}>
                        <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label="* "
                        />
                        <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: titleColor }}
                            label="Header :"
                        />
                    </Grid>
                    <Grid item xs={12} md={10} sm={10}>
                        <TextFieldCompo
                            placeHolder="Header"
                            color="primary"
                            name="header"
                            size="small"
                            value={header}
                            onChange={onChangeText}
                            errorText={formErrors["headerError"] ? formErrors["headerError"] : ""}
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent="right">
                    <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Add Details"
                        onClick={onPressAdd}
                    />
                </Grid>

                {detailCommentList.map((detail) => (
                    <div key={detail.index}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} md={3} sm={3}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label={"Details " + detail.index + ":"}
                                />
                            </Grid>
                            <Grid item xs={12} md={10} sm={10}>
                                <TextFieldCompo
                                    placeHolder="Enter Details"
                                    color="primary"
                                    name="details"
                                    size="small"
                                    value={detail.details}
                                    multiline
                                    onChange={(e) =>
                                        onChangeTextField(e.target.value, "details", detail.index)
                                    }
                                />
                            </Grid>
                        </Grid>

                        {detail.commentList.map((comment) => (
                            <Grid item sm={8} xs={12} md={8} lg={8} container justifyContent="space-evenly" key={comment.index}>

                                <Grid item xs={12} md={4} sm={4}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label="* "
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: titleColor }}
                                        label={"Comment " + comment.index + ":"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={7} sm={7} display={"flex"} flexDirection={"row"}>
                                    <TextFieldCompo
                                        placeHolder="Enter comment"
                                        value={comment.comment}
                                        onChange={(e) =>
                                            onChangeTextField(
                                                e.target.value,
                                                "commentList",
                                                detail.index,
                                                comment.index - 1
                                            )
                                        }
                                        multiline
                                    />
                                    <IconButton
                                        onClick={() => onPressAddComment(comment)}
                                        color="primary"
                                    >
                                        <Add />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                ))}

                <br />
                <Grid container justifyContent="right">
                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Submit"
                        onClick={onSaveDataNew}
                    />
                </Grid>
            </DialogContent>
        </BootstrapDialog>
    );

};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    showNotification,
    showLoador,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMomPopUp);

import { Grid, Paper } from "@mui/material";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useLoadScript,
} from "@react-google-maps/api";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import checkInImg from "../../Images/check_in.png";
import distributorImg from "../../Images/distributor.png";
import checkOutImg from "../../Images/imageRed.png";
import jointWorkImg from "../../Images/joint_work.png";
import officeWorkImg from "../../Images/office_work.png";
import otherWorkImg from "../../Images/other_work.png";
import retailingImg from "../../Images/retailing.png";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
  getMapData,
  getTimelineForSalesExecutive,
} from "../../Slice/location.slice";
import { getManagerLogsBySalesTeamIds } from "../../Slice/managerLogs.slice";
import "./styles.css";
import Timeline from "./Timeline";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

const GMap = ({
  showNotification,
  getMapData,
  getManagerLogsBySalesTeamIds,
  location,
  showLoador,
  getTimelineForSalesExecutive,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDvKXkZ-S03qxR-8b8HLnCOKr36eYRxC2k",
  });
  const params = useParams();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [markers, setMarkers] = useState([]);
  const [timelineDataForManager, setTimelineDataForManager] = useState([]);
  const [newLocationData, setNewLocationData] = useState([]);
  const [showCompanyFlag, setShowCompanyFlag] = useState("");
  const [showTimelineManagerLogs, setShowTimelineManagerLogs] = useState("");
  const [checkInLat, setCheckInLat] = useState(22.9447827);
  const [checkInLong, setCheckInLong] = useState(72.6682403);
  const [timelineFlag, setTimelineFlag] = useState(false);
  const [timelineAndMapOpenFlag, setTimelineAndMapOpenFlag] = useState(false);
  const [distanceFromOutlet, setDistanceFromOutlet] = useState("");
  const [showDistanceFromOutlet, setShowDistanceFromOutlet] = useState("");
  const [checkInAddress, setCheckInAddress] = useState("");

  useEffect(() => {
    const companyFlag = localStorage.getItem("showCompanyFlag");
    setShowCompanyFlag(companyFlag);
    getMapList(moment().format("DD-MM-YYYY"));
    showLoador({ loador: true });
    apiGet({
      url: endpoint.setting + "/by-key?key=show_order_distance_on_map",
    }).then(({ data, success }) => {
      showLoador({ loador: false });

      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        if (data && data.data && Object.keys(data.data).length != 0) {
          if (data.data.settingValue == 0) {
            setShowDistanceFromOutlet(false);
          } else {
            setShowDistanceFromOutlet(true);
          }
        }
      }
    });
  }, []);

  const distanceCalculatorHandler = async (coord1, coord2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const lat1 = deg2rad(coord1.latitude);
    const lon1 = deg2rad(coord1.longitude);
    const lat2 = deg2rad(coord2.latitude);
    const lon2 = deg2rad(coord2.longitude);
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    setDistanceFromOutlet(distance * 1000); // Convert to meters
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const checkInOutAddressHandler = async (managerLogsObject) => {
    console.log(managerLogsObject);

    if (
      managerLogsObject?.managerStartLat &&
      managerLogsObject?.managerStartLong
    ) {
      showLoador({ loador: true });
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
        managerLogsObject.managerStartLat
      },${
        managerLogsObject.managerStartLong
      }&key=${"AIzaSyDvKXkZ-S03qxR-8b8HLnCOKr36eYRxC2k"}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          showLoador({ loador: false });

          if (data.status === "OK" && data.results.length !== 0) {
            const fetchedAddress = data.results[0].formatted_address;
            setCheckInAddress(fetchedAddress);
          } else {
            console.error("Error:", data.status);
          }
        })
        .catch((error) => {
          showLoador({ loador: false });
          console.error("Error:", error);
        });
    }

    // const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${settingData.googleApiKey}`;
    // fetch(apiUrl)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (data.status === "OK") {
    //       showLoader({ show: false });
    //       const address = data.results[0].formatted_address;
    //       setAddress(address);
    //     } else {
    //       showLoader({ show: false });
    //       console.error("Error:", data.status);
    //     }
    //   })
    //   .catch((error) => console.error("Error:", error));

    // setDistanceFromOutlet("20 Meter");
  };

  const getMapList = async (dataObj) => {
    if (navigator.onLine) {
      let showTimelineManagerLogs;

      const showTimelineManagerSettingKey = localStorage.getItem(
        "showTimelineManagerLogsWise"
      );

      if (showTimelineManagerSettingKey !== null) {
        showTimelineManagerLogs = showTimelineManagerSettingKey;

        setShowTimelineManagerLogs(showTimelineManagerSettingKey);
      } else {
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.setting + "/by-key?key=show_timeline_manager_logs_wise",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (data && data.data && Object.keys(data.data).length != 0) {
              if (data.data.settingValue) {
                showTimelineManagerLogs = data.data.settingValue;

                setShowTimelineManagerLogs(showTimelineManagerLogs);
              }
            }
          }
        });
      }

      if (showTimelineManagerLogs == 0 && params.userTypeId == "4") {
        showLoador({ loador: true });
        await getMapData({
          salesTeamId: params.id,
          date: dataObj,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setDate(dataObj);
            setTimelineAndMapOpenFlag(true);
            console.log(response);
            if (
              response &&
              response.outletList &&
              response.outletList.length != 0
            ) {
              setCheckInLat(
                response && response.salesTeamRealTimeDataDetails.length != 0
                  ? +response.salesTeamRealTimeDataDetails[0].latitude
                  : []
              );
              setCheckInLong(
                response && response.salesTeamRealTimeDataDetails.length != 0
                  ? +response.salesTeamRealTimeDataDetails[0].longitude
                  : []
              );
              setMarkers(response.outletList.filter((row) => +row.amount >= 0));
            }
          }
        });
      } else if (
        (showTimelineManagerLogs == 1 &&
          (params.userTypeId == "3" || params.userTypeId == "4")) ||
        (showTimelineManagerLogs != 1 && params.userTypeId == "3")
      ) {
        let managerJson = {
          fromDate: dataObj,
          toDate: dataObj,
          salesTeamIds: params.id,
        };
        showLoador({ loador: true });
        await getManagerLogsBySalesTeamIds({
          managerLogsJson: managerJson,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setDate(dataObj);

            let firebaseList = [];
            response.length != 0
              ? response.map((responseObject) => {
                  responseObject.managerLogs.length != 0
                    ? response[0].managerLogs.map((locObject, index) => {
                        const locData = {
                          index: index + 1,
                          id: locObject.id,
                          address:
                            locObject.checkInOut == 1
                              ? locObject.endAddress
                              : locObject.startAddress,
                          lat:
                            locObject.checkInOut == 1
                              ? +locObject.managerEndLat
                              : +locObject.managerStartLat,
                          lng:
                            locObject.checkInOut == 1
                              ? +locObject.managerEndLong
                              : +locObject.managerStartLong,
                          icon:
                            locObject.otherTaskId != null
                              ? locObject.otherTaskId == 1
                                ? retailingImg
                                : locObject.otherTaskId == 2
                                ? distributorImg
                                : locObject.otherTaskId == 3
                                ? officeWorkImg
                                : locObject.otherTaskId == 4
                                ? jointWorkImg
                                : locObject.otherTaskId == 5 &&
                                  locObject.checkInOut == 0
                                ? checkInImg
                                : locObject.otherTaskId == 5 &&
                                  locObject.checkInOut == 1
                                ? checkOutImg
                                : otherWorkImg
                              : "",
                          amount: +locObject.extra1,
                          workingTypeId: locObject.workingTypeId,
                          startTime: locObject.fromTime,
                          endTime: locObject.toTime,
                          outletName: locObject.entityName,
                          remoteOrder: locObject.remoteOrder,
                          checkInOut: locObject.checkInOut,
                          outletLat: locObject.outletLatitude,
                          outletLong: locObject.outletLongitude,
                        };
                        firebaseList.push(locData);
                      })
                    : [];
                })
              : [];
            setTimelineDataForManager(response);
            setNewLocationData(
              firebaseList.length != 0
                ? firebaseList.map((locationObject) => {
                    let locData = {
                      id: locationObject.id,
                      lat: locationObject.lat,
                      lng: locationObject.lng,
                    };
                    return locData;
                  })
                : []
            );

            setCheckInLat(firebaseList.length !== 0 ? firebaseList[0].lat : []);
            setCheckInLong(
              firebaseList.length !== 0 ? firebaseList[0].lng : []
            );

            let newList = [];
            firebaseList.map((locationObject) => {
              let locData = {
                id: locationObject.id,
                lat: locationObject.lat,
                lng: locationObject.lng,
              };
              newList.push(locData);
            });
            setMarkers(firebaseList);
            setTimelineAndMapOpenFlag(true);
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(checkInLat, checkInLong)
    );
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (
    index,
    id,
    lat,
    lng,
    address,
    mainId,
    amount,
    workingTypeId,
    startTime,
    endTime,
    outletName,
    remoteOrder,
    checkInOut,
    outletLat,
    outletLong
  ) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({
      index,
      id,
      address,
      amount,
      workingTypeId,
      startTime,
      endTime,
      outletName,
      remoteOrder,
      checkInOut,
      outletLat,
      outletLong,
    });
    setIsOpen(true);
    setTimelineFlag(false);

    let latLongDataObejct = {
      id: id,
      managerStartLat: lat,
      managerStartLong: lng,
      managerEndLat: lat,
      managerEndLong: lng,
      address: address,
      mainId: mainId,
      amount: amount,
      workingTypeId: workingTypeId,
      startTime: startTime,
      endTime: endTime,
      outletName: outletName,
      remoteOrder: remoteOrder,
      checkInOut: checkInOut,
      outletLat: outletLat,
      outletLong: outletLong,
    };
    if (workingTypeId == 5) {
      checkInOutAddressHandler(latLongDataObejct);
    } else {
      let coord1 = {
        latitude: lat,
        longitude: lng,
      };

      let coord2 = {
        latitude: outletLat,
        longitude: outletLong,
      };
      distanceCalculatorHandler(coord1, coord2);
    }
  };

  const onSearchData = (dataOnSearch) => {
    let firebaseList = [];
    dataOnSearch.length != 0
      ? dataOnSearch.map((responseObject) => {
          responseObject.managerLogs.length != 0
            ? dataOnSearch[0].managerLogs.map((locObject, index) => {
                const locData = {
                  index: index + 1,
                  id: locObject.id,
                  address:
                    locObject.checkInOut == 1
                      ? locObject.endAddress
                      : locObject.startAddress,
                  lat:
                    locObject.checkInOut == 1
                      ? +locObject.managerEndLat
                      : +locObject.managerStartLat,
                  lng:
                    locObject.checkInOut == 1
                      ? +locObject.managerEndLong
                      : +locObject.managerStartLong,
                  icon:
                    locObject.otherTaskId != null
                      ? locObject.otherTaskId == 1
                        ? retailingImg
                        : locObject.otherTaskId == 2
                        ? distributorImg
                        : locObject.otherTaskId == 3
                        ? officeWorkImg
                        : locObject.otherTaskId == 4
                        ? jointWorkImg
                        : locObject.otherTaskId == 5 &&
                          locObject.checkInOut == 0
                        ? checkInImg
                        : locObject.otherTaskId == 5 &&
                          locObject.checkInOut == 1
                        ? checkOutImg
                        : otherWorkImg
                      : "",
                  amount: +locObject.extra1,
                  workingTypeId: locObject.workingTypeId,
                  startTime: locObject.fromTime,
                  endTime: locObject.toTime,
                  outletName: locObject.entityName,
                  remoteOrder: locObject.remoteOrder,
                  checkInOut: locObject.checkInOut,
                  outletLat: locObject.outletLatitude,
                  outletLong: locObject.outletLongitude,
                };
                firebaseList.push(locData);
              })
            : [];
        })
      : [];

    setTimelineDataForManager(dataOnSearch);

    setNewLocationData(
      firebaseList.length != 0
        ? firebaseList.map((locationObject) => {
            let locData = {
              id: locationObject.id,
              lat: locationObject.lat,
              lng: locationObject.lng,
            };
            return locData;
          })
        : []
    );

    setCheckInLat(firebaseList.length !== 0 ? firebaseList[0].lat : []);

    setCheckInLong(firebaseList.length !== 0 ? firebaseList[0].lng : []);

    let newList = [];
    firebaseList.map((locationObject) => {
      let locData = {
        id: locationObject.id,
        lat: locationObject.lat,
        lng: locationObject.lng,
      };
      newList.push(locData);
    });

    setMarkers(firebaseList.length != 0 ? firebaseList : []);
    setTimelineAndMapOpenFlag(true);
  };

  const handleTabData = (latLongDataObejct) => {
    console.log("HERERRE");
    console.log(latLongDataObejct);
    if (latLongDataObejct.workingTypeId == 5) {
      checkInOutAddressHandler(latLongDataObejct);
    } else {
      let coord1 = {
        latitude: latLongDataObejct.managerStartLat,
        longitude: latLongDataObejct.managerStartLong,
      };

      let coord2 = {
        latitude: latLongDataObejct.outletLat,
        longitude: latLongDataObejct.outletLong,
      };
      distanceCalculatorHandler(coord1, coord2);
    }
    mapRef?.panTo({
      lat: +latLongDataObejct.managerStartLat,
      lng: +latLongDataObejct.managerStartLong,
    });
    setInfoWindowData({
      index: 0,
      id: latLongDataObejct.id,
      address: "",
      amount: latLongDataObejct.value1,
      workingTypeId: latLongDataObejct.workingTypeId,
      startTime: latLongDataObejct.fromTime,
      endTime: latLongDataObejct.toTime,
      outletName: latLongDataObejct.entityName,
      remoteOrder: latLongDataObejct.remoteOrder,
      checkInOut: latLongDataObejct.checkInOut,
      outletLat: latLongDataObejct.outletLatitude,
      outletLong: latLongDataObejct.outletLongitude,
    });
    setIsOpen(true);
    setTimelineFlag(true);
  };

  const tabValueHandler = (value, rows) => {
    let filterDaylist = [];

    if (showTimelineManagerLogs == 0 && params.userTypeId == "4") {
      filterDaylist =
        location.salesTeamTimeline.length !== 0
          ? location.salesTeamTimeline.filter((row) => row.index == value)
          : [];

      if (filterDaylist.length != 0) {
        getMapList(filterDaylist[0].date);
      }
    } else if (
      (showTimelineManagerLogs == 1 &&
        (params.userTypeId == "3" || params.userTypeId == "4")) ||
      (showTimelineManagerLogs != 1 && params.userTypeId == "3")
    ) {
      let firebaseList = [];
      filterDaylist =
        rows.length !== 0 && rows.filter((row) => row.index == value - 1);
      filterDaylist.length != 0
        ? filterDaylist.map((responseObject) => {
            responseObject.managerLogs.length != 0
              ? filterDaylist[0].managerLogs.map((locObject, index) => {
                  const locData = {
                    index: index + 1,
                    id: locObject.id,
                    address:
                      locObject.checkInOut == 1
                        ? locObject.endAddress
                        : locObject.startAddress,
                    lat:
                      locObject.checkInOut == 1
                        ? +locObject.managerEndLat
                        : +locObject.managerStartLat,
                    lng:
                      locObject.checkInOut == 1
                        ? +locObject.managerEndLong
                        : +locObject.managerStartLong,
                    icon:
                      locObject.otherTaskId != null
                        ? locObject.otherTaskId == 1
                          ? retailingImg
                          : locObject.otherTaskId == 2
                          ? distributorImg
                          : locObject.otherTaskId == 3
                          ? officeWorkImg
                          : locObject.otherTaskId == 4
                          ? jointWorkImg
                          : locObject.otherTaskId == 5 &&
                            locObject.checkInOut == 0
                          ? checkInImg
                          : locObject.otherTaskId == 5 &&
                            locObject.checkInOut == 1
                          ? checkOutImg
                          : otherWorkImg
                        : "",
                    amount: +locObject.extra1,
                    workingTypeId: locObject.workingTypeId,
                    startTime: locObject.fromTime,
                    endTime: locObject.toTime,
                    outletName: locObject.entityName,
                    remoteOrder: locObject.remoteOrder,
                    checkInOut: locObject.checkInOut,
                    outletLat: locObject.outletLatitude,
                    outletLong: locObject.outletLongitude,
                  };
                  firebaseList.push(locData);
                })
              : [];
          })
        : [];

      setTimelineDataForManager(filterDaylist);
      setNewLocationData(
        firebaseList.length != 0
          ? firebaseList.map((locationObject) => {
              let locData = {
                id: locationObject.id,
                lat: locationObject.lat,
                lng: locationObject.lng,
              };
              return locData;
            })
          : []
      );
      setMarkers(firebaseList);
    }
  };

  return (
    <Paper
      sx={{
        // p: 2,
        borderRadius: 2,
      }}
    >
      {/* <BasicDatePicker
        color="primary"
        name="dob"
        size="small"
        value={date}
        maxDate={dayjs()}
        fullWidth
        onChange={onDateChange}
      /> */}
      <Grid
        // style={{ height: "90vh" }}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        {timelineAndMapOpenFlag && (
          <>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              container
              xs={12}
              sm={8}
              md={9}
              lg={9}
            >
              {/* <Grid container xs={12} sm={12} md={12} lg={12}> */}
              {!isLoaded ? (
                <h1>Loading...</h1>
              ) : (
                <GoogleMap
                  mapContainerClassName="map-container"
                  onLoad={onMapLoad}
                  onClick={() => setIsOpen(false)}
                  zoom={200}
                  mapContainerStyle={{ width: "100%" }}
                >
                  {markers.map(
                    (
                      {
                        index,
                        address,
                        lat,
                        lng,
                        id,
                        icon,
                        amount,
                        workingTypeId,
                        startTime,
                        endTime,
                        outletName,
                        remoteOrder,
                        checkInOut,
                        outletLat,
                        outletLong,
                      },
                      ind
                    ) => (
                      <Marker
                        key={ind}
                        position={{ lat, lng }}
                        onClick={() => {
                          handleMarkerClick(
                            index,
                            ind,
                            lat,
                            lng,
                            address,
                            id,
                            amount,
                            workingTypeId,
                            startTime,
                            endTime,
                            outletName,
                            remoteOrder,
                            checkInOut,
                            outletLat,
                            outletLong
                          );
                        }}
                        icon={{
                          url: icon,
                          scaledSize: new google.maps.Size(50, 50),
                        }}
                      >
                        {(!timelineFlag && infoWindowData?.id === ind) ||
                        (timelineFlag && infoWindowData?.id === id) ? (
                          <InfoWindow
                            onCloseClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "6px",
                              }}
                            >
                              {infoWindowData.workingTypeId == 5 ? (
                                <>
                                  <div style={dataSectionStyle}>
                                    <span style={spanStyleTimelineCss}>
                                      {"Activity No. : " + index}
                                    </span>
                                    <span style={spanStyleTimelineCss}>
                                      {infoWindowData.checkInOut == 0
                                        ? "Check In Time : " + startTime
                                        : "Check Out Time : " + endTime}
                                    </span>
                                    <span style={spanStyleTimelineCss}>
                                      {infoWindowData.checkInOut == 0
                                        ? "Check In Address : " + checkInAddress
                                        : "Check Out Address : " +
                                          checkInAddress}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div style={dataSectionStyle}>
                                    <span style={spanStyleTimelineCss}>
                                      {"Activity No. : " + index}
                                    </span>
                                    <span style={spanStyleTimelineCss}>
                                      {"Outlet Name : " + outletName}
                                    </span>
                                    <span style={spanStyleTimelineCss}>
                                      {"Outlet Address : " + address}
                                    </span>
                                    <span style={spanStyleTimelineCss}>
                                      {"Is Remote Order : " +
                                        (remoteOrder == 0 ? "No" : "Yes")}
                                    </span>
                                    {showDistanceFromOutlet && (
                                      <span style={spanStyleTimelineCss}>
                                        {"Order Distance: " +
                                          distanceFromOutlet.toFixed(2) +
                                          " Meter"}
                                      </span>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </InfoWindow>
                        ) : (
                          ""
                        )}

                        <Polyline
                          path={
                            showTimelineManagerLogs == 1 &&
                            params.userTypeId == "4"
                              ? newLocationData
                              : showTimelineManagerLogs == 0 &&
                                params.userTypeId == "4"
                              ? location.location.firebaseList
                              : params.userTypeId == "3"
                              ? newLocationData
                              : []
                          }
                          geodesic={true}
                          options={{
                            strokeColor: "#00008B",
                            strokeOpacity: 0.75,
                            strokeWeight: 2,
                            icons: [
                              {
                                // icon:"https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png",
                                offset: "0",
                                repeat: "20px",
                              },
                            ],
                          }}
                        />
                      </Marker>
                    )
                  )}
                </GoogleMap>
              )}
              {/* </Grid> */}
            </Grid>
            <Grid
              container
              style={{ overflow: "auto", width: "100%" }}
              xs={12}
              sm={4}
              md={3}
              lg={3}
            >
              <Timeline
                saveData={tabValueHandler}
                dateFlag={date}
                handleTabData={handleTabData}
                onSearchData={onSearchData}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

const spanStyleTimelineCss = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "flex-start",
  fontSize: "15px",
  fontWeight: "400",
};

const dataSectionStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  alignItems: "flex-start",
};

const mapStateToProps = (state) => ({
  location: state.location,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  getMapData,
  getTimelineForSalesExecutive,
  getManagerLogsBySalesTeamIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(GMap);

import endpoint from "../../config/endpoints";

export const AddDistributorJson = {
    formPath: "/add-distributor",
    apiBaseURL: endpoint.accountLedger,
    screenTitle: "Add Stock Holder",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,

    fieldMetaForBasicInfo: [

        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "currentRegionId",
            getListId: "currentRegionId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stateDetail",
            dataKey: "currentStateId",
            getListFrom: "currentRegionId",
            getListId: "currentStateId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "currentZoneIds",
            getListFrom: "currentStateId",
            getListId: "currentZoneIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "districtDetail",
            dataKey: "currentDistrictIds",
            getListFrom: "currentZoneIds",
            getListId: "currentDistrictIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "talukaDetail",
            dataKey: "currentTalukaIds",
            getListFrom: "currentDistrictIds",
            getListId: "currentTalukaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "autocomplete",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "currentAreaIds",
            getListFrom: "currentTalukaIds",
            getListId: "currentAreaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },

        {
            label: "Firm Name",
            controlType: "textfield",
            placeHolder: "Firm Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "businessName",
            isMandatory: true,

        },
        {
            label: "Owner Name",
            controlType: "textfield",
            placeHolder: "Owner Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fullName",
            isMandatory: true,
            // getData: true,
        },
        {
            label: "Mobile Number",
            controlType: "textfield",
            placeHolder: "Mobile Number",
            inputType: "number",
            maxLength: 10,
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "contactNo",
            valid: true,
            validName: "phoneNo",
            isMandatory: true,
        },
        {
            label: "Email Id",
            controlType: "textfield",
            placeHolder: "Email Id",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "emailId",
            valid: true,
            validName: "email",
            isMandatory: true,
        },

        {
            label: "Address",
            controlType: "textfield",
            placeHolder: "Address",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "businessAddress",
            isMandatory: true,
        },
        {
            label: "Pincode",
            controlType: "textfield",
            placeHolder: "Pincode",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "currentPincode",
            isMandatory: true,
        },
        {
            label: "Udyam Aadhar Number",
            controlType: "textfield",
            placeHolder: "Udyam Aadhar Number",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "udyamAadharNumber",
            isMandatory: false,
            // valid:true,
            // validName:"validUdyamAadhar"
        },
    ],

    fieldMetaForBasicInfoWithoutHierarchy: [
        {
            label: "Area",
            controlType: "autocomplete",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "currentAreaIds",
            getListId: "currentAreaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Firm Name",
            controlType: "textfield",
            placeHolder: "Firm Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "businessName",
            isMandatory: true,

        },
        {
            label: "Owner Name",
            controlType: "textfield",
            placeHolder: "Owner Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fullName",
            isMandatory: true,
            // getData: true,
        },
        {
            label: "Mobile Number",
            controlType: "textfield",
            placeHolder: "Mobile Number",
            inputType: "number",
            maxLength: 10,
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "contactNo",
            valid: true,
            validName: "phoneNo",
            isMandatory: true,
        },
        {
            label: "Email Id",
            controlType: "textfield",
            placeHolder: "Email Id",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "emailId",
            valid: true,
            validName: "email",
            isMandatory: true,
        },

        {
            label: "Address",
            controlType: "textfield",
            placeHolder: "Address",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "businessAddress",
            isMandatory: true,
        },
        {
            label: "Pincode",
            controlType: "textfield",
            placeHolder: "Pincode",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "currentPincode",
            isMandatory: true,
        },
        {
            label: "Udyam Aadhar Number",
            controlType: "textfield",
            placeHolder: "Udyam Aadhar Number",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "udyamAadharNumber",
            isMandatory: false,
            // valid:true,
            // validName:"validUdyamAadhar"
        },
    ],

    fieldMetaForPartnerDetails: [
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "partnerCurrentRegionId",
            getListId: "partnerCurrentRegionId",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stateDetail",
            dataKey: "partnerCurrentStateId",
            getListFrom: "partnerCurrentRegionId",
            getListId: "partnerCurrentStateId",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "partnerCurrentZoneIds",
            getListFrom: "partnerCurrentStateId",
            getListId: "partnerCurrentZoneIds",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "districtDetail",
            dataKey: "partnerCurrentDistrictIds",
            getListFrom: "partnerCurrentZoneIds",
            getListId: "partnerCurrentDistrictIds",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "talukaDetail",
            dataKey: "partnerCurrentTalukaIds",
            getListFrom: "partnerCurrentDistrictIds",
            getListId: "partnerCurrentTalukaIds",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "Area",
            controlType: "autocomplete",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "partnerCurrentAreaIds",
            getListFrom: "partnerCurrentTalukaIds",
            getListId: "partnerCurrentAreaIds",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "Firm Name",
            controlType: "textfield",
            placeHolder: "Firm Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerBusinessName",
            isMandatory: false,

        },
        {
            label: "Contact Person Name",
            controlType: "textfield",
            placeHolder: "Contact Person Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerFullName",
            isMandatory: false,
            // getData: true,
        },
        {
            label: "Mobile Number",
            controlType: "textfield",
            placeHolder: "Mobile Number",
            inputType: "number",
            maxLength: 10,
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerContactNo",
            valid: true,
            validName: "phoneNo",
            isMandatory: false,
        },
        {
            label: "Email Id",
            controlType: "textfield",
            placeHolder: "Email Id",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerEmailId",
            valid: true,
            // validName: "email",
            isMandatory: false,
        },

        {
            label: "Address",
            controlType: "textfield",
            placeHolder: "Address",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerBusinessAddress",
            isMandatory: false,
        },
        {
            label: "Pincode",
            controlType: "textfield",
            placeHolder: "Pincode",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerCurrentPincode",
            isMandatory: true,
        },
        {
            label: "Udyam Aadhar Number",
            controlType: "textfield",
            placeHolder: "Udyam Aadhar Number",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerUdyamAadharNumber",
            isMandatory: false,
            // valid:true,
            // validName:"validUdyamAadhar"
        },
    ],

    fieldMetaForPartnerDetailsWithoutHierarchy: [
        {
            label: "Area",
            controlType: "autocomplete",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "partnerCurrentAreaIds",
            getListId: "partnerCurrentAreaIds",
            isRootLevelKey: true,
            isMandatory: false,
        },
        {
            label: "Firm Name",
            controlType: "textfield",
            placeHolder: "Firm Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerBusinessName",
            isMandatory: false,

        },
        {
            label: "Contact Person Name",
            controlType: "textfield",
            placeHolder: "Contact Person Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerFullName",
            isMandatory: false,
            // getData: true,
        },
        {
            label: "Mobile Number",
            controlType: "textfield",
            placeHolder: "Mobile Number",
            inputType: "number",
            maxLength: 10,
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerContactNo",
            valid: true,
            validName: "phoneNo",
            isMandatory: false,
        },
        {
            label: "Email Id",
            controlType: "textfield",
            placeHolder: "Email Id",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerEmailId",
            valid: true,
            // validName: "email",
            isMandatory: false,
        },

        {
            label: "Address",
            controlType: "textfield",
            placeHolder: "Address",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerBusinessAddress",
            isMandatory: false,
        },
        {
            label: "Pincode",
            controlType: "textfield",
            placeHolder: "Pincode",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerCurrentPincode",
            isMandatory: true,
        },
        {
            label: "Udyam Aadhar Number",
            controlType: "textfield",
            placeHolder: "Udyam Aadhar Number",
            inputType: "numberText",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "partnerUdyamAadharNumber",
            isMandatory: false,
            // valid:true,
            // validName:"validUdyamAadhar"
        },
    ],


    fieldMetaForOther: [

        {
            label: "PAN No.",
            controlType: "textfield",
            placeHolder: "PAN No",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "panNo",
            maxLength: 10,
            valid: true,
            validName: "panNo",
            isMandatory: true,

        },

        {
            label: "FSSAI Number",
            controlType: "textfield",
            placeHolder: "FSSAI Number",
            inputType: "number",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fssiFdiNo",
            isMandatory: false,
            maxLength: 14,
        },
        {
            label: "FSSAI Expiry Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fssiFdiNoExpiryDate",
            isMandatory: true,
        },
        {
            label: "GST Type",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "gstType",
            tableDataKey: "gstType",
            defaultValue: "1",
            radioGroupItems: [
                {
                    label: "Non Register",
                    value: "1",
                },
                {
                    label: "Composite ",
                    value: "2",
                },
                {
                    label: "Regular",
                    value: "3",
                },
            ],
            isMandatory: true,
        },
        {
            label: "GST Number",
            controlType: "hideTextfieldNotEqual",
            placeHolder: "22AAAAA0000A1Z5",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "gstNo",
            tableDataKey: "gstType",
            openDataKey: "gstType",
            open: "1",
            valid: true,
            validName: "gstNo",
            inputType: "numberText",
            error: "GST Number",
            saveDataKey: true,
            setTextHide: true,
        },
    ],


    fieldMetaForConvert: [
        {
            label: "Type",
            controlType: "autocomplete",
            placeHolder: "Select Stock Holder Type",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stakeHolderTypeDetail",
            dataKey: "stockStakeHolderType",
            getListId: "stockStakeHolderType",
            isRootLevelKey: true,
            isMandatory: true,
            disable: true,
        },

        {
            label: "Credit Limit",
            controlType: "textfield",
            placeHolder: "Enter Credit Limit",
            inputType: "number",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "creditLimit",
            isMandatory: true,
        },
        {
            label: "Payment term",
            controlType: "autocomplete",
            placeHolder: "Select Payment term",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "paymentTermDetail",
            dataKey: "paymentTermId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Brand",
            controlType: "autocomplete",
            placeHolder: "Select Brand",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "brandDetail",
            dataKey: "brandId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "todate-le",
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            onSubmit: "compare",
            onSubmitParameter: "fromdate-ge",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "fromdate-ge",
        },
    ],

};

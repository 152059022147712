import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
    distributorInquiry: [],
    distributorInquiryData: {},
};
let URL = endpoints.distributorInquiry;
const distributorInquirySlice = createSlice({
    name: "distributorInquiry",
    initialState,
    reducers: {
        distributorInquirySuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                distributorInquiry: row,
            };
        },

        saveDistributorInquirySuccess: (state = cloneDeep(initialState), action) => {
            const { data } = action.payload;
            return {
                ...state,
                distributorInquiryData: data,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    distributorInquirySuccess,
    saveDistributorInquirySuccess,
    resetState,
} = distributorInquirySlice.actions;

export default distributorInquirySlice.reducer;

export const getDistributorInquiry = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + "/by-login-user",
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((distributorInquiryObject, index) => {
                    let distributorInquiryData = {
                        index: index + 1,
                        id: distributorInquiryObject.id === null ? "" : distributorInquiryObject.id,
                        flag: distributorInquiryObject.flag === null ? "" : distributorInquiryObject.flag,
                        annualTurnoverId:
                            distributorInquiryObject.annualTurnoverId === null
                                ? ""
                                : distributorInquiryObject.annualTurnoverId,
                        anyReferences:
                            distributorInquiryObject.anyReferences === null ? "" : distributorInquiryObject.anyReferences,
                        bankAddress:
                            distributorInquiryObject.bankAddress === null ? "" : distributorInquiryObject.bankAddress,
                        bankName:
                            distributorInquiryObject.bankName === null ? "" : distributorInquiryObject.bankName,
                        brandId:
                            distributorInquiryObject.brandId === null ? "" : distributorInquiryObject.brandId,
                        businessAddress:
                            distributorInquiryObject.businessAddress === null ? "" : distributorInquiryObject.businessAddress,
                        businessName:
                            distributorInquiryObject.businessName === null ? "" : distributorInquiryObject.businessName,
                        contactNo:
                            distributorInquiryObject.contactNo === null ? "" : distributorInquiryObject.contactNo,
                        creditLimit:
                            distributorInquiryObject.creditLimit === null ? "" : distributorInquiryObject.creditLimit,
                        currentRegionId:
                            distributorInquiryObject.currentRegionId === null ? "" : distributorInquiryObject.currentRegionId,
                        currentAreaIds:
                            distributorInquiryObject.currentAreaIds === null ? "" : distributorInquiryObject.currentAreaIds,
                        currentDistrictIds:
                            distributorInquiryObject.currentDistrictIds === null ? "" : distributorInquiryObject.currentDistrictIds,
                        currentLocation:
                            distributorInquiryObject.currentLocation === null ? "" : distributorInquiryObject.currentLocation,
                        currentPincode:
                            distributorInquiryObject.currentPincode === null ? "" : distributorInquiryObject.currentPincode,
                        currentStateId:
                            distributorInquiryObject.currentStateId === null ? "" : distributorInquiryObject.currentStateId,
                        currentTalukaIds:
                            distributorInquiryObject.currentTalukaIds === null ? "" : distributorInquiryObject.currentTalukaIds,
                        currentZoneIds:
                            distributorInquiryObject.currentZoneIds === null ? "" : distributorInquiryObject.currentZoneIds,
                        currentLocationLatitude:
                            distributorInquiryObject.currentLocationLatitude === null ? "" : distributorInquiryObject.currentLocationLatitude,

                        currentLocationLongitude:
                            distributorInquiryObject.currentLocationLongitude === null ? "" : distributorInquiryObject.currentLocationLongitude,

                        districtId:
                            distributorInquiryObject.districtId === null ? "" : distributorInquiryObject.districtId,
                        education:
                            distributorInquiryObject.education === null ? "" : distributorInquiryObject.education,
                        emailId:
                            distributorInquiryObject.emailId === null ? "" : distributorInquiryObject.emailId,
                        experienceInYears:
                            distributorInquiryObject.experienceInYears === null ? "" : distributorInquiryObject.experienceInYears,
                        fromDate:
                            distributorInquiryObject.fromDate === null ? "" : distributorInquiryObject.fromDate,
                        fssiFdiNo:
                            distributorInquiryObject.fssiFdiNo === null ? "" : distributorInquiryObject.fssiFdiNo,
                        fssiFdiNoExpiryDate:
                            distributorInquiryObject.fssiFdiNoExpiryDate === null ? "" : distributorInquiryObject.fssiFdiNoExpiryDate,
                        fullName:
                            distributorInquiryObject.fullName === null ? "" : distributorInquiryObject.fullName,
                        panNo: distributorInquiryObject.panNo === null ? "" : distributorInquiryObject.panNo,
                        gstNo:
                            distributorInquiryObject.gstNo === null ? "" : distributorInquiryObject.gstNo,
                        gstType:
                            distributorInquiryObject.gstType === null ? "" : distributorInquiryObject.gstType,
                        investedAmountId:
                            distributorInquiryObject.investedAmountId === null ? "" : distributorInquiryObject.investedAmountId,
                        numberOfStaffId:
                            distributorInquiryObject.numberOfStaffId === null ? "" : distributorInquiryObject.numberOfStaffId,
                        partnerApplicable:
                            distributorInquiryObject.partnerApplicable === null ? "" : distributorInquiryObject.partnerApplicable,
                        partnerBusinessAddress:
                            distributorInquiryObject.partnerBusinessAddress === null ? "" : distributorInquiryObject.partnerBusinessAddress,
                        partnerBusinessName:
                            distributorInquiryObject.partnerBusinessName === null ? "" : distributorInquiryObject.partnerBusinessName,
                        partnerCurrentAreaId:
                            distributorInquiryObject.partnerCurrentAreaId === null ? "" : distributorInquiryObject.partnerCurrentAreaId,
                        partnerCurrentDistrictId:
                            distributorInquiryObject.partnerCurrentDistrictId === null ? "" : distributorInquiryObject.partnerCurrentDistrictId,

                        partnerCurrentLocation:
                            distributorInquiryObject.partnerCurrentLocation === null ? "" : distributorInquiryObject.partnerCurrentLocation,
                        partnerCurrentPincode:
                            distributorInquiryObject.partnerCurrentPincode === null ? "" : distributorInquiryObject.partnerCurrentPincode,
                        partnerCurrentStateId:
                            distributorInquiryObject.partnerCurrentStateId === null ? "" : distributorInquiryObject.partnerCurrentStateId,
                        partnerCurrentTalukaId:
                            distributorInquiryObject.partnerCurrentTalukaId === null ? "" : distributorInquiryObject.partnerCurrentTalukaId,
                        partnerEducation:
                            distributorInquiryObject.partnerEducation === null ? "" : distributorInquiryObject.partnerEducation,
                        partnerExperienceInYears:
                            distributorInquiryObject.partnerExperienceInYears === null ? "" : distributorInquiryObject.partnerExperienceInYears,
                        partnerFullName:
                            distributorInquiryObject.partnerFullName === null ? "" : distributorInquiryObject.partnerFullName,
                        partnerPermanentAreaId:
                            distributorInquiryObject.partnerPermanentAreaId === null ? "" : distributorInquiryObject.partnerPermanentAreaId,
                        partnerPermanentDistrictId:
                            distributorInquiryObject.partnerPermanentDistrictId === null ? "" : distributorInquiryObject.partnerPermanentDistrictId,
                        partnerPermanentLocation:
                            distributorInquiryObject.partnerPermanentLocation === null ? "" : distributorInquiryObject.partnerPermanentLocation,
                        partnerPermanentPincode:
                            distributorInquiryObject.partnerPermanentPincode === null ? "" : distributorInquiryObject.partnerPermanentPincode,
                        partnerPermanentStateId:
                            distributorInquiryObject.partnerPermanentStateId === null ? "" : distributorInquiryObject.partnerPermanentStateId,
                        partnerPermanentTalukaId:
                            distributorInquiryObject.partnerPermanentTalukaId === null ? "" : distributorInquiryObject.partnerPermanentTalukaId,
                        paymentTermId:
                            distributorInquiryObject.paymentTermId === null ? "" : distributorInquiryObject.paymentTermId,
                        permanantAreaId:
                            distributorInquiryObject.permanantAreaId === null ? "" : distributorInquiryObject.permanantAreaId,
                        permanantDistrictId:
                            distributorInquiryObject.permanantDistrictId === null ? "" : distributorInquiryObject.permanantDistrictId,
                        permanantLocation:
                            distributorInquiryObject.permanantLocation === null ? "" : distributorInquiryObject.permanantLocation,
                        permanantPincode:
                            distributorInquiryObject.permanantPincode === null ? "" : distributorInquiryObject.permanantPincode,
                        permanantStateId:
                            distributorInquiryObject.permanantStateId === null ? "" : distributorInquiryObject.permanantStateId,
                        permanantTalukaId:
                            distributorInquiryObject.permanantTalukaId === null ? "" : distributorInquiryObject.permanantTalukaId,
                        referencePersonDepartment:
                            distributorInquiryObject.referencePersonDepartment === null ? "" : distributorInquiryObject.referencePersonDepartment,
                        referencePersonName:
                            distributorInquiryObject.referencePersonName === null ? "" : distributorInquiryObject.referencePersonName,
                        stateId:
                            distributorInquiryObject.stateId === null ? "" : distributorInquiryObject.stateId,
                        status:
                            distributorInquiryObject.status === null ? "" : distributorInquiryObject.status,
                        toDate:
                            distributorInquiryObject.toDate === null ? "" : distributorInquiryObject.toDate,
                        typeOfBusinessId:
                            distributorInquiryObject.typeOfBusinessId === null ? "" : distributorInquiryObject.typeOfBusinessId,
                        upi:
                            distributorInquiryObject.upi === null ? "" : distributorInquiryObject.upi,
                        isChecked: false,
                    };
                    return distributorInquiryData;
                });
                dispatch(distributorInquirySuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};


export const setDistributorInquiry =
    ({ row }) =>
        async (dispatch) => {
            dispatch(distributorInquirySuccess({ row }));
        };


export const getDistributorInquiryWithStatus = ({ status }) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + "/by-status?status=" + status,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((distributorInquiryObject, index) => {
                    let distributorInquiryData = {
                        index: index + 1,
                        id: distributorInquiryObject.id === null ? "" : distributorInquiryObject.id,
                        name:
                            distributorInquiryObject.name === null
                                ? ""
                                : distributorInquiryObject.name + " (" + distributorInquiryObject.shortName + ")",
                        distributorInquiryName:
                            distributorInquiryObject.name === null ? "" : distributorInquiryObject.name,
                        zoneName:
                            distributorInquiryObject.zone !== null && distributorInquiryObject.zone.name
                                ? distributorInquiryObject.zone.name
                                : "",
                        zone:
                            distributorInquiryObject.zone !== null && distributorInquiryObject.zone.id
                                ? distributorInquiryObject.zone.id
                                : "",
                        shortName:
                            distributorInquiryObject.shortName === null ? "" : distributorInquiryObject.shortName,
                        talukaCount:
                            distributorInquiryObject.talukaCount === null
                                ? ""
                                : distributorInquiryObject.talukaCount,
                        areaCount:
                            distributorInquiryObject.areaCount === null ? "" : distributorInquiryObject.areaCount,
                        beatCount:
                            distributorInquiryObject.beatCount === null ? "" : distributorInquiryObject.beatCount,
                        outletCount:
                            distributorInquiryObject.outletCount === null
                                ? ""
                                : distributorInquiryObject.outletCount,
                        sortOrder:
                            distributorInquiryObject.sortOrder === null ? "" : distributorInquiryObject.sortOrder,
                    };
                    return distributorInquiryData;
                });
                dispatch(distributorInquirySuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};




export const saveDistributorInquiry =
    ({ dataToSave }) =>
        async (dispatch) => {
            try {
                const response = apiPost({
                    url: URL,
                    postBody: dataToSave,
                }).then(({ data, success }) => {
                    if (success) {
                        dispatch(saveDistributorInquirySuccess({ data: data.data }));
                        return { response: data.data, success };
                    }
                    return { response: {}, success };
                });
                return response;
            } catch (e) {
                return console.error(e.message);
            }
        };



export const setSaveDistributorInquiry =
    ({ rowObject }) =>
        async (dispatch) => {
            dispatch(saveDistributorInquirySuccess({ data: rowObject }));
        };






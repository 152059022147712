import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

import { ButtonCompo } from "../../components/Comman/Button";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import { requestForDistributorJson } from "../../DynamicFormsJson/MastersJSON/requestForDistributor";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { deleteFailedMsg, deletemsg, deleteWarningMsg, noInternetMsg, savemsg, saveWarningMsg, serverMsg, watcherMsg } from "../../config/messageconstant";
import { apiDelete, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import swal from "sweetalert";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getSalesExecutiveWithStockHolderIdWithoutSelf } from "../../Slice/salesExecutive.slice";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import { titleColor } from "../../config/ColorObj";
import { getDistributorInquiryWatcher } from "../../Slice/distributorInquiryWatcher.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

let columns = [
    {
        title: "#.",
        name: "index",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "left",
        canSearch: true,
    },
    {
        name: "salesTeam",
        title: "Stock Holder Name",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
        canSearch: true,
    },
    {
        name: "stockStakeholderType",
        title: "Stock Holder Type",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
        canSearch: true,
    },
    {
        name: "action",
        title: "Actions",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "right",
        canSearch: true,
    },
];

let columnsWithoutAction = [
    {
        title: "#.",
        name: "index",
        positionCenter: true,
        showInscreen: true,
        alignCenter: "left",
        canSearch: true,
    },
    {
        name: "salesTeam",
        title: "Stock Holder Name",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
        canSearch: true,
    },
    {
        name: "stockStakeholderType",
        title: "Stock Holder Type",
        positionCenter: false,
        showInscreen: true,
        alignCenter: "left",
        canSearch: true,
    },
];

class AddWatcherPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                stockStakeHolderTypeDetail:
                    this.props.stockStakeHolderTypeList?.stockStakeHolderType,
                salesTeamDetail: this.props.salesExecutiveList?.salesExecutive,
            },
            adminFlag: false,
            areaIds: "",
            companyFlag: "",
            watcherList: [],
            formData: {},
            formErrors: {},
            fieldData: {},
            salesTeamValue: "",
            stockStakeholderTypeValue: "",
            watcherPopUpOpenFlag: false,
        };
    }

    async componentDidMount() {
        const {
            getStockStakeHolderType,
            getDistributorInquiryWatcher,
            showLoador,
            showNotification,
            dataRow,
        } = this.props;
        const { watcherList } = this.state;
        if (navigator.onLine) {

            showLoador({ loador: true });
            await getStockStakeHolderType({ userTypeIds: "3" }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getDistributorInquiryWatcher({ distributorInquiryId: dataRow.id }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {

                    const watcherList = response.map((dataObj, index) => ({
                        index: index + 1,
                        id: dataObj.id ? dataObj.id : "",
                        salesTeam: dataObj.salesTeamName,
                        salesTeamId: dataObj.salesTeamId,
                        stockStakeholderType: dataObj.stockStakeHolderTypeName,
                        stockStakeholderTypeId: dataObj.stockStakeHolderTypeId,
                    }));
                    this.setState({ watcherList });
                }
            });

            this.watcherPopUpOpen();
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    watcherPopUpOpen = () => {
        this.setState({ watcherPopUpOpenFlag: true });
    }




    componentWillReceiveProps(nextProps) {

        if (this.props.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList) {
            if (
                this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
                nextProps.stockStakeHolderTypeList?.stockStakeHolderType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockStakeHolderTypeDetail:
                            nextProps.stockStakeHolderTypeList?.stockStakeHolderType,
                    },
                });
            }
        }
        if (this.props.salesExecutiveList !== nextProps.salesExecutiveList) {
            if (
                this.props.salesExecutiveList?.salesExecutive !==
                nextProps.salesExecutiveList?.salesExecutive
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        salesTeamDetail: nextProps.salesExecutiveList?.salesExecutive,
                    },
                });
            }
        }
    }

    getListById = async (data) => {
        const {
            getSalesExecutiveWithStockHolderIdWithoutSelf,
            showLoador,
            showNotification,
        } = this.props;
        const { areaIds, adminFlag } = this.state;
        if (navigator.onLine) {
            if (data.stockStakeHolderTypeId && data.stockStakeHolderTypeId != "") {
                let stockStakeHolderTypeIdsToSend = data.stockStakeHolderTypeId.split(",").map(id => id.trim());
                console.log(stockStakeHolderTypeIdsToSend);

                showLoador({ loador: true });
                await getSalesExecutiveWithStockHolderIdWithoutSelf({ stockStakeHolderTypeIds: stockStakeHolderTypeIdsToSend }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }

        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    checkValidationOnSubmit = () => {
        const { fieldData } = this.state;
        const mandatoryCheckErrors = mandatoryCheck({
            fieldData,
            fieldMeta: requestForDistributorJson.fieldMeta,
        });
        this.setState({
            formErrors: mandatoryCheckErrors.formErrors,
        });
        if (mandatoryCheckErrors.formValid) {
            const ValidationCheckErrors = ValidationCheck({
                fieldData,
                fieldMeta: requestForDistributorJson.fieldMeta,
            });
            this.setState({
                formErrors: ValidationCheckErrors.formErrors,
            });
            return ValidationCheckErrors.formValid;
        } else {
            return false;
        }
    };



    onPressAdd = () => {
        const { watcherList, salesTeamValue, stockStakeholderTypeValue, dynamicMasterData } = this.state;

        if (this.checkValidationOnSubmit()) {
            const salesTeamObject = dynamicMasterData.salesTeamDetail.find((team) => team.id === salesTeamValue);
            const stockHolderTypeObject = dynamicMasterData.stockStakeHolderTypeDetail.find(
                (holder) => holder.id === stockStakeholderTypeValue
            );

            const newWatcher = {
                index: watcherList.length + 1,
                salesTeam: salesTeamObject.name,
                stockStakeholderType: stockHolderTypeObject.name,
                salesTeamId: salesTeamObject.id,
                stockStakeholderTypeId: stockHolderTypeObject.id,
            };

            this.setState((prevState) => ({
                watcherList: [...prevState.watcherList, newWatcher],
                formData: {},
            }));
        }
    };


    saveHandler = () => {
        const { dataRow, showNotification } = this.props;
        const { watcherList } = this.state;

        const saveList = watcherList.filter((dataObj) => !dataObj.id).map((dataObj) => ({
            distributorInquiry: { id: dataRow.id },
            stockStakeHolderType: { id: dataObj.stockStakeholderTypeId },
            salesTeam: { id: dataObj.salesTeamId },
        }));


        console.log(saveList);


        if (saveList.length !== 0) {
            swal({
                title: "Are you sure?",
                text: saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.onSave(saveList)
                }
            });
        } else {
            showNotification({ msg: watcherMsg, severity: "error" });
        }
    }


    onSave = (saveList) => {
        const { showLoador, showNotification, open } = this.props;
        if (navigator.onLine) {


            showLoador({ loador: true });
            apiPost({
                url: endpoint.distributorInquiryWatcher + "/save-list-and-send-mail",
                postBody: saveList,
            }).then(({ data, success }) => {
                if (!success) {
                    showLoador({ loador: false });
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    showLoador({ loador: false });
                    showNotification({ msg: savemsg });
                    this.handleClose();
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }

    }

    handleClose = () => {
        const { handleCloses } = this.props;
        handleCloses();
    }

    rowDelete = (rowData) => {
        console.log(rowData)
        const { showNotification, showLoador } = this.props;
        const { watcherList } = this.state;
        if (navigator.onLine) {
            swal({
                title: "Are you sure?",
                text: deleteWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    if (rowData.id) {
                        showLoador({ loador: true });
                        await apiDelete({
                            url: endpoint.distributorInquiryWatcher + "/" + rowData.id,
                        }).then(({ data, success }) => {
                            showLoador({ loador: false });
                            if (data.data.error == false) {
                                showNotification({ msg: deletemsg, severity: "success" });
                                const rowList =
                                    watcherList.filter(
                                        (row) => row.index != rowData.index
                                    );
                                const row = rowList.map((rowListData, index) => {
                                    return { ...rowListData, index: index + 1 };
                                });

                                this.setState({ watcherList: row })
                            } else {
                                showNotification({ msg: deleteFailedMsg, severity: "error" });
                            }
                        });
                    } else {
                        const filteredList = watcherList.filter(
                            (row) => row.index !== rowData.index
                        );
                        this.setState({ watcherList: filteredList })
                    }
                };
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    onDataChange = (fieldName, newValue) => {
        const { fieldData, formData } = this.state;

        if (fieldName === "salesTeam") {
            this.setState({ salesTeamValue: newValue });
        }

        if (fieldName === "stockStakeHolderTypeId") {
            this.setState({ stockStakeholderTypeValue: newValue });
        }

        const dataToSearch = {};
        const dataToReset = { ...this.state.fieldData };
        requestForDistributorJson.fieldMeta.map((currentField) => {
            if (currentField.dataKey === fieldName && currentField.getListId) {
                const masterKeyList = currentField.getListId.split(",");

                masterKeyList.map((key) => {
                    if (fieldName === key) {
                        dataToSearch[key] = newValue;
                    } else if (fieldData[key]) {
                        dataToSearch[key] = fieldData[key];
                    }
                    return null;
                });

                this.getListById(dataToSearch);
            }
            if (currentField.getListFrom) {
                const masterKeyList = currentField.getListFrom.split(",");
                masterKeyList.map((key) => {
                    if (key == fieldName && dataToReset[key]) {
                        delete dataToReset[currentField.dataKey];
                    }
                });
            }
            return null;
        });

        dataToReset[fieldName] = newValue;
        this.setState({
            fieldData: dataToReset,
        });
    };

    render() {
        const { dynamicMasterData, watcherList, formErrors, watcherPopUpOpenFlag, fieldData } = this.state;
        const { dataRow, open } = this.props;
        return (
            <>
                <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={watcherPopUpOpenFlag}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={this.handleClose}
                    >
                        Add Watcher
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <BootstrapDialogTitle
                            style={{
                                fontSize: 16,
                                paddingLeft: 4,
                            }}
                        >
                            {"Name : " + dataRow.fullName}
                        </BootstrapDialogTitle>

                        {dataRow.flag == 1 && (
                            <DynamicFormWithoutValidation
                                showBackToList={true}
                                getListById={this.getListById}
                                showTitle={requestForDistributorJson.showTitle}
                                screenTitle={""}
                                dynamicMasterData={dynamicMasterData}
                                apiBaseURL={requestForDistributorJson.apiBaseURL}
                                fieldMeta={requestForDistributorJson.fieldMeta}
                                showCancel={requestForDistributorJson.showCancel}
                                showSaveNextBtn={false}
                                saveBtnText={"Add"}
                                showSaveBtnMain={false}
                                showSaveBtn={true}
                                fieldData={fieldData}
                                formErrors={formErrors}
                                onDataChange={this.onDataChange}
                                onFormSave={this.onPressAdd}
                            />
                        )}
                        <br />
                        <Grid item xs={12} sm={12} md={12}>
                            <DynamicTable
                                data={watcherList}
                                tableHead={dataRow.flag == 1 ? columns : columnsWithoutAction}
                                showHeadDelete={dataRow.flag == 1 ? true : false}
                                showPegination={false}
                                rowDelete={this.rowDelete}
                                isActionColActive={dataRow.flag == 1 ? true : false}
                            />
                        </Grid>
                        <br />


                        {dataRow.flag == 1 && (
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                container
                                justifyContent="right"
                            >
                                <ButtonCompo
                                    size="medium"
                                    type="Submit"
                                    variant="contained"
                                    name="Submit"
                                    onClick={this.saveHandler}
                                />
                            </Grid>
                        )}


                    </DialogContent>
                </BootstrapDialog >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stockStakeHolderTypeList: state.stockStakeHolderType,
    salesExecutiveList: state.salesExecutive,
    distributorInquiryWatcherList: state.distributorInquiryWatcher,
});

const mapDispatchToProps = {
    getStockStakeHolderType,
    getSalesExecutiveWithStockHolderIdWithoutSelf,
    getDistributorInquiryWatcher,
    showLoador,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWatcherPopUp);

import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import moment from "moment";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
import imageRed from "../Images/imageRed.png"

const initialState = {
  location: {},
  salesTeamTimeline: [],
};
let url = endpoints.location;
const locationSlice = createSlice({
  name: "locationSlice",
  initialState,
  reducers: {
    locationListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        location: row,
      };
    },

    salesTeamTimelineSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesTeamTimeline: row,
      };
    },
  },
});

export const { locationListSuccess, salesTeamTimelineSuccess } =
  locationSlice.actions;

export default locationSlice.reducer;

export const getMapData =
  ({ salesTeamId, date }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          url +
          "/by-sales-team-and-date-from-real-time-data?salesTeamId=" +
          salesTeamId +
          "&date=" +
          date,
      }).then(({ data, success }) => {
        if (success) {
          let locationDetails = data.data;
          let outletList =
            locationDetails.outletDetails != null
              ? locationDetails.outletDetails.map((outlet, index) => {
                  return {
                    id: outlet.id,
                    index: index + 1,
                    address: outlet.firmName,
                    lat: +outlet.latitude,
                    lng: +outlet.longitude,
                    icon: "https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png",
                  };
                })
              : [];
          let firebaseList = [];
          locationDetails.salesTeamRealTimeDataDetails != null
            ? locationDetails.salesTeamRealTimeDataDetails.map(
                (outlet, index) => {
                  if (outlet.isOrder == 1) {
                    const locData = {
                      index: index + 1,
                      id: outlet.outletId,
                      address: outlet.outletName,
                      lat: +outlet.latitude,
                      lng: +outlet.longitude,
                      icon:
                        outlet.type == 1 && outlet.isRemoteOrder == 0
                          ? "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png"
                          : outlet.isRemoteOrder == 1
                          ? imageRed
                          : "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png",
                      amount: outlet.amount.toFixed(2),
                    };
                    firebaseList.push(locData);
                  }
                }
              )
            : [];
          locationDetails = {
            ...locationDetails,
            outletList: [...outletList, ...firebaseList],
            firebaseList:
              locationDetails.salesTeamRealTimeDataDetails != null
                ? locationDetails.salesTeamRealTimeDataDetails.map((outlet) => {
                    const locData = {
                      id: outlet.outletId,
                      lat: +outlet.latitude,
                      lng: +outlet.longitude,
                    };
                    return locData;
                  })
                : [],
          };
          dispatch(locationListSuccess({ row: locationDetails }));
          return { response: locationDetails, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTimelineForSalesExecutive =
  ({ fromDate, toDate, salesTeamIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          url +
          "/time-line-for-stake-holder-from-real-time-data?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&salesTeamIds=" +
          salesTeamIds,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;

          const row = content.map((salesTeamObject, index) => {
            console.log(salesTeamObject.json);
            let timeLineList =
              JSON.parse(salesTeamObject.json).salesTeamRealTimeDataDetails !=
              null
                ? JSON.parse(
                    salesTeamObject.json
                  ).salesTeamRealTimeDataDetails.filter(
                    (row) => row.isOrder == 1
                  )
                : [];

            const dateObject = moment(salesTeamObject.date, "DD-MM-YYYY");
            const formattedDate = dateObject.format("DD MMM");
            let saleTeamData = {
              index: index + 1,
              date: salesTeamObject.date,
              day: formattedDate,
              totalData: JSON.parse(salesTeamObject.json),
              timelineData: timeLineList.map((rowData, index) => {
                let locationData = {
                  ...rowData,
                  index: index,
                  // time: dayjs(rowData.dateAndTime).format("HH:mm"),
                  amount: rowData.amount.toFixed(2),
                  time: rowData.dateAndTime,
                  beatName: rowData.beatName,
                  outletName: rowData.outletName,
                };
                return locationData;
              }),
            };
            return saleTeamData;
          });
          console.log(row);
          dispatch(salesTeamTimelineSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setTimelineData =
  ({ row }) =>
  async (dispatch) => {
    dispatch(salesTeamTimelineSuccess({ row }));
  };